import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { getProjectById, getRelatedProjects } from '../api/Projects';
import { Errors } from '../components/Errors';
import ProjectDoneItem from '../components/Projects/ProjectDoneItem';
import ProjectFullScreen from '../components/Projects/ProjectFullScreen';
import Preloader from '../layout/Main/Preloader';
import { IProject, IProjectFullScreen } from '../model/Iproject';

const ProjectPage: FC = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const projectQuery = useQuery(['getProjectById', projectId], getProjectById, {});

  const status = projectQuery.data?.done ? 'done' : 'not_done';

  const relatedProjectsQuery = useQuery(['getRelatedProjects', status], getRelatedProjects, {
    enabled: !!projectQuery.data
  });

  const project: IProjectFullScreen | null = projectQuery.data || null;
  const relatedProjects: IProject[] = relatedProjectsQuery.data || [];

  const isLoading = relatedProjectsQuery.isLoading || relatedProjectsQuery.isLoading;
  const isError = relatedProjectsQuery.isError || relatedProjectsQuery.isError;

  if (isLoading) {
    return <Preloader />;
  }

  if (isError) {
    return <Errors page="404" />;
  }

  return project?.done && relatedProjects ? <ProjectDoneItem /> : <ProjectFullScreen />;
};

export default ProjectPage;
