import React, { FC, Dispatch, SetStateAction } from 'react';
import { ContUsForm, InputName, Label, LabelText, InputText, SentButton, Modal } from './style';
import { useForm } from 'react-hook-form';
import { sendMessage } from '../../api/SendTelegram';

interface IInputs {
  name: string;
  telegram: string;
  message: string;
}

interface ContactUsFormProps {
  visibale: boolean;
  setVisibale: Dispatch<SetStateAction<boolean>>;
}

const ContactUsForm: FC<ContactUsFormProps> = ({ visibale, setVisibale }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid }
  } = useForm<IInputs>({
    mode: 'all'
  });

  // Формирование строки для telegramApi
  const formMessage = (data: IInputs): string => {
    return `<b>Поступило новое сообщение:</b> ${Object.values(data).join(', ')}`;
  };

  // Обработчик клика по Submit
  const onSubmit = (data: IInputs) => {
    console.log(data);
    sendMessage(formMessage(data))
      .then(() => {
        reset();
        setVisibale((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  // Обработчик клика вне модалки
  const handleClickOverlay: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) setVisibale((prev) => !prev);
  };

  console.log(isValid);

  return (
    <>
      {visibale && (
        <Modal onClick={handleClickOverlay}>
          <ContUsForm>
            <Label>
              <LabelText>Ваше имя*</LabelText>
              <InputName
                {...register('name', {
                  required: true,
                  maxLength: {
                    value: 30,
                    message: 'Не более 30 символов'
                  },
                  minLength: {
                    value: 2,
                    message: 'Не менее 2 символов'
                  }
                })}
                placeholder="Имя"
              />
            </Label>
            <Label>
              <LabelText>Ваш телеграмм*</LabelText>
              <InputName
                {...register('telegram', {
                  required: true,
                  maxLength: {
                    value: 30,
                    message: 'Не более 30 символов'
                  },
                  minLength: {
                    value: 2,
                    message: 'Не менее 2 символов'
                  }
                })}
                placeholder="@username"
              />
            </Label>
            <Label>
              <LabelText>Ваше сообщение*</LabelText>
              <InputText
                {...register('message', {
                  required: true,
                  maxLength: {
                    value: 300,
                    message: 'Не более 300 символов'
                  },
                  minLength: {
                    value: 2,
                    message: 'Не менее 2 символов'
                  }
                })}
                placeholder="Напишите сообщение"
              />
            </Label>
            <SentButton disabled={!isValid} type="submit" onClick={handleSubmit(onSubmit)}>
              Отправить
            </SentButton>
          </ContUsForm>
        </Modal>
      )}
    </>
  );
};

export default ContactUsForm;
