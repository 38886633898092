import Project from './types/class/Project';
import { QueryFunctionContext } from '@tanstack/react-query';

import { instance } from '.';
import { IProject, IProjectFullScreen } from '../model/Iproject';

export const getProjects = () =>
  new Promise<Project[]>((resolve) =>
    setTimeout(() => resolve(Project.CreateTestDataArray(150)), 1500)
  );

export const getProjectsDone = () =>
  new Promise<Project[]>((resolve) =>
    setTimeout(() => resolve(Project.CreateTestDataArray(15)), 1500)
  );

export const getProjectById = async ({ queryKey }: QueryFunctionContext) => {
  const [, projectId] = queryKey;
  const res = await instance.get(`projects/${projectId}`);
  const data: IProjectFullScreen = res.data;
  return data;
};

export const getRelatedProjects = async ({ queryKey }: QueryFunctionContext) => {
  const [, status] = queryKey;
  const res = await instance.get(`projects/${status}?limit=3`);
  const data: IProject[] = res.data;
  return data;
};

/*
  instance
    .get('/projects/done')
    .then((res) => res.data)
    .then((data: IProjectResponse[]) =>
      data.map((p: IProjectResponse): Project => {
        return new Project({
          id: p.id,
          title: p.title,
          description: p.short_description,
          status: p.done ? ProjectStatus.Done : ProjectStatus.InWork,
          startDate: p.create_at
        });
      })
    );*/

export const getProjectsNotDone = () =>
  new Promise<Project[]>((resolve) =>
    setTimeout(() => resolve(Project.CreateTestDataArray(15)), 1500)
  );
/*
  instance
    .get('/projects/not_done')
    .then((res) => res.data)
    .then((data: IProjectResponse[]) =>
      data.map((p: IProjectResponse): Project => {
        return new Project({
          id: p.id,
          title: p.title,
          status: ProjectStatus.InWork,
          startDate: p.create_at,
          departments: p.department
        });
      })
    );
*/

// Временный запрос данных для текущих проектов
export const getProjectsNotDoneII = () =>
  instance.get('projects/not_done?limit=100').then((res) => res.data);
