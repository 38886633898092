import axios from 'axios';
import Auth, { memoizedRefreshTokens } from './Auth';

export const instance = axios.create({
  // baseURL: 'http://135.181.198.180:54641/',
  baseURL: 'https://guild-of-developers.ru/api',
  headers: {
    'Content-Type': 'application/json',
    'X-ACCESS-TOKEN': `Ljaldjflj231lkj123lkjlFknkanwdfqpkzdjvcnmaohaLKouqojnNiasdfn32234kmn`
  }
});

// instance.interceptors.request.use(
//   async (config) => {
//     const accessToken = await Auth.GetAccessToken();
//     if (accessToken) {
//       config.headers = {
//         ...config.headers,
//         authorization: `Bearer ${accessToken}`
//       };
//     }

//     return config;
//   },
//   (error) => Promise.reject(error)
// );

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const result = await memoizedRefreshTokens();

      if (result?.access_token) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${result?.access_token}`
        };
      }

      return axios(config);
    }
    return Promise.reject(error);
  }
);
