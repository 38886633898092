import styled from 'styled-components';

export const Section = styled.section`
  background-color: var(--color-fon);
  margin-top: 32px;

  .hidden {
    visibility: hidden;
  }

  .pagination {
    color: var(--color-white);
    display: flex;
    justify-content: center;
    list-style: none;
    align-items: center;
    gap: 15px;
    margin-top: 64px;
    margin-bottom: 128px;
    user-select: none;

    @media (max-width: 650px) {
      gap: 4px;
    }

    .page-num {
      display: block;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--fs-500);
      font-weight: var(--fw-600);
      font-feature-settings: 'pnum' on, 'lnum' on;

      line-height: var(--line-height-550);
      cursor: pointer;
    }

    .break {
      cursor: pointer;
      font-size: var(--fs-500);
      font-weight: var(--fw-600);
    }

    .selected {
      border-radius: 50%;
      background: var(--gradient-1);
    }

    .previous {
      cursor: pointer;
      margin-right: 12px;

      @media (max-width: 650px) {
        margin-right: 1px;

        svg {
          width: 14px;
          height: 12px;
        }
      }
    }

    .next {
      cursor: pointer;
      svg {
        transform: rotate(180deg);
      }

      @media (max-width: 650px) {
        margin-left: 1px;

        svg {
          width: 14px;
          height: 12px;
        }
      }
    }
  }
`;

export const Grid = styled.ul`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, min(377px, 100%));
  gap: 24px 22px;

  @media (max-width: 430px) {
    grid-template-columns: repeat(auto-fill, min(290px, 100%));
  }
`;
