import { FC, useCallback, useEffect, useState } from 'react';
import Project from '../../../api/types/class/Project';
import {
  ArrowIconContainer,
  Card,
  CardContainer,
  Circle,
  DepContainer,
  Department,
  List,
  MainBlock,
  MainContent,
  PlusIconContainer,
  Technology,
  Text,
  Title,
  Tools,
  ToolsContainer,
  ToolsHeader,
  Wrapper
} from './style';
import {
  ApplySubTitle,
  Button,
  Close,
  IconContainer,
  Stat,
  StatItem,
  SubTitle
} from '../ProjectFullScreen/style';
import { CardDepartments } from '../Project/style';
import { Container } from '../../../lib/styled/container';
import { CardStates, useProjectCardStore } from '../../../zustand-store';
import { ArrowIcon, PlusIcon, ToolIcon, icons } from './icon';
import { Cross } from '../ProjectFullScreen/icon';

interface Props {
  project?: Project;
}

export const ProjectCard: FC<Props> = () => {
  const [projectTEST] = useState(Project.CreateTestDataArray(1)[0]);
  const { project, cardState, isMenuCollapsed, setProject, setState, toggleMenu } =
    useProjectCardStore();
  useEffect(() => {
    setProject(projectTEST);
  }, [setProject, projectTEST]);

  const requirements = useCallback(() => {
    return (
      <DepContainer>
        {project?.departments?.map((dep) => {
          return (
            <DepContainer>
              <ApplySubTitle>{dep.name}</ApplySubTitle>
              <Department>
                {dep.stacks?.map((stack) => (
                  <Technology>{stack.name}</Technology>
                ))}
              </Department>
            </DepContainer>
          );
        })}
      </DepContainer>
    );
  }, [project]);

  const welcomeIf = useCallback(() => {
    return (
      <CardDepartments>
        <List>
          {project?.departments?.map((department) => (
            <li>{department.name}</li>
          ))}
        </List>
      </CardDepartments>
    );
  }, [project]);

  const statistics = useCallback(() => {
    return (
      <Stat>
        {project?.statistics?.map((department) => {
          return (
            <StatItem>
              <p>{department.department.name}</p>
              <SubTitle>
                {department.hired}/{department.total}
              </SubTitle>
            </StatItem>
          );
        })}
      </Stat>
    );
  }, [project]);

  const menu = useCallback(() => {
    const toolsMenu = (
      <Tools $collapsed={isMenuCollapsed}>
        <PlusIconContainer $collapsed={isMenuCollapsed} onClick={toggleMenu}>
          <PlusIcon></PlusIcon>
        </PlusIconContainer>
        <Close onClick={toggleMenu} $projectCardPage={true}>
          <Cross projectCardPage={true} />
        </Close>
        <ToolsContainer>
          {Object.keys(icons).map((key, index) => {
            const Icon = icons[key];
            const stateName = Object.values(CardStates)[index];
            const iconComponent = <Icon name={stateName} currentState={cardState}></Icon>;
            const iconWithCircle = (
              <Circle $name={stateName} $currentState={cardState}>
                {iconComponent}
              </Circle>
            );
            const ToolToggle = (
              <ToolsHeader
                onClick={() => setState(stateName)}
                $name={stateName}
                $currentState={cardState}
                $collapsed={isMenuCollapsed}
              >
                {key === 'Набор участников' || key === 'Закрыть проект' ? (
                  <>{iconWithCircle}</>
                ) : (
                  <>{iconComponent}</>
                )}

                <p>{Object.values(CardStates)[index]}</p>
              </ToolsHeader>
            );
            return <>{ToolToggle}</>;
          })}
        </ToolsContainer>
        <Button $projectCardPage={true} $collapsed={isMenuCollapsed}>
          Перейти в карточку
        </Button>
        <ArrowIconContainer $collapsed={isMenuCollapsed}>
          <ArrowIcon></ArrowIcon>
        </ArrowIconContainer>
      </Tools>
    );
    return <>{toolsMenu}</>;
  }, [cardState, setState, isMenuCollapsed, toggleMenu]);

  return (
    <Container>
      <Wrapper>
        {menu()}
        <CardContainer>
          <Card>
            <Button $mobile={true} $collapsed={isMenuCollapsed} onClick={toggleMenu}>
              <p>Открыть разделы</p>
              <IconContainer>
                <ToolIcon></ToolIcon>
              </IconContainer>
            </Button>

            <Title>Карточка</Title>
            <MainContent>
              <MainBlock>
                <SubTitle>«{project?.title}»</SubTitle>
                <Text>{project?.description}</Text>
              </MainBlock>

              <MainBlock>
                <SubTitle>Чем предстоит заниматься:</SubTitle>
                <Text>{project?.todo || ''}</Text>
              </MainBlock>

              <MainBlock>
                <SubTitle>Что ты получишь:</SubTitle>
                <Text>{project?.benefit || ''}</Text>
              </MainBlock>

              <MainBlock>
                <SubTitle>Тебе к нам в проект, если ты:</SubTitle>
                {welcomeIf()}
              </MainBlock>

              <MainBlock>
                <SubTitle>Требования для направлений:</SubTitle>
                {requirements()}
              </MainBlock>

              <MainBlock>
                <SubTitle>Статистика набора:</SubTitle>
                {statistics()}
              </MainBlock>
            </MainContent>
          </Card>
        </CardContainer>
      </Wrapper>
    </Container>
  );
};
