import React, { FC } from 'react';
import IPortfolioProject from '../../../api/types/interface/IPortfolioProject';
import ReactPaginate from 'react-paginate';
import { ArrowPagination } from '../../Projects/ProjectsEdit/icon';
import PortfolioProject from '../../../api/types/class/PortfolioProject';
import { useMediaQuery } from '../../../services/useMediaQuery';
import { Grid, Section } from './styles';
import ProjectClosed from '../../UI/ProjectClosed';
import Project from '../../../api/types/class/Project';

interface PortfolioListProps {
  projects: IPortfolioProject[] | undefined;
}

interface PaginatedProps {
  itemsPerPage: number;
  data: PortfolioProject[];
}

interface ItemsProps {
  items: PortfolioProject[];
}

const PaginatedItems: FC<PaginatedProps> = ({ itemsPerPage, data }) => {
  const isMobile = useMediaQuery('(max-width: 360px)');
  const Items: FC<ItemsProps> = ({ items }) => {
    return (
      <Grid>
        {items?.map((card: PortfolioProject) => (
          <ProjectClosed key={card.id} card={card} />
        ))}
      </Grid>
    );
  };

  const [currentItems, setCurrentItems] = React.useState<PortfolioProject[]>([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [itemOffset, setItemOffset] = React.useState(0);

  React.useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items items={currentItems} />
      <ReactPaginate
        breakLabel="..."
        nextLabel={<ArrowPagination />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={isMobile ? 2 : 4}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        previousLabel={<ArrowPagination />}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="page-num"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        activeLinkClassName="active"
      />
    </>
  );
};

export const ProjectsNode: FC<PortfolioListProps> = () => {
  const testData = Project.CreateTestDataArray(176);
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  return (
    <Section>
      <PaginatedItems itemsPerPage={isDesktop ? 6 : 4} data={testData} />
    </Section>
  );
};
