import styled from 'styled-components';

export const Picture = styled.picture`
  width: 100%;

  @media (max-width: 560px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export const Img = styled.img`
  width: 100%;
`;
