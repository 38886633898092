import { FC } from 'react';
import ProjectClass from '../../../../api/types/class/Project';
import { CardLink, Card, WrapperInfo, Date, Title, Description } from './style';

interface ProjectsProps {
  project: ProjectClass;
}

const Project: FC<ProjectsProps> = ({ project }) => {
  return (
    <CardLink to={`/projects/${project.id}`}>
      <Card>
        <WrapperInfo>
          <Date>
            {project.startDate} - {project.finishedDate}
          </Date>
          <Title>«{project.title}»</Title>
          <Description>{project.description}</Description>
        </WrapperInfo>
      </Card>
    </CardLink>
  );
};

export default Project;
