import React, { FC } from 'react';
import { Container } from '../../lib/styled/container';
import HidingPanel, { HidingPanelProps, HidingPanelStylesProps } from '../HidingPanel';
import { Section } from './style';

interface AccordionProps {
  data: Array<HidingPanelProps>;
  styles?: HidingPanelStylesProps;
  projectPage?: boolean;
}

const Accordion: FC<AccordionProps> = ({ data, styles, projectPage }) => {
  return (
    <Section projectPage={projectPage as boolean}>
      <Container projectPage={projectPage}>
        {data.map((item: HidingPanelProps, index) => (
          <HidingPanel
            background={styles && styles.background}
            textColor={styles && styles.textColor}
            iconClose={styles && styles.iconClose}
            borderTop={styles && styles.borderTop}
            avatar={item.avatar}
            text={item.text}
            isOpenedDefault={item.isOpenedDefault || false}
            title={item.title}
            key={index}
            projectPage={projectPage}
          />
        ))}
      </Container>
    </Section>
  );
};

export default Accordion;
