import styled from 'styled-components';

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--gradient-3);
  border-radius: 20px;
  padding: 23px 0;

  @media (min-width: 768px) {
    padding: 30px 0;
  }
`;
