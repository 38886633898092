import IDepartment from '../interface/IDepartment';
import IImage from '../interface/IImage';
import IPortfolioProject from '../interface/IPortfolioProject';

/**
 * Проект из портфолио
 * @class PortfolioProject
 * @implements IPortfolioProject
 */
export default class PortfolioProject implements IPortfolioProject {
  /**
   * @constructor PortfolioProject
   * @param data Partial of PortfolioProject
   */
  public constructor(data: Partial<PortfolioProject>) {
    Object.assign(this, data);
  }

  /**
   * Генератор тестовых данных
   * @param n количество объектов
   * @returns Массив тестовых объектов
   * @example  const portfolioPrjArray = PortfolioProject.CreateTestDataArray(10);
   */
  public static CreateTestDataArray(n: number): PortfolioProject[] {
    const arr: PortfolioProject[] = [];
    for (let i = 0; i < n; i++) arr.push(PortfolioProject.CreateTestData());
    return arr;
  }

  /**
   * Генератор тестовых данных
   * @returns Тестовый объект
   * @example  const portfolioPrj = PortfolioProject.CreateTestData();
   */
  public static CreateTestData(): PortfolioProject {
    return new PortfolioProject({
      id: Math.floor(Math.random() * 1000),
      title: `Test project № ${Math.floor(Math.random() * 1000)}`,
      description: 'Test описание проекта',
      roleInTeam: 'Что-то делал',
      startDate: new Date()
        .toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .split('/')
        .join('.'),
      finishedDate: new Date()
        .toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .split('/')
        .join('.'),
      departments: [
        {
          id: Math.floor(Math.random() * 100),
          name: 'Front',
          stacks: [
            {
              id: Math.floor(Math.random() * 100),
              name: 'JS'
            }
          ]
        },
        {
          id: Math.floor(Math.random() * 100),
          name: 'Back',
          stacks: [
            {
              id: Math.floor(Math.random() * 100),
              name: 'Python'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'SQL'
            }
          ]
        }
      ],
      link: 'http://google.com',
      img: {
        id: Math.floor(Math.random() * 1000),
        url: '/assets/img/portfolio-screenshot.webp'
      }
    });
  }

  id?: number = -1;

  title?: string = '';

  description?: string = '';

  roleInTeam?: string = '';

  departments?: IDepartment[] = [];

  link?: string = '';

  img?: IImage | undefined;

  startDate?: string = '';

  finishedDate?: string = '';
}
