import styled from 'styled-components';

export const Section = styled.section`
  background-color: var(--color-fon);
  margin-top: 32px;
`;

export const NavProjects = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: 2px solid var(--color-grey-2);
  margin-top: 88px;

  .active {
    border-bottom: 4px solid var(--color-blue-1);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  @media (max-width: 768px) {
    gap: 15px;
  }
`;

export const NavProject = styled.p`
  cursor: pointer;
  color: var(--color-white);
  font-size: var(--fs-500);
  font-weight: var(--fw-700);
  line-height: var(--line-height-550);
  text-align: center;
  transition: border 0.1s ease;
  position: relative;
  padding-bottom: 18px;

  @media (max-width: 450px) {
    font-size: var(--fs-400);
  }
`;

export const NavProjectTitle = styled.span``;
