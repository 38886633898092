import styled from 'styled-components';

export const Section = styled.section`
  padding: 60px 0;
  @media (max-width: 560px) {
    padding: 38px 0;
  }

  .swiper {
    padding-bottom: 75px;
  }

  .swiper-slide {
    height: auto;
  }

  .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
  }

  .swiper-pagination-bullet-active {
    width: 21px;
    height: 21px;
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination-bullet {
    margin: 0 5px;
    vertical-align: middle;
  }
  .swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination-bullet:first-child {
    margin-left: 0;
  }
  .swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination-bullet-active {
    margin: 0 11px;
  }
`;
export const Wrapper = styled.div`
  padding: 0;
`;
export const ListBlock = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, min(281px, 100%));
  gap: 64px 75px;
  @media (max-width: 320px) {
    grid-template-columns: repeat(auto-fill, min(127px, 100%));
    gap: 32px 0px;
  }
`;
export const Title = styled.h1`
  margin: 0 0 69px 0;
  text-align: center;
  font-size: var(--fs-600);
  font-weight: var(--fw-700);
  line-height: var(--line-height-600);
  color: var(--color-white);
  @media (max-width: 560px) {
    margin: 0 0 35px 0;
  }
`;
