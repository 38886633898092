import React, { FC } from 'react';
import { Container } from '../../lib/styled/container';
import {
  Section,
  Wrapper,
  Greetings,
  Title,
  TitleSpan,
  Descr,
  DescrSpan,
  Members,
  MembersItem,
  MembersIcon,
  MembersDescr,
  MembersAmount,
  MembersName
} from './style';
import { getHomepageDashboard } from '../../api/Dashboard';
import { useQuery } from '@tanstack/react-query';
import Preloader from '../../layout/Main/Preloader';

const Hero: FC = () => {
  const { isLoading, error, data } = useQuery(['HomepageDashboard'], getHomepageDashboard);
  if (isLoading) return <Preloader />;
  if (error) return <div>Ошибка</div>;

  return (
    <Section>
      <Container>
        <Wrapper>
          <Greetings>
            <Title>
              GoD /<TitleSpan> Guild of Developers</TitleSpan>
            </Title>

            <Descr>
              Помогаем начинающим IT&nbsp;специалистам получить коммерческий опыт
              <DescrSpan> абсолютно бесплатно</DescrSpan>
            </Descr>
          </Greetings>

          <Members>
            {(data || []).map((item, index) => (
              <MembersItem key={index}>
                <MembersIcon src={item.imageSrc} />
                <MembersDescr>
                  <MembersAmount>{item.amount}</MembersAmount>
                  <MembersName>{item.name}</MembersName>
                </MembersDescr>
              </MembersItem>
            ))}
          </Members>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default Hero;
