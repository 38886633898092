import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CardLink = styled(Link)``;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  width: 100%;
  border-radius: 20px;
  background: var(--gradient-2);
`;

export const CardImg = styled.img`
  display: block;
  width: 202px;

  border-radius: 10px;
  background-color: var(--color-grey-3);

  @media (min-width: 768px) {
    width: 232px;
  }
  @media (min-width: 1024px) {
    width: 206px;
  }
  @media (min-width: 1200px) {
    width: 265px;
  }
`;

export const CardWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 17px 25px 29px;
`;

export const CardDate = styled.p`
  margin-bottom: 18px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-white);
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
`;

export const CardTitle = styled.h3`
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: var(--color-white);

  &::before {
    content: open-quote;
  }
  &::after {
    content: close-quote;
  }

  @media (min-width: 1200px) {
    font-size: 24px;
    line-heght: 26px;
  }
`;

export const CardDescription = styled.p`
  margin-bottom: 22px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: var(--color-white);

  word-wrap: break-word;
`;

export const CardButton = styled(Link)`
  width: 130px;
  padding: 9px 19px;
  text-align: center;
  background: var(--color-grey);
  border-radius: 25px;
  color: var(--color-white);
  font-weight: var(--fw-700);
  font-size: var(--fs-500);
  line-height: var(--line-height-550);

  @media (min-width: 768px) {
    width: 184px;
    font-size: var(--fs-550);
  }
`;
