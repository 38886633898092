import { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '../../../lib/styled/container';
import { Advantages, Arrow, Back, Cross, Failure, Forward, GitHubIcon, TelegramIcon } from './icon';
import Accordion from '../../Accordion';
import {
  Section,
  Wrapper,
  GoBack,
  SubTitle,
  Content,
  MainContent,
  MainBlock,
  Description,
  AsideContent,
  AsideBlock,
  Button,
  Pagination,
  PaginationControl,
  PaginationLeft,
  PaginationRight,
  SomeInfo,
  ApplyFormScreen,
  ApplyForm,
  ApplyTitle,
  ApplySubTitle,
  AppplyTestTask,
  ApplySolutionInput,
  Close,
  ConnectInfo,
  IconContainer,
  StatusImgContainer,
  Separator,
  ConnectButtons,
  SelectDepartment,
  MenuItems,
  Select,
  Stat,
  StatItem,
  ConnectInfoContainer
} from './style';
import Project from '../../../api/types/class/Project';
import Projects from '..';
import { ArrorRight, ArrowDown, ArrowUp } from '../Project/Icon';
import { AsideBlockStates, WindowStates, useFullScreenProjectStore } from '../../../zustand-store';
import { TestStatus } from '../../../api/types/interface/ITestTask';
import { Menu } from '@headlessui/react';
import ApplyDropdownList from '../../DropdownList/ApplyDropdownList';
import { CardDepartment, CardDepartments, Stack, StackItem } from '../Project/style';
import IDepartment from '../../../api/types/interface/IDepartment';
const ProjectFullScreen: FC = () => {
  const {
    project,
    data,
    solutionInput,
    isTestSent,
    testStatus,
    departments,
    isApplyCompleted,
    isOpen,
    windowState,
    asideBlockState,
    selectedDepartment,
    setProject,
    setOpen,
    apply,
    continueApply,
    checkStatus,
    changeSolution,
    updateTestStatus,
    finish,
    deliverySuccess,
    closeWindow,
    sendTest,
    changeSolutionInput,
    setDepartments,
    reset
  } = useFullScreenProjectStore();

  //const { data } = useQuery(['project'], getProjects);
  const { projectId } = useParams<{ projectId?: string }>();
  //const [project, setProject] = useState<ProjectProps['project']>();
  const [toggle, setToggle] = useState('');
  const departmentRadioPreview = useCallback(() => {
    const curr = departments.find((dep) => dep.isCheck === true);
    if (windowState === WindowStates.Apply)
      return <SelectDepartment>{curr ? curr.name : 'Выбери'}</SelectDepartment>;
    else if (windowState === WindowStates.ApplyWithTest)
      return <SelectDepartment $second={true}>{curr ? curr.name : 'Выбери'}</SelectDepartment>;
  }, [departments, windowState]);

  const handleSetToggle = useCallback(
    (name: string) => (toggle === name ? setToggle('') : setToggle(name)),
    [setToggle, toggle]
  );

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [project, windowState]);

  useEffect(() => {
    const card = data?.find((item: Project) => item.id === Number(projectId));
    setProject(card as Project);
    reset();
  }, [projectId, data, reset, setProject]);

  useEffect(() => {
    setDepartments();
  }, [project, setDepartments]);

  useEffect(() => {
    if (project?.statistics) {
      const isOpenProject = project.statistics.find(
        (department) => department.hired < department.total
      );
      if (isOpenProject) setOpen(true);
      else setOpen(false);
    }
    let currDepartment: IDepartment;
    if (departments)
      currDepartment = departments.find((dep) => dep.isCheck === true) as IDepartment;

    if (project?.testTask) {
      const currTestTask = project?.testTask.find(
        (task) => task.department.id === currDepartment?.id
      );
      updateTestStatus(currTestTask?.testTaskStatus as TestStatus);
    }
  }, [project, setOpen, updateTestStatus, setDepartments, departments]);

  useEffect(() => {
    setTimeout(() => {
      if (isTestSent > 0) deliverySuccess(); // человеку отображается окно об успехе через пол секунды после изменения ссылки или первой отправки тестового, в будущем можно поставть где-то лоадер
    }, 500);
  }, [isTestSent, deliverySuccess]);

  useEffect(() => {
    if (windowState) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
  }, [windowState]);
  return (
    <>
      {windowState === WindowStates.Apply ? (
        <ApplyFormScreen>
          <ApplyForm>
            <Close onClick={closeWindow}>
              <Cross />
            </Close>
            <ApplyTitle>Подать заявку</ApplyTitle>
            <ApplySubTitle>Выбери направление</ApplySubTitle>
            <Select>
              <Menu>
                {departmentRadioPreview()}
                <MenuItems $first={true}>
                  <ApplyDropdownList />
                </MenuItems>
              </Menu>
            </Select>

            <Button onClick={continueApply}>Далее</Button>
          </ApplyForm>
        </ApplyFormScreen>
      ) : windowState === WindowStates.ApplyWithTest ? (
        <ApplyFormScreen>
          <ApplyForm onSubmit={(e) => sendTest(e)}>
            <Close onClick={closeWindow}>
              <Cross />
            </Close>
            <ApplyTitle>Подать заявку</ApplyTitle>
            <ApplySubTitle>Направление</ApplySubTitle>
            <Select $second={true}>
              <Menu>
                {departmentRadioPreview()}
                <MenuItems $second={true}>
                  <ApplyDropdownList isChangeDepBlock={true} />
                </MenuItems>
              </Menu>
            </Select>
            <ApplySubTitle>Тестовое задание</ApplySubTitle>
            <AppplyTestTask>
              {project?.testTask
                ? project?.testTask.map((task) => {
                    if (task.department.id === selectedDepartment)
                      return <a href={task.testTask}>{task.testTask}</a>;
                  })
                : ''}
            </AppplyTestTask>
            <Separator />
            <ApplySubTitle>Прикрепить тестовое задание</ApplySubTitle>
            <ApplySolutionInput
              type="text"
              placeholder="ссылка"
              defaultValue={solutionInput}
              onChange={(e) => changeSolutionInput(e.target.value)}
            ></ApplySolutionInput>
            <Button type="submit">Отправить заявку</Button>
          </ApplyForm>
        </ApplyFormScreen>
      ) : windowState === WindowStates.SuccessOfSending ? (
        <ApplyFormScreen>
          <ApplyForm $succMsg={true}>
            <ApplyTitle>Тестовое успешно отправлено!</ApplyTitle>
            <Button onClick={closeWindow}>Продолжить</Button>
          </ApplyForm>
        </ApplyFormScreen>
      ) : windowState === WindowStates.Change ? (
        <ApplyFormScreen>
          <ApplyForm onSubmit={(e) => sendTest(e)}>
            <Close onClick={closeWindow}>
              <Cross />
            </Close>
            <ApplyTitle>Изменить ссылку</ApplyTitle>
            <ApplySubTitle>Твое тестовое задание</ApplySubTitle>
            <ApplySolutionInput
              type="text"
              defaultValue={solutionInput}
              onChange={(e) => changeSolutionInput(e.target.value)}
            ></ApplySolutionInput>
            <Button type="submit">Изменить ссылку на решение</Button>
          </ApplyForm>
        </ApplyFormScreen>
      ) : windowState === WindowStates.StatusCheck ? (
        <ApplyFormScreen>
          <ApplyForm $status={testStatus}>
            <ApplyTitle>Cтатус тестового</ApplyTitle>
            {testStatus === TestStatus.SentToLead ? (
              <>
                <Close onClick={closeWindow}>
                  <Cross />
                </Close>
                <SomeInfo>Тестовое задание находится на проверке у тимлида</SomeInfo>
                <ConnectInfo $statWin={true} onClick={closeWindow}>
                  Связаться с тимлидом
                  <IconContainer>
                    <TelegramIcon isWhite={false} />
                  </IconContainer>
                </ConnectInfo>
              </>
            ) : testStatus === TestStatus.Failed ? (
              <>
                <StatusImgContainer>
                  <Failure />
                </StatusImgContainer>
                <SomeInfo>
                  Тестовое задание не принято. Тимлид посчитал что ты не подходишь для участия в
                  проекте :(
                </SomeInfo>
                <Button onClick={finish}>Продолжить</Button>
              </>
            ) : (
              <>
                <StatusImgContainer>
                  <Advantages />
                </StatusImgContainer>
                <SomeInfo>Тестовое задание принято. Добро пожаловать в команду!</SomeInfo>
                <Button onClick={finish}>Продолжить</Button>
              </>
            )}
          </ApplyForm>
        </ApplyFormScreen>
      ) : (
        <></>
      )}
      <Section onClick={() => setToggle('')}>
        <Container>
          <Wrapper>
            <GoBack to="/projects" onClick={reset}>
              <Arrow />
            </GoBack>
            <Content>
              <MainContent>
                <MainBlock>
                  <SubTitle>«{project?.title}»</SubTitle>
                  <Description>{project?.description}</Description>
                </MainBlock>
                <MainBlock>
                  <SubTitle>Чем предстоит заниматься:</SubTitle>
                  {project?.todo || ''}
                </MainBlock>
                <MainBlock>
                  <SubTitle>Тебе к нам в проект, если ты:</SubTitle>
                  <CardDepartments>
                    {project?.departments?.map((department) => (
                      <>
                        <CardDepartment
                          key={department.name}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSetToggle(department.name);
                          }}
                        >
                          <p>
                            {department.name}
                            {toggle === department.name ? <ArrowUp /> : <ArrowDown />}
                          </p>
                        </CardDepartment>
                        <Stack $toggle={toggle} $depName={department.name}>
                          {toggle === department.name
                            ? department.stacks?.map((stack) => (
                                <StackItem key={stack.id}>{stack.name}</StackItem>
                              ))
                            : ''}
                        </Stack>
                      </>
                    ))}
                  </CardDepartments>
                </MainBlock>
                <MainBlock>
                  <SubTitle>Что ты получишь:</SubTitle>
                  <p>{project?.benefit || ''}</p>
                </MainBlock>
                <MainBlock>
                  <SubTitle>Требования для направлений:</SubTitle>
                  {project?.departments?.map((department) => {
                    return (
                      <Accordion
                        data={[
                          {
                            title: department.name,
                            text: department.stacks?.reduce(
                              (acc, curr) => acc + curr.name + '\n',
                              ''
                            )
                          }
                        ]}
                        projectPage={true}
                      ></Accordion>
                    );
                  })}
                </MainBlock>
                <MainBlock>
                  <SubTitle>Статистика набора:</SubTitle>
                  <Stat>
                    {project?.statistics?.map((department) => {
                      return (
                        <StatItem>
                          <p>{department.department.name}</p>
                          <SubTitle>
                            {department.hired}/{department.total}
                          </SubTitle>
                        </StatItem>
                      );
                    })}
                  </Stat>
                </MainBlock>
              </MainContent>
              <AsideContent>
                <AsideBlock>
                  {isOpen && asideBlockState === AsideBlockStates.Start ? (
                    <>
                      <SomeInfo>
                        Прочитать требования для каждого из направлений и откликнуться на проект
                      </SomeInfo>

                      <Button onClick={apply}>Откликнуться на проект</Button>
                    </>
                  ) : asideBlockState === AsideBlockStates.SuccessOfSending ? (
                    <>
                      <SomeInfo>
                        Тестовое задание отправлено<br></br>
                      </SomeInfo>
                      <ConnectInfoContainer>
                        <ConnectInfo onClick={changeSolution}>
                          Изменить ссылку на решение
                        </ConnectInfo>
                        <ConnectInfo>
                          Связаться с тимлидом
                          <IconContainer>
                            <TelegramIcon isWhite={false} />
                          </IconContainer>
                        </ConnectInfo>
                      </ConnectInfoContainer>

                      <Button onClick={checkStatus}>Узнать статус проверки</Button>
                    </>
                  ) : asideBlockState === AsideBlockStates.Status ? (
                    testStatus === TestStatus.Success ? (
                      <>
                        <SomeInfo>
                          Тестовое задание принято. Добро пожаловать в команду!<br></br>
                        </SomeInfo>{' '}
                        <ConnectInfo $statWin={true} onClick={closeWindow}>
                          Связаться с тимлидом
                          <IconContainer>
                            <TelegramIcon isWhite={false} />
                          </IconContainer>
                        </ConnectInfo>
                      </>
                    ) : testStatus === TestStatus.Failed ? (
                      <>
                        <SomeInfo>
                          Тестовое задание не принято. Тимлид посчитал что ты не подходишь для
                          участия в проекте :(<br></br>
                        </SomeInfo>
                        <ConnectInfo onClick={closeWindow}>
                          Связаться с тимлидом
                          <IconContainer>
                            <TelegramIcon isWhite={false} />
                          </IconContainer>
                        </ConnectInfo>{' '}
                      </>
                    ) : (
                      <></>
                    )
                  ) : isOpen === false ? (
                    <SomeInfo>
                      Набор участников закрыт. Проект находится в стадиии разработки. <br></br>
                    </SomeInfo>
                  ) : isOpen === null ? (
                    <SomeInfo>
                      Данные проекта не загружены. <br></br>
                    </SomeInfo>
                  ) : (
                    <></>
                  )}
                </AsideBlock>
                {isApplyCompleted && testStatus === TestStatus.Success ? (
                  <ConnectButtons>
                    <Button>
                      Общий чат проекта
                      <IconContainer>
                        <TelegramIcon isWhite={true} />
                      </IconContainer>
                    </Button>
                    <Button>
                      Чат Бакенда
                      <IconContainer>
                        <TelegramIcon isWhite={true} />
                      </IconContainer>
                    </Button>
                    <Button>
                      Trello
                      <IconContainer>
                        <ArrorRight />
                      </IconContainer>
                    </Button>
                    <Button>
                      GitHub{' '}
                      <IconContainer>
                        <GitHubIcon />
                      </IconContainer>
                    </Button>
                  </ConnectButtons>
                ) : (
                  <></>
                )}

                {/* TODO: Решить какую логику сделать дляи пагинации. Предлагаю пока так */}
                <Pagination>
                  {Number(projectId) > 0 && (
                    <PaginationControl to={`/projects/${Number(projectId) - 1}`}>
                      <Back /> <PaginationLeft>Предыдущий проект</PaginationLeft>
                    </PaginationControl>
                  )}
                  {data && Number(projectId) < data.length - 1 && (
                    <PaginationControl to={`/projects/${Number(projectId) + 1}`}>
                      <PaginationRight>Следующий проект</PaginationRight> <Forward />
                    </PaginationControl>
                  )}
                </Pagination>
              </AsideContent>
            </Content>
          </Wrapper>
          <Separator />
        </Container>
        <ApplyTitle>Другие проекты</ApplyTitle>
        <Projects page={'apply'} />
      </Section>
    </>
  );
};

export default ProjectFullScreen;
