import { FC, useState } from 'react';
import {
  Section,
  BackgroundImg,
  Info,
  InfoChange,
  Avatar,
  Member,
  MemberWrapper,
  MemberName,
  MemberUserName,
  Descr,
  DescrText,
  DescrList,
  DescrItem,
  DescrItemText,
  DescrItemLink,
  Skills,
  SkillsItem,
  SkillsDepartment,
  SkillsStackList,
  SkillsStackItem,
  Projects,
  ProfileTitle,
  OtherMembers,
  Header,
  Badge,
  Divider,
  Portfolio,
  SkillsWrapper,
  Grid,
  InfoText,
  InfoChangeText
} from './style';
import ReactMarkdown from 'react-markdown';
import { MailIcon, MapPinIcon, FlagIcon } from './icon';
import { Link } from 'react-router-dom';
import { SocialIcons } from './SocialIcons';
import { MemberCard } from './MemberCard';
import { MemberRating } from './MemberRating';
import { PortfolioNode } from './PortfolioNode';
import { ProjectsNode } from './ProjectsNode';
import { Container } from './Container';
import { ProjectsEmpty } from './ProjectsEmpty';
import { useQuery } from '@tanstack/react-query';
import { getMember } from '../../api/Members';
import IMember from '../../api/types/interface/IMember';
import ProfileProjects from './ProfileProjects';
import Preloader from '../../layout/Main/Preloader';
import ClassMember from '../../api/types/class/Member';
import LinkType from '../../api/types/LinkType';
import { getUserById } from '../../api/UserApi';

interface IProps {
  owner: boolean;
}

const MemberProfile: FC<IProps> = ({ owner }) => {
  const { isLoading, error, data } = useQuery(['members'], getMember);

  if (isLoading) return <Preloader />;
  if (error) return <div>Ошибка</div>;
  if (!data) return <></>;

  const otherMembers = ClassMember.CreateTestDataArray(2);

  return (
    data && (
      <Section>
        <Container>
          {!owner && <BackgroundImg />}
          <Info>
            <InfoText>
              <Header>
                <Link to="photo">
                  <Avatar src={data.profilePhoto?.url} />
                </Link>
                <Badge src="/assets/img/user-rating.svg" />
              </Header>

              <Member>
                <MemberWrapper>
                  <MemberName>
                    {data.surname} {data.name}
                  </MemberName>
                </MemberWrapper>

                <MemberUserName>{data.nickname}</MemberUserName>
              </Member>
            </InfoText>

            {owner && (
              <InfoChange to="edit">
                <InfoChangeText>Изменить данные</InfoChangeText>
              </InfoChange>
            )}
          </Info>

          {owner && data?.userRating && (
            <MemberRating userRating={data.userRating} currentRatingNum={data.currentRatingNum} />
          )}

          {owner && <ProfileProjects />}

          {!owner && (
            <Descr>
              <DescrText>
                {data?.about ? <ReactMarkdown>{data.about}</ReactMarkdown> : ''}
              </DescrText>

              <DescrList>
                <DescrItem>
                  <MapPinIcon color="var(--color-blue-1)" />
                  <DescrItemText>{data.location}</DescrItemText>
                </DescrItem>

                <DescrItem>
                  <MailIcon color="var(--color-blue-1)" />
                  <DescrItemLink href={`mailto: ${data.email}`}>{data.email}</DescrItemLink>
                </DescrItem>

                <DescrItem>
                  <FlagIcon color="var(--color-blue-1)" />

                  <DescrItemText>Состоит с {data.timeInGuild}</DescrItemText>
                </DescrItem>

                <DescrItem>
                  {data.socialLinks?.map((link) => (
                    <DescrItemLink href={`${link.url}`}>
                      <SocialIcons type={link.type || LinkType.Unknown} />
                    </DescrItemLink>
                  ))}
                </DescrItem>
              </DescrList>
            </Descr>
          )}

          {!owner && (
            <Skills>
              <SkillsWrapper>
                {data.department
                  ? data.department.map((department) => (
                      <SkillsItem>
                        <SkillsDepartment>{department.name}</SkillsDepartment>
                        <SkillsStackList>
                          {department.stacks?.map((stack) => (
                            <SkillsStackItem>{stack.name}</SkillsStackItem>
                          ))}
                        </SkillsStackList>
                      </SkillsItem>
                    ))
                  : null}
              </SkillsWrapper>
            </Skills>
          )}

          {!owner && (
            <Projects>
              <ProfileTitle>Проекты GoD</ProfileTitle>
              {data.projects?.length ? (
                <ProjectsNode projects={data.projects} />
              ) : (
                <ProjectsEmpty
                  data="Проекты находятся в разработке.
              Гильдиец очень старается над своими задачами"
                />
              )}
            </Projects>
          )}

          {!owner && (
            <Portfolio>
              <ProfileTitle>Портфолио</ProfileTitle>
              {data.portfolio?.length ? (
                <PortfolioNode portfolio={data.portfolio} />
              ) : (
                <ProjectsEmpty data="Гильдиец ещё не добавил личные проекты" />
              )}
            </Portfolio>
          )}

          {!owner && <Divider />}

          {!owner && (
            <OtherMembers>
              <ProfileTitle>Другие гильдийцы</ProfileTitle>
              <Grid>
                {otherMembers?.map((member: IMember) => (
                  <MemberCard member={member} />
                ))}
              </Grid>
            </OtherMembers>
          )}
        </Container>
      </Section>
    )
  );
};

export default MemberProfile;
