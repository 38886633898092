import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TelegramLoginButton from 'react-telegram-login';
import { IconHide, IconVisible } from '../RegistrationForm/Icon';
import {
  Flex,
  ButtonEye,
  InputEmail,
  InputPassword,
  Label,
  LabelText,
  NextButton,
  RegForm,
  Section,
  Title,
  Typography,
  Wrapper,
  EmptySection,
  HalfContainer
} from './style';
import Auth, { IAuthApiResponse } from '../../api/Auth';
import ITelegramUserData from '../../api/types/interface/ITelegramUserData';
import { useDispatch } from 'react-redux';
import { LOGIN } from '../../services/actions/users';
import { requestLoginByMail } from '../../api/UserApi';

// Example POST method implementation:
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  return response.json();
}

const LoginForm: FC = () => {
  const dispatch = useDispatch<any>();
  const [visiblePassword, setVisiblePassword] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAuthOk = (authDate: IAuthApiResponse) => {
    navigate('/profile');
  };
  const handleAuthError = (msg: string) => {
    console.log(msg);
  };

  const handleTelegramResponse = async (response: ITelegramUserData) => {
    await Auth.AuthByTelegram(response, handleAuthOk, handleAuthError);
  };

  const handleAuthByEmail = async () => {
    const loggedUser = { email, password };
    dispatch(LOGIN(loggedUser));
    navigate('/profile');
  };
  return (
    <>
      <EmptySection />
      <Section primary>
        <HalfContainer>
          <Wrapper>
            <Title>Вход в аккаунт</Title>
            <TelegramLoginButton dataOnauth={handleTelegramResponse} botName="GoDAuthBot" />
            <RegForm>
              <Label>
                <LabelText>Email*</LabelText>
                <InputEmail
                  value={email}
                  placeholder="Введите"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Label>
              <Label>
                <LabelText>Пароль*</LabelText>

                <InputPassword
                  value={password}
                  type={visiblePassword ? 'text' : 'password'}
                  placeholder="Введите"
                  onChange={(e) => setPassword(e.target.value)}
                />

                <ButtonEye onClick={() => setVisiblePassword(!visiblePassword)}>
                  {!visiblePassword && <IconHide />}
                  {visiblePassword && <IconVisible />}
                </ButtonEye>
              </Label>
              <Typography size="12px">
                <Link to="/forgetPassword">Не помню пароль</Link>
              </Typography>
            </RegForm>
            <NextButton onClick={() => handleAuthByEmail()}>Войти</NextButton>
            <Flex>
              <Typography>Ты здесь впервые?</Typography>
              <Typography>
                <Link to="/registration" style={{ fontWeight: 'bold' }}>
                  Зарегистрируйся
                </Link>
              </Typography>
              <Typography>или войди с помощью Telegram</Typography>
            </Flex>
          </Wrapper>
        </HalfContainer>
      </Section>
    </>
  );
};

export default LoginForm;
