import React, { FC } from 'react';
import Hero from '../components/Hero';
import { Projects2 } from '../components/Projects';

const Home: FC = () => {
  return (
    <>
      <Hero />
      <Projects2 title="Наши проекты" showGoToAllButton={true} showFilter={false} />
    </>
  );
};

export default Home;
