import React, { FC } from 'react';
import { Picture, Img } from './style';

export interface ResponsiveImageProps {
  imageSrcSet: string;
  imageSrc: string;
  imageSizes: string;
  imageAlt: string;
}
export const ResponsiveImage: FC<ResponsiveImageProps> = (data) => {
  return (
    <Picture>
      <source srcSet={data.imageSrcSet} sizes={data.imageSizes} />
      <Img src={data.imageSrc} alt={data.imageAlt} />
    </Picture>
  );
};

export default ResponsiveImage;
