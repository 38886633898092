import React from 'react';

export const LogoIcon = () => {
  return (
    <svg
      width="180"
      height="99"
      viewBox="0 0 180 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.5782 84.8361C34.0483 84.8361 28.8739 83.6971 24.055 81.4191C19.2361 79.141 15.0097 75.999 11.3758 71.9928C7.82083 67.9867 5.01639 63.4307 2.96244 58.3248C0.987479 53.2189 0 47.7988 0 42.0645C0 36.3303 0.987479 30.9494 2.96244 25.9221C5.01639 20.8948 7.82083 16.4174 11.3758 12.4898C15.0097 8.56216 19.2361 5.49863 24.055 3.29918C28.9529 1.09973 34.2853 0 40.0522 0C48.031 0 54.7458 1.68887 60.1967 5.0666C65.7266 8.36577 69.874 12.7647 72.639 18.2633L62.4482 25.5686C60.2362 21.0126 57.0763 17.5956 52.9684 15.3176C48.9395 13.0396 44.5156 11.9006 39.6967 11.9006C35.7467 11.9006 32.1523 12.7254 28.9134 14.375C25.6745 16.0246 22.87 18.3026 20.5001 21.209C18.2091 24.0369 16.4712 27.2968 15.2862 30.9887C14.1012 34.6021 13.5087 38.4119 13.5087 42.418C13.5087 46.6598 14.1802 50.6267 15.5232 54.3186C16.8661 58.0106 18.7621 61.2705 21.2111 64.0984C23.66 66.8477 26.5434 69.0079 29.8614 70.5789C33.1793 72.1499 36.7737 72.9354 40.6446 72.9354C45.2265 72.9354 49.5714 71.7964 53.6794 69.5184C57.8663 67.1619 61.7767 63.6663 65.4106 59.0318V72.4641C58.0638 80.7121 49.453 84.8361 39.5782 84.8361ZM64.1071 51.7264H39.6967V41.9467H65.4106L64.6107 50.3714L65.6557 62.1516L71.373 77.2746L75.2459 84.2469L67.8689 80.041L64.1071 51.7264Z"
        fill="white"
      />
      <path
        d="M109.918 84.0985L112.5 73.4017L118.217 51.8239L109.918 36.3321L109.918 0.737793H139.451C148.436 0.737793 155.936 2.61634 161.952 6.37344C167.968 10.0523 172.46 15.0617 175.429 21.4018C178.476 27.6637 180 34.63 180 42.3007C180 50.7542 178.32 58.1118 174.961 64.3737C171.679 70.6355 166.991 75.4884 160.897 78.9324C154.803 82.3765 147.654 84.0985 139.451 84.0985H109.918ZM166.64 42.3007C166.64 36.4302 165.546 31.2642 163.358 26.8027C161.249 22.2629 158.163 18.7406 154.1 16.2358C150.115 13.6528 145.232 12.3613 139.451 12.3613H123.044V72.4749H139.451C145.311 72.4749 150.233 71.1443 154.217 68.483C158.28 65.8217 161.366 62.2212 163.476 57.6813C165.585 53.1415 166.64 48.0146 166.64 42.3007Z"
        fill="white"
      />
      <g filter="url(#filter0_d_1893_14497)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M89.8156 91.4754C107.64 91.4754 122.09 77.0256 122.09 59.2009C122.09 41.3761 107.64 26.9263 89.8156 26.9263C71.9908 26.9263 57.541 41.3761 57.541 59.2009C57.541 77.0256 71.9908 91.4754 89.8156 91.4754ZM89.9038 80.7301C103.522 80.7301 114.562 69.6904 114.562 56.0722C114.562 42.454 103.522 31.4142 89.9038 31.4142C76.2856 31.4142 65.2459 42.454 65.2459 56.0722C65.2459 69.6904 76.2856 80.7301 89.9038 80.7301Z"
          fill="url(#paint0_linear_1893_14497)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1893_14497"
          x="51.541"
          y="21.9263"
          width="76.5488"
          height="76.5491"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1893_14497" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1893_14497"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1893_14497"
          x1="90"
          y1="19.1804"
          x2="90"
          y2="98.6681"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#26C0F1" />
          <stop offset="1" stop-color="#6F35CE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
