import { createReducer } from '@reduxjs/toolkit';
import { LOGIN } from '../actions/users';

const initialState = {
  isLogin: false,
  status: 'not started',
  errmessage: '',
  access_token: '',
  refresh_token: '',
  id: ''
};

export const usersReduser = createReducer(initialState, (builder) => {
  builder
    .addCase(LOGIN.pending, (state, action) => {
      state.isLogin = false;
      state.status = action.type;
      state.errmessage = '';
      state.access_token = '';
      state.refresh_token = '';
    })
    .addCase(LOGIN.fulfilled, (state, action) => {
      state.isLogin = true;
      state.status = action.type;
      state.errmessage = '';
      state.access_token = action.payload.data.access_token;
      state.refresh_token = action.payload.data.refresh_token;
    })
    .addCase(LOGIN.rejected, (state, action) => {
      state.isLogin = true;
      state.status = action.type;
      state.errmessage = action.error.message;
      state.access_token = '';
      state.refresh_token = '';
    });
});

export default usersReduser;
