import IAuthTokenPayload from '../interface/IAuthTokenPayload';
import { Buffer } from 'buffer';

export default class AuthTokenPayload {
  /**
   * @constructor AuthTokenPayload
   * @param data Partial of AuthTokenPayload
   */
  public constructor(data: Partial<AuthTokenPayload>) {
    Object.assign(this, data);
  }

  /** идентификатор  пользователя */
  sub = 0;

  /** время истечения */
  exp = 0;

  /** время создания */
  iat = 0;

  info = '';

  /** токен в b64 */
  tokenStr = '';

  /**
   * CreateFromB64
   * @param b64 base64 token string
   */
  public static CreateFromB64(b64: string): AuthTokenPayload {
    if (b64 == '') throw new RangeError('Bad parameter: b64 token');
    const payloadB64 = b64.split('.')[1]; //[0-header].[1-payload].[2-hash]
    const jsonStr = Buffer.from(payloadB64, 'base64').toString('binary');
    const payload: IAuthTokenPayload = JSON.parse(jsonStr);
    const t = new AuthTokenPayload(payload);
    t.tokenStr = b64;
    return t;
  }

  /**
   * Проверка на срок жизни токена
   */
  public CheckAlive(): boolean {
    if (this.exp == 0) return false;
    if (this.iat == 0) return false;

    const issued = new Date(this.iat * 1000);
    const expires = new Date(this.exp * 1000);

    if (expires < issued) return false;
    if (issued > new Date()) return false;

    if (expires < new Date()) return false;

    return true;
  }
}
