import styled from 'styled-components';

export const Rating = styled.div`
  width: 100%;
  max-width: 996px;
  margin: 0 auto 128px;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  background: var(--gradient-2);
  color: var(--color-white);
  border-radius: 20px;

  @media (min-width: 768px) {
    padding: 32px 106px;
    flex-direction: row;
  }
`;

export const RatingInfo = styled.div`
  display: flex;
  gap: 27px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const RatingStar = styled.div``;

export const RatingName = styled.p`
  margin-bottom: 16px;
  font-size: var(--fs-500);
  font-weight: var(--fw-700);
  line-height: var(--line-height-550);
`;

export const RatingCaption = styled.p`
  font-size: var(--fs-450);
  font-weight: var(--fw-400);
  align-self: flex-end;
  text-align: center;
  padding-bottom: 2px;
`;

export const RatingPoints = styled.div``;

export const RatingPointsValue = styled.p`
  display: flex;
  gap: 6px;
  align-items: baseline;
  font-size: var(--fs-600);
  font-weight: var(--fw-700);
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
`;

export const RatingInfoText = styled.span`
  position: relative;
  z-index: 3;
`;

export const RatingInfoButton = styled.button`
  padding: 15px 20px;
  width: 100%;
  position: relative;
  white-space: nowrap;

  background: var(--gradient-1);
  border-radius: 25px;
  border: none;

  font-size: var(--fs-250);
  font-weight: var(--fw-600);
  line-height: var(--line-height-350);
  text-align: center;
  color: var(--color-white);

  transition: all 0.2s ease-in-out;

  &::before {
    position: absolute;
    content: '';
    inset: 0;
    background: var(--gradient-5);
    z-index: 2;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    border-radius: 25px;
  }

  &:hover::before {
    opacity: 1;
  }

  &:active::before {
    opacity: 0;
  }

  &:active {
    background: var(--gradient-2);
  }

  @media (min-width: 850px) {
    width: 281px;
  }

  @media (max-width: 850px) {
    min-width: 179px;
    white-space: normal;
  }
`;
