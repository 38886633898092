import React, { FC } from 'react';
import { Container } from '../../lib/styled/container';
import { Section, Wrapper, Title, ListBlock } from './style';
import { TeamFiguresOne, TeamFiguresOneProps } from '../TeamFiguresOne';

interface TeamFiguresManyProps {
  data: Array<TeamFiguresOneProps>;
}

const TeamFiguresMany: FC<TeamFiguresManyProps> = (props) => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Title>GoD в цифрах</Title>
          <ListBlock>
            {props.data.map((item: TeamFiguresOneProps, index) => (
              <TeamFiguresOne
                users_count={item.users_count}
                department_title={item.department_title}
                image_path={item.image_path}
                key={index}
              />
            ))}
          </ListBlock>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default TeamFiguresMany;
