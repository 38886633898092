import styled from 'styled-components';

export const MainWrapper = styled.main`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  > * {
    flex-grow: 1;
  }
`;
