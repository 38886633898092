import React, { FC } from 'react';
import { Menu } from '@headlessui/react';
import { Wrapper, LeftFilter, FilterButtons, MenuButton, MenuItems } from './style';
import FilterOpen from '../FilterOpen';
import { IconFilter } from './Icon';
import { useMediaQuery } from '../../../services/useMediaQuery';
import { useFilterStore } from '../../../zustand-store';

interface FilterBtnProps {
  projects?: string;
}

const FilterProjects: FC<FilterBtnProps> = ({ projects }) => {
  const { projectsType, changeProjectsType } = useFilterStore();
  const isMobile = useMediaQuery('(max-width: 460px)');

  return (
    <Wrapper>
      <LeftFilter>
        {projectsType.map((item, i) => (
          <FilterButtons
            to={item.name === 'Текущие' ? '/currentProjects' : '/doneProjects'}
            key={i}
            active={projects === item.name}
            onClick={() => changeProjectsType(item.name)}
          >
            {item.name}
          </FilterButtons>
        ))}
      </LeftFilter>

      <Menu>
        <MenuButton>
          {isMobile ? <IconFilter isMobile /> : <IconFilter isMobile={false} />}
        </MenuButton>

        <MenuItems>
          <FilterOpen />
        </MenuItems>
      </Menu>
    </Wrapper>
  );
};

export default FilterProjects;
