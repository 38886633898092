import { Spinner, SpinnerWrapper } from './style';

const Preloader = () => {
  return (
    <SpinnerWrapper>
      <Spinner />
    </SpinnerWrapper>
  );
};

export default Preloader;
