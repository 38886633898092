import { FC } from 'react';
import {
  Card,
  CardDescription,
  CardImage,
  CardLinks,
  ContainedLink,
  OutlinedLink,
  Section
} from './style';

interface ErrorsProps {
  bgImageUrl: string;
}

export const Error404: FC<ErrorsProps> = (data) => {
  return (
    <Section style={{ backgroundImage: `url("${data.bgImageUrl}")` }}>
      <Card>
        <CardImage src="/assets/img/error-404.png" />
        <CardDescription>Что-то пошло не по плану...</CardDescription>
        <CardLinks>
          <OutlinedLink to="/">На главную</OutlinedLink>
          <ContainedLink to="/currentProjects">Найти проект</ContainedLink>
        </CardLinks>
      </Card>
    </Section>
  );
};
