const BASE_URL = 'https://api.telegram.org';
const BOT_TOKEN = 'bot6590035885:AAFY_XGxTQNKn3_IXTmJEUGM8ObK8KBPYe0';
const CHAT_ID = '-880680328';

const checkResponse = (res: { ok: unknown; status: unknown }) => {
  if (res.ok) {
    return res;
  }
  return Promise.reject(`Ошибка ${res.status}`);
};

export const sendMessage = (text: string) => {
  return fetch(
    `${BASE_URL}/${BOT_TOKEN}/sendMessage?chat_id=${encodeURIComponent(
      CHAT_ID
    )}&text=${text}&parse_mode=HTML`,
    {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    }
  ).then(checkResponse);
};
