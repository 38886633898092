import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Section = styled.section`
  background-color: var(--color-fon);
`;

export const Title = styled.h1`
  font-size: var(--fs-600);
  max-width: 792px;
  font-weight: var(--fw-700);
  line-height: var(--line-height-600);
  color: var(--color-white);
  position: relative;

  text-align: center;
  margin: 81px auto 32px;

  @media (min-width: 768px) {
    margin: 105px auto 39px;
  }
`;

export const TitleText = styled.span`
  display: block;
  width: 60%;
  margin: 0 auto;
`;

export const ArrowLink = styled(Link)`
  position: absolute;
  left: 0;
  top: 9px;
`;

export const Wrapper = styled.form`
  position: relative;
  max-width: 792px;
  padding: 24px 12px;
  margin: 0 auto 64px;
  background: var(--gradient-2);
  border-radius: 20px;

  @media (min-width: 768px) {
    padding: 48px 101px;
    margin: 0 auto 128px;
  }

  @media (max-width: 1024px) {
    padding: 48px 58px;
    margin: 0 auto 128px;
  }

  @media (max-width: 768px) {
    padding: 48px 32px;
  }
`;

export const SubTitle = styled.h2`
  font-size: var(--fs-600);
  font-weight: var(--fw-700);
  line-height: var(--line-height-600);
  color: var(--color-white);

  text-align: center;
  margin: 0 0 24px;

  @media (min-width: 768px) {
    margin: 0 0 32px;
  }
`;

export const PhotoDiv = styled.div`
  margin-bottom: 88px;

  @media (min-width: 768px) {
    margin-bottom: 64px;
  }
`;

interface PhotoBoxProps {
  minWidth: string;
  maxWidth?: string;
  height: string;
  marginMob?: string;
  marginDesk?: string;
  border: string;
}

export const PhotoBox = styled.div<PhotoBoxProps>`
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  height: ${(props) => props.height};
  margin: ${(props) => props.marginMob};
  background-color: var(--color-white);
  z-index: 10;

  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  border: ${(props) => props.border};
  border-radius: 50%;

  @media (min-width: 768px) {
    margin: ${(props) => props.marginDesk};
    border: 2px solid var(--color-blue-1);
  }
`;

export const PhotoImg = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  z-index: 1;
`;

interface ButtonsBoxProps {
  paddingBlock: string;
  deskGap: string;
}

export const ButtonsBox = styled.div<ButtonsBoxProps>`
  display: flex;
  align-items: center;
  margin-top: 32px;
  flex-direction: column;
  gap: 26px;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    gap: ${(props) => props.deskGap};
    padding-block: ${(props) => props.paddingBlock};
  }
`;

export const FileInput = styled.input`
  display: none;
`;

interface BlackBtnProps {
  margin?: string;
  display?: string;
}

export const BlackBtn = styled.button<BlackBtnProps>`
  min-width: 179px;
  padding: 13px 50px;
  margin: ${(props) => props.margin};
  display: ${(props) => props.display};

  background-color: var(--color-violet-1);
  border-radius: 25px;
  border: 2px solid var(--color-violet-1);

  font-size: var(--fs-250);
  font-weight: var(--fw-600);
  line-height: var(--line-height-450);
  text-align: center;
  color: var(--color-white);
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: var(--color-violet-2);
    border-color: var(--color-violet-2);
  }

  &:active {
    background-color: var(--color-violet-3);
    border-color: var(--color-violet-3);
  }

  @media (max-width: 768px) {
    min-width: 233px;
  }
`;

export const ActionBtnBox = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 560px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
`;

export const ActionBtn = styled.button`
  min-width: 282px;
  max-width: 282px;
  padding: 13px 24px;
  position: relative;
  z-index: 1;

  border-radius: 25px;
  background: var(--gradient-1);

  font-size: var(--fs-250);
  font-weight: var(--fw-600);
  line-height: var(--line-height-450);
  text-align: center;
  color: var(--color-white);
  transition: all 0.2s ease-in-out;

  &::before {
    position: absolute;
    content: '';
    inset: 0;
    background: var(--gradient-5);
    z-index: 2;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    border-radius: 25px;
  }

  &:hover::before {
    opacity: 1;
  }

  &:active::before {
    opacity: 0;
  }

  &:active {
    background: var(--gradient-2);
  }

  @media (max-width: 768px) {
    min-width: 233px;
  }
`;

export const ActionBtnText = styled.span`
  position: relative;
  z-index: 3;
`;

interface WhiteBtnProps {
  marginTop?: string;
  width?: number;
  padding?: string;
}

export const WhiteBtn = styled.button<WhiteBtnProps>`
  min-width: ${({ width }) => (width ? width : 179)}px;
  padding: ${({ padding }) => (padding ? padding : '13px 50px')};
  margin-top: ${(props) => props.marginTop};

  border-radius: 25px;
  border: 2px solid var(--color-blue-1);

  font-size: var(--fs-250);
  font-weight: var(--fw-600);
  line-height: var(--line-height-450);
  text-align: center;
  color: var(--color-blue-1);
  transition: all 0.2s ease-in-out;

  &:hover {
    color: var(--color-violet-1);
    border-color: var(--color-violet-1);
  }

  &:active {
    color: var(--color-white);
    background-color: var(--color-blue-1);
    border-color: var(--color-blue-1);
  }

  @media (max-width: 768px) {
    min-width: 233px;
  }
`;

export const CoverDiv = styled.div`
  margin-bottom: 64px;
  @media (min-width: 768px) {
    margin-bottom: 88px;
  }
`;

export const CoverBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 571px;
  width: 100%;
  height: 185px;
  background: var(--gradient-1);
  border-radius: 15px;
  margin: 0 auto;
  @media (max-width: 768px) {
    min-height: 72px;
    min-width: 232px;
  }

  @media (max-width: 530px) {
    height: 150px;
  }

  @media (max-width: 450px) {
    height: 72px;
  }
`;

export const CoverImg = styled.img`
  width: 100%;
  height: 185px;
  object-fit: cover;
  border-radius: 15px;
`;

export const CropDiv = styled.div`
  position: absolute;
  min-height: 750px;
  top: 15%;
  left: 10%;
  right: 10%;
  bottom: 25%;
  z-index: 100;
  border: 1px solid black;
  background-color: var(--color-grey-2);
`;

export const CropControls = styled.div`
  position: absolute;
  bottom: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  z-index: 101;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const CroppBtnText = styled.span`
  position: relative;
  z-index: 3;
`;

export const CroppBtn = styled.button`
  min-width: 179px;
  padding: 13px 50px;
  border-radius: 25px;
  background: var(--gradient-1);
  position: relative;

  font-size: var(--fs-250);
  font-weight: var(--fw-600);
  line-height: var(--line-height-450);
  text-align: center;
  color: var(--color-white);
  transition: all 0.2s ease-in-out;

  &::before {
    position: absolute;
    content: '';
    inset: 0;
    background: var(--gradient-5);
    z-index: 2;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    border-radius: 25px;
  }

  &:hover::before {
    opacity: 1;
  }

  &:active::before {
    opacity: 0;
  }

  &:active {
    background: var(--gradient-2);
  }
`;
