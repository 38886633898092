import React, { FC } from 'react';
import { Card, Header, Title, Button, TextBlock, Avatar, Paragraph } from './style';
import { IconClose } from './icon';

export interface HidingPanelProps {
  title: string;
  isOpenedDefault?: boolean;
  text: string | undefined;
  avatar?: string;
  background?: string;
  textColor?: string;
  borderTop?: string;
  iconClose?: React.ReactElement;
  projectPage?: boolean;
}

export interface HidingPanelStylesProps {
  background?: string;
  textColor?: string;
  borderTop?: string;
  iconClose?: React.ReactElement;
}

const HidingPanel: FC<HidingPanelProps> = (props) => {
  const [IsOpened, setIsOpened] = React.useState(props.isOpenedDefault || false);

  const togglePanel = () => setIsOpened(!IsOpened);

  return (
    <Card projectPage={props.projectPage} color={props.background}>
      <Header projectPage={props.projectPage} onClick={togglePanel}>
        <Title projectPage={props.projectPage} color={props.textColor}>
          {props.title}
        </Title>
        <Button projectPage={props.projectPage} className={IsOpened ? 'opened' : ''}>
          {props.iconClose ? props.iconClose : <IconClose projectPage={props.projectPage} />}
        </Button>
      </Header>
      {IsOpened ? (
        <>
          <TextBlock
            projectPage={props.projectPage}
            color={props.textColor}
            theme={{ borderTop: props.borderTop }}
          >
            {props.avatar ? <Avatar src={props.avatar} /> : null}
            {props.avatar ? <Paragraph>{props.text}</Paragraph> : null}
            {!props.avatar ? <>{props.text}</> : null}
          </TextBlock>
        </>
      ) : null}
    </Card>
  );
};

export default HidingPanel;
