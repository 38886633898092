import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const EmptySection = styled.section`
  width: 50%;
  background: url(/assets/img/forget-background.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const HalfContainer = styled.div`
  max-width: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const Section = styled.section<{ primary?: boolean }>`
  background: ${(props) => (props.primary ? '#0C182C' : 'white')};
  color: ${(props) => (props.primary ? 'white' : 'red')};
  width: 50%;

  display: flex;
  justify-content: flex-start;
  @media (max-width: 768px) {
    width: 100%;
    background: none;
    z-index: 2;
  }
`;

export const Wrapper = styled.div`
  max-width: 768px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  @media (max-width: 768px) {
    margin-inline: auto;
  }
`;

export const Title = styled.h2`
  text-align: center;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
`;

export const RegForm = styled.form`
  padding: 24px 25px;
  background: var(--gradient-2);
  border-radius: 20px;
  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 33px;

  @media (max-width: 768px) {
    max-width: 288px;
    margin-bottom: 0;
  }
`;

export const Label = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  gap: 10px;
  position: relative;
  flex-grow: 1;

  @media (min-width: 768px) {
    width: initial;

    ${({ wfill }: { wfill?: boolean }) => {
      return (
        wfill &&
        css`
          width: 100%;
        `
      );
    }}
  }
`;

export const LabelText = styled.span`
  font-size: var(--fs-450);
  font-weight: var(--fw-600);
  line-height: var(--line-height-500);
`;

export const InputEmail = styled.input.attrs({
  type: 'email'
})`
  width: 100%;
  padding: 15px 24px;
  border: 1px solid transparent;
  border-radius: 15px;
  font-weight: 600;
  background: var(--color-white);

  &::placeholder {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    max-width: 240px;
  }
`;

export const NextButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: var(--color-white);

  border-radius: 25px;
  width: 179px;
  height: 40px;

  transition: opacity 0.25s;

  position: relative;
  background: var(--gradient-1);
  z-index: 1;
  &::before {
    border-radius: 25px;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--gradient-4);
    z-index: -1;
    transition: opacity 0.5s;
    opacity: 0;
  }
  &:hover::before {
    opacity: 1;
  }
  @media (max-width: 768px) {
    width: 288px;
    font-weight: 600;
    font-size: 16px;
    padding: 9px 24px;
  }
`;

export const Divider = styled.span`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Typography = styled('p')<{ size?: string; weight?: string }>`
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  line-height: 20px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const StyledLink = styled(Link)`
  font-weight: 700;
`;
