import styled from 'styled-components';

export const Card = styled.div<{ projectPage?: boolean | undefined }>`
  margin-bottom: 24px;
  background: ${({ color, projectPage }) =>
    projectPage ? '#6f35ce' : color || 'var(--gradient-2)'};
  border-radius: ${(props) => (props.projectPage ? '20px' : '15px')};
  user-select: none;
`;

export const Header = styled.div<{ projectPage?: boolean | undefined }>`
  display: flex;
  height: ${(props) => (props.projectPage ? '48px' : '')};
  border-radius: ${(props) => (props.projectPage ? '20px' : '15px')};
  justify-content: space-between;
`;

export const Title = styled.h3<{ projectPage?: boolean | undefined }>`
  padding: ${(props) => (props.projectPage ? '11px 4px 13px 32px' : '31px 44px 32px 44px')};
  font-size: ${(props) => (props.projectPage ? 'var(--fs-350)' : 'var(--fs-900)')};
  font-weight: ${(props) => (props.projectPage ? 'var(--fw-600)' : 'var(--fw-600)')};
  line-height: var(--line-height-800);
  align-self: center;
  color: ${({ color }) => color || 'var(--color-white)'};

  @media (max-width: 560px) {
    padding: 24px 6px 27px 24px;
    font-size: var(--fs-250);
    line-height: var(--line-height-750);
  }
`;

export const Button = styled.div<{ projectPage?: boolean | undefined }>`
  padding: ${(props) => (props.projectPage ? '14px 34px' : '27px 58px')};
  cursor: pointer;

  &.opened svg {
    transform: rotate(45deg);
  }

  @media (max-width: 560px) {
    padding: ${(props) => !props.projectPage && '24px}'};
  }

  svg {
    width: ${(props) => (props.projectPage ? '21.5px' : '64px')};
    height: ${(props) => (props.projectPage ? '21.5px' : '64px')};
    border-radius: ${(props) => (props.projectPage ? '15px' : '0px')};
    background: ${(props) => (props.projectPage ? '#26c0f1' : '')};
  ${(props) => !props.projectPage && '@media (max-width: 560px) {width: 32px; height: 32px}'};
}
}
`;

export const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--color-grey-3);
  object-fit: cover;
  overflow: hidden;
  flex-shrink: 0;

  @media (min-width: 768px) {
    width: 70px;
    height: 70px;
  }
`;

export const TextBlock = styled('div')<{ projectPage?: boolean | undefined }>`
  display: flex;
  align-items: center;
  padding: ${(props) => (props.projectPage ? '25px' : '45px')};
  font-size: var(--fs-900);
  font-weight: ${(props) => (props.projectPage ? 'var(--fw-600)' : 'var(--fw-400)')};
  line-height: var(--line-height-800);
  // color: var(--color-white);
  color: ${({ color, projectPage }) => (projectPage ? '#26c0f1' : color || 'var(--color-white)')};
  white-space: pre-wrap;
  // border-top: 2px solid var(--color-grey1);
  border-top: 2px solid ${({ theme }) => theme.borderTop || 'var(--color-grey1)'};

  @media (max-width: 560px) {
    padding: 24px 21px 29px 24px;
    font-size: var(--fs-450);
    line-height: var(--line-height-800);
  }
`;

export const Paragraph = styled.p`
  display: block;
  margin-left: 20px;
`;
