import React, { FC } from 'react';
import { Error404 } from './Error404';
import { Error500 } from './Error500';

interface ErrorsProps {
  page: string;
}

export const Errors: FC<ErrorsProps> = (data) => {
  return data.page === '404' ? (
    <Error404 bgImageUrl="/assets/img/error-background.webp" />
  ) : (
    <Error500 bgImageUrl="/assets/img/error-background.webp" />
  );
};
