import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Section = styled.div`
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;

  padding-block: 120px;

  @media (max-width: 768px) {
    padding-block: 10%;
  }
`;

export const Card = styled.div`
  width: 792px;
  height: 700px;

  background: linear-gradient(129.36deg, #205768 -9.17%, #33185f 105.44%);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: 500px;
    width: 90%;
  }
`;

export const CardImage = styled.img`
  width: 588px;
  height: 233px;
  @media (max-width: 768px) {
    width: 70%;
    height: auto;
  }
`;

export const CardDescription = styled.p`
  margin-top: 63px;
  width: 379px;
  line-height: 54px;
  font-size: 46px;

  font-style: normal;
  font-weight: var(--fw-700);
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--color-white);

  @media (max-width: 768px) {
    max-width: 75%;
    line-height: 3rem;
    font-size: 2rem;
  }
`;

export const CardLinks = styled.div`
  margin-top: 34px;
  gap: 20px;

  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContainedLink = styled(Link)`
  min-width: 183px;
  min-height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--gradient-1);
  color: var(--color-white);
  font-size: 16px;
  font-weight: var(--fw-600);

  border-radius: 25px;
  line-height: 22px;

  transition: transform 0.3s ease-out;

  &:hover,
  &:focus-visible {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(1.05);
  }
`;

export const OutlinedLink = styled(Link)`
  min-width: 184px;
  min-height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--color-blue-1);
  font-size: 16px;
  font-weight: var(--fw-600);

  border-radius: 25px;
  line-height: 22px;
  border: 2px solid var(--color-blue-1);

  transition: transform 0.3s ease-out;

  &:hover,
  &:focus-visible {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(1.05);
  }
`;
