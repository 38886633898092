import {
  Indicator,
  IndicatorWrapper,
  IndicatorIndication,
  IndicatorTrack,
  IconRatingWrapper
} from './style';
import { IconRating } from './icon';
import React from 'react';

export const RatingProgress: React.FC = () => {
  return (
    <IndicatorWrapper>
      <IconRatingWrapper>
        <IconRating />
      </IconRatingWrapper>
      <Indicator>
        <IndicatorTrack />
        <IndicatorIndication />
      </Indicator>
    </IndicatorWrapper>
  );
};
