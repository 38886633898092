export const TelegramIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <path
        d="M21.9998 40.3332C32.1251 40.3332 40.3332 32.1251 40.3332 21.9998C40.3332 11.8746 32.1251 3.6665 21.9998 3.6665C11.8746 3.6665 3.6665 11.8746 3.6665 21.9998C3.6665 32.1251 11.8746 40.3332 21.9998 40.3332Z"
        fill="white"
        stroke="#26C0F1"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.8049 15.6902L28.0455 28.7829C27.8373 29.7069 27.2944 29.9369 26.5229 29.5016L22.3184 26.3845L20.2897 28.3476C20.0652 28.5734 19.8774 28.7623 19.4447 28.7623L19.7468 24.4543L27.5393 17.3699C27.8781 17.066 27.4658 16.8976 27.0127 17.2015L17.3792 23.3043L13.2319 21.9983C12.3298 21.715 12.3135 21.0907 13.4197 20.6554L29.6415 14.3678C30.3926 14.0844 31.0498 14.5362 30.8049 15.6902Z"
        fill="#26C0F1"
      />
    </svg>
  );
};

export const ArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <path
        d="M22.0001 40.3332C32.1253 40.3332 40.3334 32.1251 40.3334 21.9998C40.3334 11.8746 32.1253 3.6665 22.0001 3.6665C11.8749 3.6665 3.66675 11.8746 3.66675 21.9998C3.66675 32.1251 11.8749 40.3332 22.0001 40.3332Z"
        fill="white"
        stroke="#26C0F1"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6666 21.9998H29.3333M29.3333 21.9998L22.9166 15.5832M29.3333 21.9998L22.9166 28.4165"
        stroke="#26C0F1"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
