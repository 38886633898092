import React, { FC } from 'react';

import {
  Section,
  Wrapper,
  PictureWrapper,
  TextBlockWrapper,
  TextBlock,
  Title,
  DescrP1,
  DescrP2
} from './style';
import { ResponsiveImage, ResponsiveImageProps } from '../ResponsiveImage';

interface HeroAboutProps {
  title: string;
  descr: string;
  titleSize?: string;
  image: ResponsiveImageProps;
  descrp1?: string;
  descrp2?: string;
}

const HeroAbout: FC<HeroAboutProps> = (data) => {
  return (
    <Section>
      <Wrapper>
        <TextBlockWrapper>
          <TextBlock>
            <Title fontSize={data.titleSize}>{data.title}</Title>
            <DescrP1>{data.descrp1}</DescrP1>
            <DescrP2>{data.descrp2}</DescrP2>
          </TextBlock>
        </TextBlockWrapper>
        <PictureWrapper>
          <ResponsiveImage
            imageSizes={data.image.imageSizes}
            imageSrcSet={data.image.imageSrcSet}
            imageSrc={`${data.image.imageSrc}.replace("http://135.181.198.180:52641", "https://guild-of-developers.ru/img)`}
            imageAlt={data.image.imageAlt}
          />
        </PictureWrapper>
      </Wrapper>
    </Section>
  );
};

export default HeroAbout;
