import styled from 'styled-components';

interface FlexProps {
  gap?: string;
  minWidth?: string;
  flexDirection?: string;
  padding?: string;
  mt?: string;
  ml?: string;
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  flex-wrap: wrap;
  min-width: ${(props) => props.minWidth};
  gap: ${(props) => props.gap};
  flex-direction: ${(props) => props.flexDirection};
  padding: ${(props) => props.padding};
  margin-top: ${(props) => props.mt};
  margin-left: ${(props) => props.ml};
`;
