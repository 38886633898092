import create from 'zustand';
import { devtools } from 'zustand/middleware';
import Project from '../api/types/class/Project';
import { TestStatus } from '../api/types/interface/ITestTask';
import { FormEvent } from 'react';
import IDepartment from '../api/types/interface/IDepartment';
import { IHireStatistics } from '../api/types/interface/IHireStatistics';
import ISocialLink from '../api/types/interface/ISocialLink';
interface IRegistrationStore {
  name: string;
  lastName: string;
  nickname: string;
  city: string;
  telegram: string;
  email: string;
  git: string;
  portfolio: string;
  text: string;
  password: string;
  confirmPassword: string;
  departments: {
    name: string;
    isCheck: boolean;
    stack: {
      name: string;
      isCheck: boolean;
    }[];
  }[];
  changeInput: (name: string, value: string) => void;
  checkedDepartments: () => {
    name: string;
    isCheck: boolean;
    stack: {
      name: string;
      isCheck: boolean;
    }[];
  }[];
  changeDeparments: (nameDepartment: string) => void;
  changeStack: (nameDepartment: string, nameStack: string) => void;
}

interface IFilterStore {
  departments: {
    name: string;
    isCheck: boolean;
    stack: {
      name: string;
      isCheck: boolean;
      departmentName: string;
    }[];
  }[];
  currentStack: {
    name: string;
    departmentName: string;
    isCheck: boolean;
  }[];
  date: {
    name: string;
    isCheck: boolean;
  }[];
  projectsType: {
    name: 'Текущие' | 'Выполненные';
    isCheck: boolean;
  }[];
  checkedDepartments: () => {
    name: string;
    isCheck: boolean;
    stack: {
      name: string;
      isCheck: boolean;
    }[];
  }[];
  changeDeparments: (nameDepartment: string) => void;
  changeCurrentStack: (nameStack: string) => void;
  changeDate: (nameDate: string) => void;
  changeProjectsType: (nameType: string) => void;
}

interface IProjectStore {
  name: string;
  date: string;
  description: string;
  workInfo: string;
  participantBenefit: string;
  role: string;
  departments: IDepartment[];
  projectLink: string;
  projectLinks: ISocialLink[];
  changeDepartments: (nameDepartment: string) => void;
  changeInput: (name: string, value: string) => void;
  checkedDepartments: () => IDepartment[];
  changeStack: (nameDepartment: string, nameStack: string) => void;
  changeProjectLinks: (i: number, link: string) => void;
  addProjectLink: () => void;
}

export enum WindowStates {
  None,
  Apply,
  ApplyWithTest,
  SuccessOfSending,
  Change,
  StatusCheck
}
export enum AsideBlockStates {
  Start,
  SuccessOfSending,
  Closed,
  Open,
  Success,
  Failure,
  Accepted,
  Status
}
export enum CardStates {
  Card = 'Карточка',
  EditCard = 'Редактировать карточку',
  AddLeads = 'Добавить тимлидов',
  Recruitment = 'Набор участников',
  Members = 'Участники проекта',
  Close = 'Закрыть проект'
}

interface IProjectCardStore {
  project?: Project;
  cardState: CardStates;
  isMenuCollapsed: boolean;
  setState: (state: CardStates) => void;
  setProject: (project: Project) => void;
  toggleMenu: () => void;
}

const useProjectCardStore = create<IProjectCardStore>()(
  devtools((set, get) => ({
    project: {},
    cardState: CardStates.Card,
    isMenuCollapsed: true,
    setState: (state) => {
      set({ cardState: state });
    },
    setProject: (project) => {
      set({ project: project });
    },
    toggleMenu: () => {
      set({ isMenuCollapsed: !get().isMenuCollapsed });
    }
  }))
);

interface IFullScreenProjectStore {
  departments: IDepartment[];
  project?: Project;
  data: Project[];
  solutionInput: string;
  sendedSolution: string;
  selectedDepartment: number;
  isTestSent: number;
  isApplyCompleted: boolean;
  isOpen: boolean | null;
  windowState: WindowStates;
  asideBlockState: AsideBlockStates;
  testStatus: TestStatus;
  setProject: (card: Project) => void;
  updateTestStatus: (status?: TestStatus) => void;
  setDepartments: () => void;
  setOpen: (bool: boolean) => void;
  apply: () => void;
  continueApply: () => void;
  checkStatus: () => void;
  changeSolution: () => void;
  finish: () => void;
  deliverySuccess: () => void;
  closeWindow: () => void;
  sendTest: (e: FormEvent) => void;
  changeSolutionInput: (payload: string) => void;
  reset: () => void;
  selectDepartment: (payload: number) => void;
  getProjStat: () => IHireStatistics[] | undefined;
}

const useFullScreenProjectStore = create<IFullScreenProjectStore>()(
  devtools((set, get) => ({
    project: {}, // текущий отображающийся проект
    data: Project.CreateTestDataArray(1000), // данные всех проектов
    departments: [],
    solutionInput: '', // ссылка на решение тестового
    sendedSolution: '', // отправленная ссылка
    selectedDepartment: 0,
    isTestSent: 0, // отправил ли он тестовое
    isApplyCompleted: false, // получил ли человек результат проверки тестового
    isOpen: null,
    windowState: WindowStates.None,
    asideBlockState: AsideBlockStates.Start,
    testStatus: TestStatus.Unknown,
    setProject(card: Project) {
      set({ project: card });
    },
    updateTestStatus(status) {
      set({ testStatus: status });
    },
    setDepartments() {
      set({ departments: get().project?.departments });
    },
    setOpen(bool: boolean) {
      set({ isOpen: bool });
    },
    apply() {
      set({ windowState: WindowStates.Apply });
    },
    continueApply() {
      set({ windowState: WindowStates.ApplyWithTest });
    },
    checkStatus() {
      set({ windowState: WindowStates.StatusCheck });
    },
    changeSolution() {
      set({ windowState: WindowStates.Change, solutionInput: get().sendedSolution });
    },
    finish() {
      set({
        isApplyCompleted: true,
        windowState: WindowStates.None,
        asideBlockState: AsideBlockStates.Status
      });
    },
    deliverySuccess() {
      set({
        windowState: WindowStates.SuccessOfSending,
        asideBlockState: AsideBlockStates.SuccessOfSending
      });
    },
    closeWindow() {
      set({ windowState: WindowStates.None });
    },
    sendTest(e) {
      e.preventDefault();
      //const { target } = e;
      if (get().solutionInput) {
        set({
          isTestSent: get().isTestSent + 1,
          testStatus: TestStatus.Success,
          windowState: WindowStates.None,
          sendedSolution: get().solutionInput,
          solutionInput: ''
        });
      }
    },
    changeSolutionInput(payload: string) {
      set({ solutionInput: payload });
    },
    reset() {
      set({
        departments: [],
        solutionInput: '',
        sendedSolution: '',
        selectedDepartment: 0,
        isTestSent: 0,
        isApplyCompleted: false,
        isOpen: null,
        windowState: WindowStates.None,
        asideBlockState: AsideBlockStates.Start,
        testStatus: TestStatus.Unknown
      });
    },
    selectDepartment(payload) {
      set({
        selectedDepartment: payload,
        departments: get().departments.map((dep) => {
          if (dep.id === payload) {
            dep.isCheck = true;
          } else {
            dep.isCheck = false;
          }
          return dep;
        })
      });
    },
    getProjStat() {
      return get().project?.statistics;
    }
  }))
);

const useRegistrationStore = create<IRegistrationStore>()(
  devtools((set, get) => ({
    name: '',
    lastName: '',
    nickname: '',
    city: '',
    telegram: '',
    email: '',
    git: '',
    portfolio: '',
    text: '',
    password: '',
    confirmPassword: '',
    departments: [
      {
        name: 'Backend',
        isCheck: false,
        stack: [
          {
            name: 'Python',
            isCheck: false
          },
          {
            name: 'FastApi',
            isCheck: false
          },
          {
            name: 'PostgreSQL',
            isCheck: false
          },
          {
            name: 'PHP',
            isCheck: false
          }
        ]
      },
      {
        name: 'Frontend',
        isCheck: false,
        stack: [
          {
            name: 'HTML',
            isCheck: false
          },
          {
            name: 'CSS',
            isCheck: false
          },
          {
            name: 'JavaScript',
            isCheck: false
          },
          {
            name: 'React',
            isCheck: false
          },
          {
            name: 'Vue',
            isCheck: false
          }
        ]
      },
      {
        name: 'UI/UX',
        isCheck: false,
        stack: []
      },
      {
        name: 'Тестировщик ПО',
        isCheck: false,
        stack: []
      },
      {
        name: 'Безопасность',
        isCheck: false,
        stack: []
      },
      {
        name: 'Project-менеджер',
        isCheck: false,
        stack: []
      },
      {
        name: 'Контроллёр',
        isCheck: false,
        stack: []
      },
      {
        name: 'Data Scientist',
        isCheck: false,
        stack: []
      }
    ],
    changeInput(name, value) {
      set({ [name]: value });
    },
    checkedDepartments() {
      return get().departments.filter((item) => item.isCheck);
    },
    changeDeparments(nameDepartment) {
      const departments = get().departments.map((item) => {
        if (item.name === nameDepartment) {
          const newItem = { ...item, isCheck: !item.isCheck };
          if (!newItem.isCheck) {
            newItem.stack.forEach((i) => (i.isCheck = false));
          }
          return newItem;
        }
        return item;
      });
      set({ departments });
    },
    changeStack(nameDepartment, nameStack) {
      const departments = get().departments.map((item) =>
        item.name === nameDepartment
          ? {
              ...item,
              stack: item.stack.map((i) =>
                i.name === nameStack ? { ...i, isCheck: !i.isCheck } : i
              )
            }
          : item
      );
      set({ departments });
    }
  }))
);

const useFilterStore = create<IFilterStore>()(
  devtools((set, get) => ({
    departments: [
      {
        name: 'Backend',
        isCheck: false,
        stack: [
          {
            name: 'Python',
            isCheck: false,
            departmentName: 'Backend'
          },
          {
            name: 'FastApi',
            isCheck: false,
            departmentName: 'Backend'
          },
          {
            name: 'PostgreSQL',
            isCheck: false,
            departmentName: 'Backend'
          },
          {
            name: 'PHP',
            isCheck: false,
            departmentName: 'Backend'
          }
        ]
      },
      {
        name: 'Frontend',
        isCheck: false,
        stack: [
          {
            name: 'HTML',
            isCheck: false,
            departmentName: 'Frontend'
          },
          {
            name: 'CSS',
            isCheck: false,
            departmentName: 'Frontend'
          },
          {
            name: 'JavaScript',
            isCheck: false,
            departmentName: 'Frontend'
          },
          {
            name: 'React',
            isCheck: false,
            departmentName: 'Frontend'
          },
          {
            name: 'Vue',
            isCheck: false,
            departmentName: 'Frontend'
          }
        ]
      },
      {
        name: 'UI/UX',
        isCheck: false,
        stack: []
      },
      {
        name: 'Тестировщик ПО',
        isCheck: false,
        stack: []
      },
      {
        name: 'Безопасность',
        isCheck: false,
        stack: []
      },
      {
        name: 'Project-менеджер',
        isCheck: false,
        stack: []
      },
      {
        name: 'Контроллёр',
        isCheck: false,
        stack: []
      },
      {
        name: 'Data Scientist',
        isCheck: false,
        stack: []
      }
    ],
    currentStack: [],
    date: [
      {
        name: 'За всё время',
        isCheck: false
      },
      {
        name: 'За последний день',
        isCheck: false
      },
      {
        name: 'За последние 3 дня',
        isCheck: false
      },
      {
        name: 'За последнюю неделю',
        isCheck: false
      },
      {
        name: 'За последний месяц',
        isCheck: false
      },
      {
        name: 'За последние полгода',
        isCheck: false
      }
    ],
    projectsType: [
      {
        name: 'Текущие',
        isCheck: true
      },
      {
        name: 'Выполненные',
        isCheck: false
      }
    ],
    checkedDepartments() {
      return get().departments.filter((item) => item.isCheck);
    },
    changeDeparments(nameDepartment) {
      const departments = get().departments.map((item) => {
        if (item.name === nameDepartment) {
          const newItem = { ...item, isCheck: !item.isCheck };
          if (!newItem.isCheck) {
            newItem.stack.forEach((i) => (i.isCheck = false));

            const currentStack = get().currentStack.filter(
              (stack) => stack.departmentName != nameDepartment
            );
            set({ currentStack });
          }

          if (newItem.isCheck) {
            const currentStack = get().currentStack;
            currentStack.push(...newItem.stack);
            set({ currentStack });
          }
          return newItem;
        }
        return item;
      });
      set({ departments });
    },
    changeCurrentStack(nameStack) {
      const currentStack = get().currentStack.map((item) => {
        if (item.name === nameStack) {
          const departments = get().departments.map((department) =>
            department.name === item.departmentName
              ? {
                  ...department,
                  stack: department.stack.map((i) =>
                    i.name === nameStack ? { ...i, isCheck: !i.isCheck } : i
                  )
                }
              : department
          );
          set({ departments });

          return { ...item, isCheck: !item.isCheck };
        }
        return item;
      });
      set({ currentStack });
    },
    changeDate(nameDate) {
      const date = get().date.map((item) =>
        item.name === nameDate ? { ...item, isCheck: true } : { ...item, isCheck: false }
      );
      set({ date });
    },
    changeProjectsType(nameType) {
      const projectsType = get().projectsType.map((item) =>
        item.name === nameType ? { ...item, isCheck: true } : { ...item, isCheck: false }
      );
      set({ projectsType });
    }
  }))
);

const useProjectStore = create<IProjectStore>()(
  devtools((set, get) => ({
    name: '',
    date: '',
    workInfo: '',
    participantBenefit: '',
    description: '',
    role: '',
    departments: [
      {
        id: 1,
        name: 'Backend',
        isCheck: false,
        stacks: [
          {
            id: 1,
            name: 'Python',
            isCheck: false
          },
          {
            id: 2,
            name: 'FastApi',
            isCheck: false
          },
          {
            id: 3,
            name: 'PostgreSQL',
            isCheck: false
          },
          {
            id: 4,
            name: 'PHP',
            isCheck: false
          }
        ]
      },
      {
        id: 2,
        name: 'Frontend',
        isCheck: false,
        stacks: [
          {
            id: 5,
            name: 'HTML',
            isCheck: false
          },
          {
            id: 6,
            name: 'CSS',
            isCheck: false
          },
          {
            id: 7,
            name: 'JavaScript',
            isCheck: false
          },
          {
            id: 8,
            name: 'React',
            isCheck: false
          },
          {
            id: 9,
            name: 'Vue',
            isCheck: false
          }
        ]
      },
      {
        id: 3,
        name: 'UI/UX',
        isCheck: false,
        stacks: []
      },
      {
        id: 4,
        name: 'Тестировщик ПО',
        isCheck: false,
        stacks: []
      },
      {
        id: 5,
        name: 'Project-менеджер',
        isCheck: false,
        stacks: []
      },
      {
        id: 6,
        name: 'Безопасность',
        isCheck: false,
        stacks: []
      },
      {
        id: 7,
        name: 'Контроллёр',
        isCheck: false,
        stacks: []
      },
      {
        id: 8,
        name: 'Data Scientist',
        isCheck: false,
        stacks: []
      }
    ],
    projectLink: '',
    projectLinks: [{ id: 0, url: '' }],

    changeInput(name, value) {
      set({ [name]: value });
    },
    checkedDepartments() {
      return get().departments.filter((item) => item.isCheck);
    },
    changeDepartments(nameDepartment) {
      const departments = get().departments.map((item) => {
        if (item.name === nameDepartment) {
          const newItem = { ...item, isCheck: !item.isCheck };
          return newItem;
        }
        return item;
      });
      set({ departments });
    },
    changeStack(nameDepartment, nameStack) {
      const departments = get().departments.map(
        (item): IDepartment =>
          item.name === nameDepartment
            ? {
                ...item,
                stacks: (item.stacks || []).map((i) =>
                  i.name === nameStack ? { ...i, isCheck: !i.isCheck } : i
                )
              }
            : item
      );
      set({ departments });
    },
    changeProjectLinks(i, link) {
      const projectLinks = get().projectLinks;
      const findLink = projectLinks[i];

      if (findLink) findLink.url = link;
      set({ projectLinks });
    },
    addProjectLink() {
      const projectLinks = get().projectLinks;
      set({ projectLinks: [...projectLinks, { url: '' }] });
    }
  }))
);

export {
  useRegistrationStore,
  useFilterStore,
  useProjectStore,
  useFullScreenProjectStore,
  useProjectCardStore
};
