import { IMemberRating } from '../interface/IMemberRating';
import IDepartment from '../interface/IDepartment';
import IImage from '../interface/IImage';
import IMember from '../interface/IMember';
import IPortfolioProject from '../interface/IPortfolioProject';
import IProject from '../interface/IProject';
import ISocialLink from '../interface/ISocialLink';
import IStack from '../interface/IStack';
import LinkType from '../LinkType';
import PortfolioProject from './PortfolioProject';
import Project from './Project';

/**
 * @class Member
 * @implements IMember
 */
export default class Member implements IMember {
  /**
   * @constructor Member
   * @param data Partial of Member
   */
  public constructor(data: Partial<Member>) {
    Object.assign(this, data);
  }

  /**
   * Генератор тестовых данных
   * @param n количество объектов
   * @returns Массив тестовых объектов
   * @example  const memberArray = Member.CreateTestDataArray(10);
   */
  public static CreateTestDataArray(n: number): Member[] {
    const arr: Member[] = [];
    for (let i = 0; i < n; i++) arr.push(Member.CreateTestData());
    return arr;
  }

  /**
   * Генератор тестовых данных
   * @returns Тестовый объект
   * @example  const member = Member.CreateTestData();
   */
  public static CreateTestData(): Member {
    return new Member({
      id: Math.floor(Math.random() * 1000),
      name: `Константин #${Math.floor(Math.random() * 1000)}`,
      location: 'Петропавловск-Камчатский',
      about: `Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Вдали от всех живут они в буквенных домах на берегу Семантика большого языкового океана.
  - Маленький ручеек Даль журчит по всей стране и обеспечивает ее всеми необходимыми правилами.
  - Маленький ручеек Даль журчит по всей стране и обеспечивает ее всеми необходимыми правилами.
  - Маленький ручеек Даль журчит по всей стране и обеспечивает ее всеми необходимыми правилами.`,
      nickname: 'elVasyan',
      surname: 'Иванов',
      email: 'some@mail.er',
      phone: '+79101234567',
      department: [
        {
          id: Math.floor(Math.random() * 100),
          name: 'Front',
          stacks: [
            {
              id: Math.floor(Math.random() * 100),
              name: 'JS'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'React'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'TS'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'HTML'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'CSS'
            }
          ],
          bgImgSrc: '/assets/img/frontend.webp'
        },
        {
          id: Math.floor(Math.random() * 100),
          name: 'Back',
          stacks: [
            {
              id: Math.floor(Math.random() * 100),
              name: 'Python'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'SQL'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'FastAPi'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'Django'
            }
          ]
        }
      ],
      timeInGuild: 'Где-то полдня',
      userRating: {
        id: Math.floor(Math.random() * 5),
        name: 'Начинающий авнтюрист',
        points: 120
      },
      currentRatingNum: 20,
      profilePhoto: {
        id: Math.floor(Math.random() * 1000),
        url: '/assets/img/avatar.webp'
      },
      socialLinks: [
        {
          type: LinkType.Gitlab,
          url: 'https://gitlab.com/'
        },
        {
          type: LinkType.Github,
          url: 'https://github.com/'
        },
        {
          type: LinkType.Telegram,
          url: 'https://telegram.org/'
        }
      ],
      portfolio: PortfolioProject.CreateTestDataArray(Math.floor(Math.random() * 5)),
      portfolioLink: {
        type: LinkType.Unknown,
        url: 'https://bla.com'
      },
      projects: Project.CreateTestDataArray(Math.floor(Math.random() * 5), false)
    });
  }

  id?: number = -1;

  name?: string = '';

  surname?: string = '';

  nickname?: string = '';

  email?: string = '';

  phone?: string = '';

  department?: IDepartment[] = [];

  stack?: IStack[] = [];

  timeInGuild?: string = '';

  userRating?: IMemberRating | undefined;

  currentRatingNum = 0;

  profilePhoto?: IImage | undefined;

  socialLinks?: ISocialLink[] = [];

  portfolio?: IPortfolioProject[] = [];

  projects?: IProject[] = [];

  portfolioLink?: ISocialLink | undefined;

  profileBackPhoto?: IImage | undefined;

  about?: string;

  location?: string;
}
