/**
 * @enum Тип ссылки
 */
const enum LinkType {
  /** ссылка на портфолио на стороннем сайте */
  Unknown,

  /** Телеграм */
  Telegram,

  /** ГитХаб */
  Github,

  /** ГитЛаб */
  Gitlab,

  /** Трелло */
  Trello,

  /** Беханс */
  Behance
}

export default LinkType;
