import styled from 'styled-components';

export const Section = styled.section`
  background-color: var(--color-fon);
`;

export const Wrapper = styled.div`
  flex-direction: column;
  display: flex;

  gap: 64px;
  padding: 128px 0;
  @media (min-width: 768px) {
  }
`;

export const Title = styled.h2`
  margin-bottom: 40px;

  text-align: center;
  color: var(--color-white);
  font-weight: var(--fw-700);
  font-size: var(--fs-600);
  line-height: var(--line-height-600);
`;

export const Grid = styled.ul`
  display: grid;
  justify-content: center;
  gap: 20px;

  @media (min-width: 1200px) {
    grid-template-columns: repeat(2, 588px);
  }

  @media (min-width: 1024px) and (max-width: 1200px) {
    grid-template-columns: repeat(2, minmax(462px, 588px));
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(339px, 462px));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(296px, 339px));
    gap: 24px;
  }
`;
