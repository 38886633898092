import React, { FC } from 'react';
import { ForgetPasswordForm } from '../components/ForgetPasswordForm';
import { Container } from '../components/ForgetPasswordForm/Container';

const ForgetPassword: FC = () => {
  return (
    <Container primary>
      <ForgetPasswordForm />
    </Container>
  );
};

export default ForgetPassword;
