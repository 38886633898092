/**
 * @enum Список статусов проекта
 */
enum ProjectStatus {
  /** черновик */
  Draft,
  /** в работе / незавершенный */
  InWork,
  /** завершённый */
  Done
}

export default ProjectStatus;
