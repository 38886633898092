import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

export const SubTitleWrapper = styled.div``;

export const SubTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px 0;
`;

export const SubTitleText = styled.h3`
  color: var(--color-white);
  font-weight: 600;
  font-size: 16px;
  line-heith: 22px;
`;

export const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: var(--color-white);
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: var(--gradient-2);

  @media (min-width: 768px) {
    // width: 588px;
  }
`;

export const CardImg = styled.img`
  max-width: 100%;
  height: 160px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 20px;
  background-color: var(--color-grey-3);
  @media (min-width: 1024px) {
    height: 218px;
  }
  @media (min-width: 1200px) {
    height: 278px;
  }
`;

export const CardWrapper = styled.div`
  padding: 17px 26.5px 29px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px 0;
`;

export const CardDate = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-white);
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
`;

export const CardTitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: var(--color-white);
  font-feature-settings: 'pnum' on, 'lnum' on;

  &::before {
    content: open-quote;
  }
  &::after {
    content: close-quote;
  }
  @media (min-width: 1024px) {
    font-size: 24px;
  }
`;

export const CardDescription = styled.p`
  font-size: 14px;
  font-weight: var(--fw-400);
  line-height: 17px;
  color: var(--color-white);

  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  -ms-line-clamp: 4;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  display: -webkit-box;
  display: box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  box-orient: vertical;
`;

export const CardButton = styled.button`
  width: 184px;
  padding: 9px 19px;
  text-align: center;
  background: var(--color-grey);
  border-radius: 25px;
  color: var(--color-white);
  font-weight: var(--fw-700);
  font-size: var(--fs-550);
  line-height: var(--line-height-550);

  justify-self: end;
`;

export const CardTitleSmall = styled.p`
  color: var(--color-white);
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;

export const CardRole = styled.ul``;

export const CardRoleItem = styled.li`
  display: inline-block;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: var(--color-white);

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const CardStack = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 25px;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
    gap: 32px;
  }
`;

// -------------------------

export const ImageBox = styled.div`
  position: relative;
  width: 100%;
  height: 278px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-grey-3);
  border-radius: 20px;
`;

export const CardImage = styled.img`
  width: 100%;
  height: 278px;
  border-radius: 20px;
  object-fit: cover;
`;

export const CardEdit = styled(Link)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-fon);
  border-radius: 50%;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 19px 0px;

  @media (min-width: 768px) {
    padding: 16px 24px 0;
  }
`;

export const Date = styled.span`
  font-size: var(--fs-300);
  line-height: var(--line-height-300);
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    margin-bottom: 18px;
  }
`;

export const Title = styled.h2`
  font-size: var(--fs-550);
  font-weight: var(--fw-600);
  line-height: var(--line-height-550);
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-overflow: ellipsis;
  overflow: hidden;

  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-bottom: 25px;
  }
`;

export const BlackBtn = styled.button`
  min-width: 179px;
  padding: 16px 50px;
  max-width: 281px;
  margin: 40px auto 24px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  background: var(--gradient-1);
  border-radius: 25px;
  border: none;

  font-size: var(--fs-250);
  font-weight: var(--fw-600);
  line-height: var(--line-height-350);
  color: var(--color-white);

  transition: all 0.2s ease-in-out;

  &::before {
    position: absolute;
    content: '';
    inset: 0;
    background: var(--gradient-5);
    z-index: 2;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    border-radius: 25px;
  }

  &:hover::before {
    opacity: 1;
  }

  &:active::before {
    opacity: 0;
  }

  &:active {
    background: var(--gradient-2);
  }

  svg {
    position: relative;
    z-index: 3;
    transform: rotate(180deg);
  }
`;

export const BlackBtnText = styled.span`
  position: relative;
  z-index: 3;
`;

export const NextLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-white);

  border-radius: 25px;
  width: 100%;
  gap: 25px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;

  transition: opacity 0.25s;

  position: relative;
  background: var(--gradient-1);
  z-index: 1;
  &::before {
    border-radius: 25px;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--gradient-4);
    z-index: -1;
    transition: opacity 0.5s;
    opacity: 0;
  }
  &:hover::before {
    opacity: 1;
  }
  @media (min-width: 768px) {
    padding: 13px 24px;
  }
  @media (min-width: 1200px) {
    max-width: 281px;
  }
`;
