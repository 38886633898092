import React, { FC } from 'react';
import {
  Card,
  CardImg,
  CardWrapper,
  CardDate,
  CardTitle,
  CardDescription,
  CardLink
} from './style';
import Project from '../../../api/types/class/Project';
import { ImageWrapper } from '../ProjectCards/ImageWrapper';

const ProjectClosed: FC<{ card: Project }> = ({ card }) => {
  return (
    <CardLink to={`/projects/${card.id}`}>
      <Card>
        <ImageWrapper>
          <CardImg
            src={
              card.projectImages && card.projectImages.length > 0 ? card.projectImages[0].url : ''
            }
          />
        </ImageWrapper>
        <CardWrapper>
          <CardDate>
            {card.startDate} - {card.finishedDate}
          </CardDate>
          <CardTitle>{card.title}</CardTitle>
          <CardDescription>{card.description}</CardDescription>
        </CardWrapper>
      </Card>
    </CardLink>
  );
};

export default ProjectClosed;
