import axios from 'axios';
import { instance } from '.';

const headers = {
  'X-ACCESS-TOKEN': `Ljaldjflj231lkj123lkjlFknkanwdfqpkzdjvcnmaohaLKouqojnNiasdfn32234kmn`,
  'Content-Type': 'application/json',
  Authorization:
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjIwMiwiZXhwIjoxNjkxMDg0NjQ1LCJpYXQiOjE2OTEwODI4NDV9.LeOpFjSTa4oLusfjdkyKN_PbVCT1Bus5L02buVy8xOE'
};

///**регистрация по почте */
export const requestRegistrationByMail = (
  telegram_id: string,
  last_name: string,
  tg_login: string,
  town: string,
  email: string,
  password: string,
  nickname: string,
  create_at: string,
  about_me: string,
  stacks: string,
  departments: string,
  links: string
) =>
  instance.post('users/registration', {
    telegram_id: telegram_id,
    last_name: last_name,
    tg_login: tg_login,
    town: town,
    email: email,
    password: password,
    nickname: nickname,
    create_at: create_at,
    about_me: about_me,
    stacks: stacks,
    departments: departments,
    links: links
  });

///Логин по почте
export const requestLoginByMail = (email: unknown, password: unknown) =>
  instance.post('auth/login', {
    email: `${email}`,
    password: `${password}`
  });

///Профиль пользователя
export const changeUserInfo = (
  id: number,
  last_name: string,
  first_name: string,
  tg_login: string,
  town: string,
  email: string,
  nickname: string
) => {
  axios.put(
    'http://135.181.198.180:54641/users',
    {
      id: 201,
      last_name: '123asdasdsadsad1dsadsadacsdadsad',
      first_name: 'sdasasdsadasdsdaddtrasdasing',
      tg_login: 'strasasdaasdsddasddsading',
      town: '111111111111sadsadasd11sadsad11111111111111',
      email: '5555555555555555555555555',
      nickname: 'strasdsdsdsdadssdsdadsdsasing'
    },
    { headers }
  );
};

export const getUserById = (userID: number) => {
  axios.get(`http://135.181.198.180:54641/users/${userID}`, { headers });
};
