import styled from 'styled-components';

export const Section = styled.section`
  @media (max-width: 560px) {
    padding: 9px 16px 71px 16px;
  }
  & :hover {
    cursor: pointer;
  }
`;

export const Wrapper = styled.a`
  display: flex;
  justify-content: space-around;
  height: 160px;
  width: 100%;
  margin 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 15px;
  @media (max-width: 560px) {
    height: 140px;
    max-width: 288px;
  }
`;

export const IconWrapper = styled.div`
  padding-top: 62px;
  padding-right: 119px;
  @media (max-width: 560px) {
    display: none;
  }
`;

export const Title = styled.h2`
  display: block;
  padding-top: 69px;
  padding-left: 75px;
  padding-right: 15px;
  font-size: var(--fs-600);
  font-weight: var(--fw-700);
  line-height: var(--line-height-600);
  color: var(--color-white);
  @media (max-width: 560px) {
    padding-top: 32px;
    padding-left: 29px;
    padding-right: 32px;
    text-align: center;
    font-size: var(--fs-570);
    line-height: var(--line-height-550);
  }
`;
