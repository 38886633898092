import { FC, useState, useCallback } from 'react';
import ProjectClass from '../../../api/types/class/Project';
import { ArrowDown, ArrowUp } from './Icon';
import {
  CardButton,
  Card,
  CardTitle,
  CardDate,
  CardDepartments,
  CardDepartment,
  Stack,
  StackItem
} from './style';

export interface ProjectProps {
  project: ProjectClass;
}

const Project: FC<ProjectProps> = ({ project }) => {
  const [toggle, setToggle] = useState('');

  const handleSetToggle = useCallback(
    (name: string) => (toggle === name ? setToggle('') : setToggle(name)),
    [setToggle, toggle]
  );

  return (
    <Card
      onClick={() => {
        setToggle('');
      }}
    >
      <CardDate>{project.startDate}</CardDate>
      <CardTitle>«{project.title}»</CardTitle>
      <CardDepartments>
        {project.departments?.map((department) => (
          <>
            <CardDepartment
              key={department.name}
              onClick={(e) => {
                e.stopPropagation();
                handleSetToggle(department.name);
              }}
            >
              <p>
                {department.name}
                {toggle === department.name ? <ArrowUp /> : <ArrowDown />}
              </p>
            </CardDepartment>
            <Stack $toggle={toggle} $depName={department.name}>
              {toggle === department.name
                ? department.stacks?.map((stack) => (
                    <StackItem key={stack.id}>{stack.name}</StackItem>
                  ))
                : ''}
            </Stack>
          </>
        ))}
      </CardDepartments>
      <CardButton to={`/projects/${project.id}`}>Записаться</CardButton>
    </Card>
  );
};

export default Project;
