import styled from 'styled-components';

interface ChipProps {
  gap?: string;
  width?: string;
  border?: string;
  borderRadius?: string;
  padding?: string;
  mt?: string;
  ml?: string;
}

export const Chip = styled.span<ChipProps>`
  display: inline-block;
  width: ${(props) => props.width};
  gap: ${(props) => props.gap};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  padding: ${(props) => props.padding};
  margin-top: ${(props) => props.mt};
  margin-left ${(props) => props.ml};
`;
