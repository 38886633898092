import { createReducer } from '@reduxjs/toolkit';
import { PROFILE_EDIT } from '../actions/profile-edit';

const initialState = {
  id: '',
  last_name: '',
  first_name: '',
  tg_login: '',
  town: '',
  email: '',
  nickname: '',
  status: '',
  errMessage: ''
};

export const profileEditReduser = createReducer(initialState, (builder) => {
  builder
    .addCase(PROFILE_EDIT.pending, (state, action) => {
      state.id = '';
      state.last_name = '';
      state.first_name = '';
      state.tg_login = '';
      state.town = '';
      state.email = '';
      state.nickname = '';
      state.status = action.type;
      state.errMessage = '';
    })
    .addCase(PROFILE_EDIT.fulfilled, (state, action) => {
      console.log(action.payload.data);
      state.id = action.payload.data.id;
      state.last_name = action.payload.data.last_name;
      state.first_name = action.payload.data.first_name;
      state.tg_login = action.payload.data.tg_login;
      state.town = action.payload.data.town;
      state.email = action.payload.data.email;
      state.nickname = action.payload.data.nickname;
      state.status = action.type.status;
      state.errMessage = '';
    })
    .addCase(PROFILE_EDIT.rejected, (state, action) => {
      console.log(action);
      state.id = '';
      state.last_name = '';
      state.first_name = '';
      state.tg_login = '';
      state.town = '';
      state.email = '';
      state.nickname = '';
      state.errMessage = action.error.message;
    });
});

export default profileEditReduser;
