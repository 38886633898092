import { Container } from '../../../lib/styled/container';
import { ArrowLeftBlue1 } from '../../UI/Icons/ArrowLeftBlue1';
import { GoBack, Title, Wrapper } from '../ProjectFullScreen/style';
import { ProjectCard } from './ProjectCard';
import { ProjectSmallCard } from './ProjectSmallCard';
import { Section } from './Section';
import { SubTitle } from './style';

export default function ProjectDoneItem() {
  return (
    <div>
      <Section primary>
        <Container>
          <Wrapper>
            <GoBack to="/currentProjects">
              <ArrowLeftBlue1 />
            </GoBack>
            <Title>«Инкубатор Фондов»</Title>
            <ProjectCard />
            <SubTitle>Другие проекты</SubTitle>
            <ProjectSmallCard />
          </Wrapper>
        </Container>
      </Section>
    </div>
  );
}
