import { combineReducers } from 'redux';
import { usersReduser } from './users';
import { registerReduser } from './Register';
import { profileEditReduser } from './profile-edit';
import Projects from './projectsDone/projects';

const rootReducer = combineReducers({
  usersReduser,
  registerReduser,
  profileEditReduser,
  Projects
});

export default rootReducer;
