import { FC } from 'react';

interface ISvgProps {
  color?: string;
}

export const StarIcon: FC<ISvgProps> = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.55 21.3368L19.8495 25.3352C20.6619 25.8475 21.6619 25.0853 21.4244 24.1482L19.5995 16.9761C19.5502 16.7773 19.558 16.5686 19.6221 16.3741C19.6863 16.1796 19.8041 16.0071 19.962 15.8766L25.6116 11.166C26.349 10.5537 25.974 9.31675 25.0116 9.25427L17.6372 8.77947C17.4359 8.76776 17.2425 8.69772 17.0805 8.5779C16.9184 8.45807 16.7948 8.29366 16.7247 8.10474L13.9749 1.18257C13.9021 0.982525 13.7695 0.809721 13.5951 0.687611C13.4207 0.565501 13.2129 0.5 13 0.5C12.7871 0.5 12.5793 0.565501 12.4049 0.687611C12.2305 0.809721 12.0979 0.982525 12.0251 1.18257L9.27528 8.10474C9.2052 8.29366 9.08158 8.45807 8.91954 8.5779C8.75751 8.69772 8.56406 8.76776 8.36285 8.77947L0.988397 9.25427C0.0259692 9.31675 -0.349003 10.5537 0.388442 11.166L6.03802 15.8766C6.19593 16.0071 6.31373 16.1796 6.37786 16.3741C6.44199 16.5686 6.44984 16.7773 6.40049 16.9761L4.71312 23.6234C4.42564 24.748 5.62555 25.6601 6.58798 25.0478L12.45 21.3368C12.6144 21.2324 12.8052 21.1769 13 21.1769C13.1948 21.1769 13.3856 21.2324 13.55 21.3368Z"
        fill="black"
      />
    </svg>
  );
};

export const MailIcon: FC<ISvgProps> = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.75 1.35645L14 13.0439L1.25 1.35645"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.25 1.35645H26.75V19.4189C26.75 19.7007 26.6381 19.971 26.4388 20.1702C26.2395 20.3695 25.9693 20.4814 25.6875 20.4814H2.3125C2.03071 20.4814 1.76046 20.3695 1.5612 20.1702C1.36194 19.971 1.25 19.7007 1.25 19.4189V1.35645Z"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6758 10.9189L1.58203 20.176"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.418 20.176L16.3242 10.9189"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const MapPinIcon: FC<ISvgProps> = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 15.9814C14.3472 15.9814 16.25 14.0787 16.25 11.7314C16.25 9.38424 14.3472 7.48145 12 7.48145C9.65279 7.48145 7.75 9.38424 7.75 11.7314C7.75 14.0787 9.65279 15.9814 12 15.9814Z"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.625 11.7314C22.625 21.2939 12 28.7314 12 28.7314C12 28.7314 1.375 21.2939 1.375 11.7314C1.375 8.91352 2.49442 6.21101 4.48699 4.21844C6.47956 2.22586 9.18207 1.10645 12 1.10645C14.8179 1.10645 17.5204 2.22586 19.513 4.21844C21.5056 6.21101 22.625 8.91352 22.625 11.7314V11.7314Z"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const GithubIcon: FC<ISvgProps> = () => {
  return (
    <svg width="21" height="26" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.15625 27.7939C7.57533 27.7957 7.99061 27.7145 8.37813 27.5549C8.76565 27.3953 9.11773 27.1606 9.41407 26.8643C9.71041 26.5679 9.94513 26.2158 10.1047 25.8283C10.2643 25.4408 10.3455 25.0255 10.3437 24.6064V18.2314"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.8438 27.7939C18.4247 27.7957 18.0094 27.7145 17.6219 27.5549C17.2344 27.3953 16.8823 27.1606 16.586 26.8643C16.2896 26.5679 16.0549 26.2158 15.8953 25.8283C15.7358 25.4408 15.6545 25.0255 15.6563 24.6064V18.2314"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.1875 18.2315H18.3125C18.7316 18.2297 19.1469 18.311 19.5344 18.4705C19.9219 18.6301 20.274 18.8648 20.5703 19.1612C20.8667 19.4575 21.1014 19.8096 21.2609 20.1971C21.4205 20.5846 21.5018 20.9999 21.5 21.419V22.4815C21.4982 22.9006 21.5795 23.3158 21.7391 23.7034C21.8986 24.0909 22.1333 24.443 22.4297 24.7393C22.726 25.0356 23.0781 25.2704 23.4656 25.4299C23.8531 25.5895 24.2684 25.6707 24.6875 25.669"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.8125 18.2315H7.6875C7.26842 18.2297 6.85314 18.311 6.46562 18.4705C6.0781 18.6301 5.72602 18.8648 5.42968 19.1612C5.13334 19.4575 4.89862 19.8096 4.73905 20.1971C4.57949 20.5846 4.49824 20.9999 4.5 21.419V22.4815C4.50176 22.9006 4.42051 23.3158 4.26095 23.7034C4.10138 24.0909 3.86666 24.443 3.57032 24.7393C3.27398 25.0356 2.9219 25.2704 2.53438 25.4299C2.14686 25.5895 1.73158 25.6707 1.3125 25.669"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8484 4.41895C10.2242 3.44212 9.36419 2.63813 8.34758 2.08108C7.33096 1.52403 6.19048 1.23184 5.03125 1.23145C4.5158 2.12365 4.20653 3.11988 4.1261 4.14713C4.04567 5.17438 4.19611 6.20661 4.56641 7.16816C3.8402 8.2387 3.44737 9.50037 3.4375 10.7939V11.8564C3.4375 13.5472 4.10915 15.1687 5.30469 16.3643C6.50024 17.5598 8.12174 18.2314 9.8125 18.2314H16.1875C17.8783 18.2314 19.4998 17.5598 20.6953 16.3643C21.8909 15.1687 22.5625 13.5472 22.5625 11.8564V10.7939C22.5526 9.50037 22.1598 8.2387 21.4336 7.16816C21.8039 6.20661 21.9543 5.17438 21.8739 4.14713C21.7935 3.11988 21.4842 2.12365 20.9688 1.23145C19.8095 1.23184 18.669 1.52403 17.6524 2.08108C16.6358 2.63813 15.7758 3.44212 15.1516 4.41895H10.8484Z"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const FlagIcon: FC<ISvgProps> = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.75 18.0127V1.60645H19L14.5 7.46582L19 13.3252H1.75"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const PhoneIcon: FC<ISvgProps> = () => {
  return (
    <svg width="18" height="14" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.28516 13.9815C9.3785 16.3057 11.2042 18.1803 13.4648 19.2998C13.6316 19.3811 13.816 19.4163 14 19.4019C14.184 19.3875 14.3611 19.3241 14.5141 19.2178L17.8344 16.9346C17.9811 16.8322 18.1506 16.7697 18.3271 16.7529C18.5036 16.7362 18.6813 16.7658 18.8438 16.8389L25.0594 19.5869C25.2718 19.6779 25.4493 19.8383 25.5645 20.0434C25.6797 20.2484 25.7262 20.4868 25.6969 20.7217C25.4999 22.3045 24.7493 23.7593 23.5857 24.8136C22.4221 25.868 20.9252 26.4498 19.375 26.4502C14.5845 26.4502 9.99026 24.4912 6.60288 21.0042C3.21551 17.5172 1.3125 12.7878 1.3125 7.85646C1.31285 6.26067 1.87803 4.7197 2.9023 3.52187C3.92656 2.32404 5.33972 1.55144 6.87734 1.34865C7.10557 1.31847 7.3371 1.36636 7.53629 1.48494C7.73547 1.60352 7.89127 1.78622 7.97969 2.0049L10.6492 8.41701C10.7186 8.58154 10.7477 8.76113 10.7338 8.93995C10.7199 9.11876 10.6635 9.29131 10.5695 9.4424L8.35156 12.9151C8.25282 13.0721 8.19486 13.2526 8.18324 13.4393C8.17161 13.626 8.2067 13.8127 8.28516 13.9815V13.9815Z"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const TelegramIcon: FC<ISvgProps> = () => {
  return (
    <svg width="21" height="19" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.68752 14.8345L21.6274 25.34C21.7653 25.4622 21.9327 25.5463 22.1131 25.5842C22.2934 25.6221 22.4805 25.6125 22.656 25.5562C22.8315 25.5 22.9894 25.399 23.114 25.2633C23.2387 25.1276 23.326 24.9618 23.3672 24.7822L28.361 2.97438C28.4028 2.78657 28.3929 2.59091 28.3323 2.4083C28.2716 2.22569 28.1625 2.06297 28.0166 1.93752C27.8707 1.81207 27.6935 1.72859 27.5038 1.69601C27.3142 1.66342 27.1193 1.68295 26.9399 1.7525L2.42267 11.3814C1.43986 11.7666 1.57267 13.2009 2.60861 13.4134L9.68752 14.8345Z"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.6875 14.8357L27.7633 1.78027"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.6508 20.0811L11.507 24.2248C11.3592 24.3749 11.1701 24.4777 10.9637 24.5201C10.7573 24.5624 10.543 24.5425 10.348 24.4628C10.153 24.3831 9.98604 24.2472 9.86844 24.0724C9.75083 23.8976 9.68785 23.6917 9.6875 23.4811V14.835"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const GitlabIcon: FC<ISvgProps> = () => {
  return (
    <svg width="21" height="19" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.8335 7.19927L16.1776 0.980175C16.107 0.710643 15.9503 0.470358 15.7304 0.294513C15.5105 0.118668 15.2389 0.0165017 14.9555 0.0029788C14.6721 -0.0105441 14.3917 0.0652864 14.1555 0.219364C13.9192 0.373442 13.7394 0.597671 13.6425 0.85921L12.2499 4.57644H5.74957L4.35699 0.856758C4.25983 0.595265 4.07984 0.37116 3.84339 0.217272C3.60693 0.0633853 3.32645 -0.0121954 3.04302 0.00160272C2.75959 0.0154008 2.48812 0.117853 2.26837 0.293949C2.04861 0.470045 1.89214 0.710531 1.82186 0.980175L0.165938 7.19927C-0.0951918 8.17849 -0.0472292 9.21238 0.303488 10.1643C0.654205 11.1162 1.29117 11.9414 2.13021 12.5307L8.23636 16.7621C8.46024 16.9169 8.72747 17 9.0014 17C9.27534 17 9.54257 16.9169 9.76644 16.7621L15.8726 12.5307C16.711 11.941 17.3473 11.1156 17.6975 10.1637C18.0476 9.21183 18.095 8.17816 17.8335 7.19927ZM3.21693 3.50247L4.11365 5.89562C4.1843 6.08413 4.31225 6.24683 4.48022 6.36172C4.64819 6.47661 4.84808 6.53816 5.05286 6.53804H12.9466C13.1514 6.53816 13.3513 6.47661 13.5192 6.36172C13.6872 6.24683 13.8152 6.08413 13.8858 5.89562L14.7825 3.50247L15.5442 6.36313L8.99974 10.8952L2.45523 6.36068L3.21693 3.50247ZM1.99937 8.44815L7.26631 12.0959L6.11708 12.892L3.2836 10.9304C2.88178 10.6484 2.55523 10.2756 2.33166 9.84345C2.10809 9.41133 1.99411 8.93269 1.99937 8.44815ZM8.99974 14.8904L7.85051 14.0935L8.99974 13.2974L10.149 14.0935L8.99974 14.8904ZM14.7167 10.9288L11.8832 12.8904L10.7332 12.0959L16.0001 8.44815C16.0052 8.93235 15.8912 9.41061 15.6678 9.84242C15.4444 10.2742 15.1181 10.6469 14.7167 10.9288Z"
        fill="#26C0F1"
      />
    </svg>
  );
};
