import React, { FC, useState, useEffect } from 'react';
import HeroAbout from '../components/HeroAbout';
import HeroAboutReversed from '../components/HeroAboutReversed';
import TeamFiguresMany from '../components/TeamFiguresMany';
import { TelegramIcon, ArrowIcon } from '../components/BannerBlock/icon';
import BannerBlock from '../components/BannerBlock';
import ContactUsForm from '../components/ContactUsForm';
import { getAboutUsMembers } from '../api/AboutUsMembers';
import UWrapper from '../components/Accordion/UniversalWrapper/';

const AboutGuild: FC = () => {
  const [visibale, setVisibale] = useState(false);
  const handleClick = () => setVisibale((prev) => !prev);
  const [members, setMembers] = useState([]);
  useEffect(() => {
    getAboutUsMembers().then((data) => setMembers(data));
  }, []);

  return (
    <>
      <UWrapper>
        <HeroAbout
          titleSize="--fs-800"
          title="Кто мы"
          descr="Guild of Developers (GoD) - IT сообщество, созданное для помощи благотворительным фондам и людям. Всем желающим здесь помогают повышать навыки и делать добрые дела.Мы собрали самых любознательных, целеустремленных и приветливых людей. Объединяем новичков и специалистов всех возрастов из разных городов и стран. К нам приходят люди, которые хотят выйти на новый уровень дохода, сменить профессию, получить навык работы в команде и научиться новому."
          descrp1="Guild of Developers (GoD) - IT сообщество, созданное для помощи благотворительным фондам и людям. Всем желающим здесь помогают повышать навыки и делать добрые дела."
          descrp2="Мы собрали самых любознательных, целеустремленных и приветливых людей. Объединяем новичков и специалистов всех возрастов из разных городов и стран. К нам приходят люди, которые хотят выйти на новый уровень дохода, сменить профессию, получить навык работы в команде и научиться новому."
          image={{
            imageAlt: 'who_are_we',
            imageSrc: '/assets/img/who_we_are.svg',
            imageSrcSet:
              '/assets/img/who_we_are_320.svg 320w,  /assets/img/WhoWeAre1024.svg 1024w, /assets/img/who_we_are.svg 1920w',
            imageSizes:
              '(max-width: 560px) 320px, (max-width: 1024px) 1920px, (max-width: 561px) 1920px'
          }}
        />

        <HeroAboutReversed
          title="Наша цель"
          descrp1="Мир несправедлив, и не у всех представителей фондов помощи и благотворительности есть финансы для разработки своего рабочего сайта или мобильного приложения."
          descrp2="Мы верим в доброту и людей, желающих менять себя и мир вокруг. Поэтому GoD даёт уникальную возможность получить знания в IT совершенно бесплатно и помочь людям."
          descrp3="Вы сможете собрать своё рабочее портфолио с реальными проектами, поработать в команде единомышленников и помочь добрым делом нуждающимся."
          descr="Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Вдали от всех живут они в буквенных домах на берегу Семантика большого языкового океана. TEST TEST TEST TEST"
          image={{
            imageAlt: 'purpose',
            imageSrc: '/assets/img/OurTarged1920',
            imageSrcSet:
              '/assets/img/OurTarget320.svg 320w, /assets/img/WhoWeAre1024.svg 1024w 1024w, /assets/img/OurTarged1920.svg 1920w',
            imageSizes: '(max-width: 560px) 320px, (max-width: 561px) 1920px'
          }}
        />

        <BannerBlock
          title="Если у вас есть вопросы свяжитесь с нами"
          icon={<ArrowIcon />}
          bgImageUrl="/assets/img/banner_contact_us.svg"
          action={handleClick}
        />
        <TeamFiguresMany data={members} />
        <BannerBlock
          title="Давайте пообщаемся в&nbsp;Телеграмм"
          href="https://t.me/School_Administrator_bot"
          icon={<TelegramIcon />}
          bgImageUrl="/assets/img/banner_telegram.svg"
        />
        <ContactUsForm visibale={visibale} setVisibale={setVisibale} />
      </UWrapper>
    </>
  );
};

export default AboutGuild;
