import React, { FC, useEffect } from 'react';
import ReactPaginate from 'react-paginate';

import { Grid, Section } from './style';
import { ArrowPagination } from './icon';

import Project from './Project';
import { useMediaQuery } from '../../../services/useMediaQuery';

import { getProjectsDone } from '../../../api/Projects';
import Preloader from '../../../layout/Main/Preloader';
import { useQuery } from '@tanstack/react-query';
import { Title } from '../style';
import { ProjectsState } from '../../../services/reducers/projectsDone/projects';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataProjects } from '../../../services/reducers/projectsDone/projects';

interface PaginatedProps {
  itemsPerPage: number;
  data: [];
}

interface FetchItems {
  Projects: ProjectsState;
}

interface ItemsArr {
  items: Array<ItemsProps>;
}

export interface ItemsProps {
  create_at: string;
  done: boolean;
  id: number;
  images: string;
  short_description: string;
  title: string;
  update_at: string;
}

const Items: FC<ItemsArr> = ({ items }) => {
  return (
    <Grid>
      {items?.map((item: ItemsProps) => (
        <Project key={item.id} project={item} />
      ))}
    </Grid>
  );
};

const PaginatedItems: FC<PaginatedProps> = ({ itemsPerPage, data }) => {
  const isMobile = useMediaQuery('(max-width: 650px)');

  const [currentItems, setCurrentItems] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [itemOffset, setItemOffset] = React.useState(0);

  React.useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items items={currentItems} />
      <ReactPaginate
        breakLabel="..."
        nextLabel={<ArrowPagination />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={isMobile ? 2 : 4}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        previousLabel={<ArrowPagination />}
        containerClassName={data.length < itemsPerPage ? 'pagination hidden' : 'pagination'}
        pageLinkClassName="page-num"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        activeLinkClassName="active"
      />
    </>
  );
};

const ProjectsDone: FC = () => {
  const { isError } = useQuery(['getProjectsDone'], getProjectsDone);
  const dispatch = useDispatch<any>();
  const items = useSelector((state: FetchItems) => state.Projects.items);
  const loaded = useSelector((state: FetchItems) => state.Projects.loaded);
  const total = useSelector((state: FetchItems) => state.Projects.total);
  const page = useSelector((state: FetchItems) => state.Projects.page);

  useEffect(() => {
    dispatch(fetchDataProjects({ limit: 100, page: 1 }));
  }, []);

  if (!loaded) {
    return (
      <Section>
        <Preloader />
      </Section>
    );
  }

  if (isError) {
    return (
      <Section>
        <Title>Ошибка</Title>;
      </Section>
    );
  }

  return (
    <Section>
      <PaginatedItems itemsPerPage={6} data={items} />
    </Section>
  );
};

export default ProjectsDone;
