export const IconRating = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1497 39.223C15.5385 38.6117 15.5385 37.6207 16.1497 37.0094L36.4976 16.6616C37.1088 16.0504 38.0999 16.0504 38.7111 16.6616C39.3224 17.2729 39.3224 18.2639 38.7111 18.8752L18.3633 39.223C17.752 39.8342 16.761 39.8342 16.1497 39.223Z"
        fill="url(#paint0_linear_3038_22595)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.66342 30.015C7.05216 29.4037 7.05216 28.4127 7.66342 27.8014L28.0112 7.4536C28.6225 6.84234 29.6135 6.84234 30.2248 7.4536C30.8361 8.06485 30.8361 9.0559 30.2248 9.66715L9.87697 30.015C9.26571 30.6262 8.27467 30.6262 7.66342 30.015Z"
        fill="url(#paint1_linear_3038_22595)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.056 11.1979C13.3616 10.8923 13.8571 10.8923 14.1628 11.1979L36.0758 33.111C36.3814 33.4166 36.3814 33.9121 36.0758 34.2177C35.7702 34.5234 35.2747 34.5234 34.969 34.2177L13.056 12.3047C12.7504 11.9991 12.7504 11.5035 13.056 11.1979Z"
        fill="url(#paint2_linear_3038_22595)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 9.13043C15.7878 9.13043 9.13043 15.7878 9.13043 24C9.13043 32.2122 15.7878 38.8696 24 38.8696C32.2122 38.8696 38.8696 32.2122 38.8696 24C38.8696 15.7878 32.2122 9.13043 24 9.13043ZM6 24C6 14.0589 14.0589 6 24 6C33.9411 6 42 14.0589 42 24C42 33.9411 33.9411 42 24 42C14.0589 42 6 33.9411 6 24Z"
        fill="url(#paint3_linear_3038_22595)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3038_22595"
          x1="18.453"
          y1="33.2475"
          x2="30.9985"
          y2="37.4198"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#26C0F1" />
          <stop offset="0.990321" stop-color="#6F35CE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3038_22595"
          x1="9.96668"
          y1="24.0395"
          x2="22.5122"
          y2="28.2118"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#26C0F1" />
          <stop offset="0.990321" stop-color="#6F35CE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3038_22595"
          x1="18.1975"
          y1="14.5033"
          x2="14.4769"
          y2="20.6444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#26C0F1" />
          <stop offset="0.990321" stop-color="#6F35CE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3038_22595"
          x1="12.605"
          y1="-5.86126"
          x2="44.6841"
          y2="0.422639"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#26C0F1" />
          <stop offset="0.990321" stop-color="#6F35CE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
