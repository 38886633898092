export const PhotoBlanc = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.7781 0.666992H4.22255C3.27956 0.666992 2.37519 1.04159 1.70839 1.70839C1.04159 2.37519 0.666992 3.27956 0.666992 4.22255V39.7781C0.666992 40.7211 1.04159 41.6255 1.70839 42.2923C2.37519 42.9591 3.27956 43.3337 4.22255 43.3337H39.7781C40.7211 43.3337 41.6255 42.9591 42.2923 42.2923C42.9591 41.6255 43.3337 40.7211 43.3337 39.7781V4.22255C43.3337 3.27956 42.9591 2.37519 42.2923 1.70839C41.6255 1.04159 40.7211 0.666992 39.7781 0.666992ZM4.22255 4.22255H39.7781V21.4181L34.2914 15.9292C33.6247 15.2629 32.7207 14.8886 31.7781 14.8886C30.8355 14.8886 29.9315 15.2629 29.2648 15.9292L5.41588 39.7781H4.22255V4.22255ZM11.3337 14.8892C11.3337 14.186 11.5422 13.4986 11.9329 12.9139C12.3236 12.3291 12.8789 11.8734 13.5286 11.6043C14.1783 11.3352 14.8932 11.2648 15.5829 11.402C16.2726 11.5392 16.9061 11.8778 17.4034 12.3751C17.9006 12.8723 18.2393 13.5058 18.3765 14.1956C18.5136 14.8853 18.4432 15.6002 18.1741 16.2499C17.905 16.8996 17.4493 17.4549 16.8646 17.8455C16.2799 18.2362 15.5924 18.4448 14.8892 18.4448C13.9462 18.4448 13.0419 18.0702 12.3751 17.4034C11.7083 16.7366 11.3337 15.8322 11.3337 14.8892Z"
        fill="#26C0F1"
      />
    </svg>
  );
};

export const CoverBlanc = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.7781 0.666992H4.22255C3.27956 0.666992 2.37519 1.04159 1.70839 1.70839C1.04159 2.37519 0.666992 3.27956 0.666992 4.22255V39.7781C0.666992 40.7211 1.04159 41.6255 1.70839 42.2923C2.37519 42.9591 3.27956 43.3337 4.22255 43.3337H39.7781C40.7211 43.3337 41.6255 42.9591 42.2923 42.2923C42.9591 41.6255 43.3337 40.7211 43.3337 39.7781V4.22255C43.3337 3.27956 42.9591 2.37519 42.2923 1.70839C41.6255 1.04159 40.7211 0.666992 39.7781 0.666992ZM4.22255 4.22255H39.7781V21.4181L34.2914 15.9292C33.6247 15.2629 32.7207 14.8886 31.7781 14.8886C30.8355 14.8886 29.9315 15.2629 29.2648 15.9292L5.41588 39.7781H4.22255V4.22255ZM11.3337 14.8892C11.3337 14.186 11.5422 13.4986 11.9329 12.9139C12.3236 12.3291 12.8789 11.8734 13.5286 11.6043C14.1783 11.3352 14.8932 11.2648 15.5829 11.402C16.2726 11.5392 16.9061 11.8778 17.4034 12.3751C17.9006 12.8723 18.2393 13.5058 18.3765 14.1956C18.5136 14.8853 18.4432 15.6002 18.1741 16.2499C17.905 16.8996 17.4493 17.4549 16.8646 17.8455C16.2799 18.2362 15.5924 18.4448 14.8892 18.4448C13.9462 18.4448 13.0419 18.0702 12.3751 17.4034C11.7083 16.7366 11.3337 15.8322 11.3337 14.8892Z"
        fill="#fff"
      />
    </svg>
  );
};

export const CoverBlancMin = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.7781 0.666992H4.22255C3.27956 0.666992 2.37519 1.04159 1.70839 1.70839C1.04159 2.37519 0.666992 3.27956 0.666992 4.22255V39.7781C0.666992 40.7211 1.04159 41.6255 1.70839 42.2923C2.37519 42.9591 3.27956 43.3337 4.22255 43.3337H39.7781C40.7211 43.3337 41.6255 42.9591 42.2923 42.2923C42.9591 41.6255 43.3337 40.7211 43.3337 39.7781V4.22255C43.3337 3.27956 42.9591 2.37519 42.2923 1.70839C41.6255 1.04159 40.7211 0.666992 39.7781 0.666992ZM4.22255 4.22255H39.7781V21.4181L34.2914 15.9292C33.6247 15.2629 32.7207 14.8886 31.7781 14.8886C30.8355 14.8886 29.9315 15.2629 29.2648 15.9292L5.41588 39.7781H4.22255V4.22255ZM11.3337 14.8892C11.3337 14.186 11.5422 13.4986 11.9329 12.9139C12.3236 12.3291 12.8789 11.8734 13.5286 11.6043C14.1783 11.3352 14.8932 11.2648 15.5829 11.402C16.2726 11.5392 16.9061 11.8778 17.4034 12.3751C17.9006 12.8723 18.2393 13.5058 18.3765 14.1956C18.5136 14.8853 18.4432 15.6002 18.1741 16.2499C17.905 16.8996 17.4493 17.4549 16.8646 17.8455C16.2799 18.2362 15.5924 18.4448 14.8892 18.4448C13.9462 18.4448 13.0419 18.0702 12.3751 17.4034C11.7083 16.7366 11.3337 15.8322 11.3337 14.8892Z"
        fill="#fff"
      />
    </svg>
  );
};

export const PhotoBlancMin = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.7781 0.666992H4.22255C3.27956 0.666992 2.37519 1.04159 1.70839 1.70839C1.04159 2.37519 0.666992 3.27956 0.666992 4.22255V39.7781C0.666992 40.7211 1.04159 41.6255 1.70839 42.2923C2.37519 42.9591 3.27956 43.3337 4.22255 43.3337H39.7781C40.7211 43.3337 41.6255 42.9591 42.2923 42.2923C42.9591 41.6255 43.3337 40.7211 43.3337 39.7781V4.22255C43.3337 3.27956 42.9591 2.37519 42.2923 1.70839C41.6255 1.04159 40.7211 0.666992 39.7781 0.666992ZM4.22255 4.22255H39.7781V21.4181L34.2914 15.9292C33.6247 15.2629 32.7207 14.8886 31.7781 14.8886C30.8355 14.8886 29.9315 15.2629 29.2648 15.9292L5.41588 39.7781H4.22255V4.22255ZM11.3337 14.8892C11.3337 14.186 11.5422 13.4986 11.9329 12.9139C12.3236 12.3291 12.8789 11.8734 13.5286 11.6043C14.1783 11.3352 14.8932 11.2648 15.5829 11.402C16.2726 11.5392 16.9061 11.8778 17.4034 12.3751C17.9006 12.8723 18.2393 13.5058 18.3765 14.1956C18.5136 14.8853 18.4432 15.6002 18.1741 16.2499C17.905 16.8996 17.4493 17.4549 16.8646 17.8455C16.2799 18.2362 15.5924 18.4448 14.8892 18.4448C13.9462 18.4448 13.0419 18.0702 12.3751 17.4034C11.7083 16.7366 11.3337 15.8322 11.3337 14.8892Z"
        fill="#26C0F1"
      />
    </svg>
  );
};

export const PhotoBlancMob = () => {
  return (
    <svg width="11" height="11" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.7781 0.666992H4.22255C3.27956 0.666992 2.37519 1.04159 1.70839 1.70839C1.04159 2.37519 0.666992 3.27956 0.666992 4.22255V39.7781C0.666992 40.7211 1.04159 41.6255 1.70839 42.2923C2.37519 42.9591 3.27956 43.3337 4.22255 43.3337H39.7781C40.7211 43.3337 41.6255 42.9591 42.2923 42.2923C42.9591 41.6255 43.3337 40.7211 43.3337 39.7781V4.22255C43.3337 3.27956 42.9591 2.37519 42.2923 1.70839C41.6255 1.04159 40.7211 0.666992 39.7781 0.666992ZM4.22255 4.22255H39.7781V21.4181L34.2914 15.9292C33.6247 15.2629 32.7207 14.8886 31.7781 14.8886C30.8355 14.8886 29.9315 15.2629 29.2648 15.9292L5.41588 39.7781H4.22255V4.22255ZM11.3337 14.8892C11.3337 14.186 11.5422 13.4986 11.9329 12.9139C12.3236 12.3291 12.8789 11.8734 13.5286 11.6043C14.1783 11.3352 14.8932 11.2648 15.5829 11.402C16.2726 11.5392 16.9061 11.8778 17.4034 12.3751C17.9006 12.8723 18.2393 13.5058 18.3765 14.1956C18.5136 14.8853 18.4432 15.6002 18.1741 16.2499C17.905 16.8996 17.4493 17.4549 16.8646 17.8455C16.2799 18.2362 15.5924 18.4448 14.8892 18.4448C13.9462 18.4448 13.0419 18.0702 12.3751 17.4034C11.7083 16.7366 11.3337 15.8322 11.3337 14.8892Z"
        fill="#26C0F1"
      />
    </svg>
  );
};

export const Arrow = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 1L1 8L8 15M1 8H17"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
