import { FC } from 'react';
import PortfolioProject from '../../../../api/types/class/PortfolioProject';
import { BlancImage, CardEditIcon, ArrowBack } from './icon';
import {
  ImageBox,
  Card,
  CardImage,
  Date,
  Footer,
  Main,
  BlackBtn,
  BlackBtnText,
  SubTitle,
  SubTitleWrapper,
  SubTitleText,
  Text,
  Title,
  CardEdit
} from './style';
import { Link } from 'react-router-dom';

export interface IProject2 {
  id: number;
  name: string;
  imageUrl: string;
  description: string;
  role: string;
  department: string[];
  date: string;
}

export interface IProjectProps {
  card: PortfolioProject;
}

// Изображение человека не подходит под проект, взял тестовое

const Project: FC<IProjectProps> = ({ card }) => {
  const departments = card.departments?.map((d) => d.name).join(', ');
  const stacks = card.departments
    ?.map((d) => d.stacks?.map((stack) => stack.name))
    .flat()
    .join(', ');
  /*
      const depStacks: string[] = [];

      if (stacks) {
        for (const stack of stacks) {
          if (stack) {
            depStacks.push(...stack);
          }
        }
      }
  */
  return (
    <Card>
      <ImageBox>
        {card.img ? <CardImage src="/assets/img/projectImg.png" alt="обложка" /> : <BlancImage />}
        <CardEdit to={`/profile/projects/${card.id}`}>
          <CardEditIcon />
        </CardEdit>
      </ImageBox>
      <Main>
        <Date>29.07.2022 - 10.11.2022</Date>
        <Title>«{card.title}»</Title>
        <Text>{card.description}</Text>
        <SubTitle>Роль в команде</SubTitle>
        <Text>{card.roleInTeam}</Text>
        <SubTitleWrapper>
          <SubTitle>
            <SubTitleText>Направление</SubTitleText>
            <Text>{departments}</Text>
          </SubTitle>
          <SubTitle>
            <SubTitleText>Стек</SubTitleText>
            <Text>{stacks}</Text>
          </SubTitle>
        </SubTitleWrapper>
        <Footer>
          <Link to={`/profile/projects/${card.id}`}>
            <BlackBtn>
              <BlackBtnText>Подробнее</BlackBtnText>
              <ArrowBack />
            </BlackBtn>
          </Link>
        </Footer>
      </Main>
    </Card>
  );
};

export default Project;
