import styled from 'styled-components';

export const Section = styled.section`
  @media (max-width: 560px) {
    padding: 38px 0;
  }
`;
export const Wrapper = styled.div`
  padding: 128px 90px;
  @media (max-width: 560px) {
    padding: 0px 49px 40px;
  }
`;
export const ListBlock = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill,min(281px,100%));
  gap: 64px 75px;
  @media (max-width: 320px) {
    grid-template-columns: repeat(auto-fill,min(127px,100%));
    gap: 32px 0px;
  }
}
`;
export const Title = styled.h1`
  margin: 0 0 69px 0;
  text-align: center;
  font-size: var(--fs-600);
  font-weight: var(--fw-700);
  line-height: var(--line-height-600);
  color: var(--color-white);
  @media (max-width: 560px) {
    margin: 0 0 35px 0;
  }
`;
