import React from 'react';

export const IconCloseViolet1 = () => {
  return (
    <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5026 39.1668C8.92197 39.1553 0.347553 30.5809 0.335938 20.0002V19.6168C0.546637 9.08388 9.2189 0.695435 19.7531 0.835218C30.2873 0.974981 38.7339 9.59055 38.6651 20.1255C38.5962 30.6603 30.0378 39.1647 19.5026 39.1668ZM9.91927 18.0835V21.9168H17.5859V29.5835H21.4193V21.9168H29.0859V18.0835H21.4193V10.4169H17.5859V18.0835H9.91927Z"
        fill="#6F35CE"
      />
    </svg>
  );
};
