export const IconPlus = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 1.75C5.89137 1.75 1.75 5.89137 1.75 11C1.75 16.1086 5.89137 20.25 11 20.25C16.1086 20.25 20.25 16.1086 20.25 11C20.25 5.89137 16.1086 1.75 11 1.75ZM0.25 11C0.25 5.06294 5.06294 0.25 11 0.25C16.9371 0.25 21.75 5.06294 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75C5.06294 21.75 0.25 16.9371 0.25 11Z"
        fill="#26C0F1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 16C10.4477 16 10 15.5523 10 15L10 7C10 6.44772 10.4477 6 11 6V6C11.5523 6 12 6.44772 12 7L12 15C12 15.5523 11.5523 16 11 16V16Z"
        fill="#26C0F1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 11C16 11.5523 15.5523 12 15 12L7 12C6.44772 12 6 11.5523 6 11V11C6 10.4477 6.44772 10 7 10L15 10C15.5523 10 16 10.4477 16 11V11Z"
        fill="#26C0F1"
      />
    </svg>
  );
};
