import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Container } from '../../lib/styled/container';
import { Section, Wrapper, Title, Grid } from './style';
import { getMembers } from '../../api/Members';
import Filter from '../Filter';
import Preloader from '../../layout/Main/Preloader';
import {
  Badge,
  BadgeWrapper,
  CardBody,
  CardDepartment,
  CardEmail,
  CardFooter,
  CardFooterSection,
  CardFooterSubTitle,
  CardFooterText,
  CardHeader,
  CardMeadia,
  CardSubTitle,
  CardTitle,
  Divider,
  Card
} from './components';
import Member from '../../api/types/class/Member';
import { Flex } from '../UI/Flex/Flex';
import { Chip } from '../UI/Chip/Chip';

const MemberCard: FC<{ member: Member }> = ({ member }) => {
  const departments = member.department?.map((d) => d.name);
  return (
    <Card>
      <CardHeader>
        <BadgeWrapper>
          <Badge src="/assets/img/user-rating.svg" />
          <CardMeadia src={member.profilePhoto?.url} />
        </BadgeWrapper>
      </CardHeader>
      <CardBody>
        <CardTitle>Константинопольский Константин</CardTitle>
        <CardEmail>{member.email}</CardEmail>
        <Divider />
        <CardDepartment>
          <CardSubTitle>Направление</CardSubTitle>
          <Flex gap="8px" mt="8px">
            {departments?.map((d) => (
              <Chip
                key={d}
                padding="10px 16px"
                borderRadius="20px"
                border="1px solid var(--color-white)"
              >
                {d}
              </Chip>
            ))}
          </Flex>
        </CardDepartment>

        <CardDepartment>
          <CardSubTitle>Стек</CardSubTitle>
          <Flex gap="8px" mt="8px">
            {member.department?.map((d) =>
              d.stacks?.map((s) => (
                <Chip
                  key={s.name}
                  padding="10px 16px"
                  borderRadius="20px"
                  border="1px solid var(--color-white)"
                >
                  {s.name}
                </Chip>
              ))
            )}
          </Flex>
        </CardDepartment>
        <Divider />
        <CardFooter>
          <CardFooterSection>
            <CardFooterSubTitle>Участие в проектах</CardFooterSubTitle>
            <CardFooterText>{member.projects?.length}</CardFooterText>
          </CardFooterSection>

          <CardFooterSection>
            <CardFooterSubTitle>Состоит в Гильдии</CardFooterSubTitle>
            <CardFooterText>{member.timeInGuild}</CardFooterText>
          </CardFooterSection>
        </CardFooter>
      </CardBody>
    </Card>
  );
};

const Members: FC = () => {
  const { isLoading, error, data } = useQuery(['members'], getMembers);

  if (isLoading) return <Preloader />;
  if (error) return <div>Ошибка</div>;
  if (!data) return <></>;

  return (
    <Section>
      <Container>
        <Wrapper>
          <div>
            <Title>Наши гильдийцы</Title>
            <Filter page="guildMembers" />
          </div>
          <Grid>
            {data.map((member: Member) => (
              <MemberCard key={member.id} member={member} />
            ))}
          </Grid>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default Members;
