/* eslint-disable @typescript-eslint/no-shadow */
import { FC } from 'react';
import { useProjectStore } from '../../../zustand-store';
import ProjectGodForm from './ProjectGodForm';

const ProjectGodAdd: FC = () => {
  const {
    name,
    description,
    workInfo,
    participantBenefit,
    changeInput,
    checkedDepartments,
    changeProjectLinks,
    addProjectLink,
    projectLinks
  } = useProjectStore();

  return (
    <ProjectGodForm
      name={name}
      workInfo={workInfo}
      description={description}
      participantBenefit={participantBenefit}
      changeInput={changeInput}
      checkedDepartments={checkedDepartments}
      changeProjectLinks={changeProjectLinks}
      addProjectLink={addProjectLink}
      projectLinks={projectLinks}
    />
  );
};

export default ProjectGodAdd;
