import React, { FC } from 'react';
import ProjectsDone from '../components/Projects/ProjectsDone';

const DoneProjects: FC = () => {
  return (
    <>
      <ProjectsDone />
    </>
  );
};

export default DoneProjects;
