// import { instance } from '.';
import Member from './types/class/Member';

export const getMembers = () =>
  new Promise<Member[]>((resolve) =>
    setTimeout(() => resolve(Member.CreateTestDataArray(10)), 1500)
  );
// instance.get('/members/').then((res) => res.data);

export const getMember = () =>
  new Promise<Member>((resolve) => setTimeout(() => resolve(Member.CreateTestData()), 1500));
// instance.get('/members/').then((res) => res.data);
