import { FC } from 'react';
import HeroAbout from '../components/HeroAbout';
import { ArrowIcon } from '../components/BannerBlock/icon';
import BannerBlock from '../components/BannerBlock';
import HowItWorksSliderFull from '../components/HowItWorksSliderFull';
import Accordion from '../components/Accordion';

const HowItWorks: FC = () => {
  return (
    <>
      <HeroAbout
        title="Как это устроено?"
        descr={
          'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Вдали от всех живут они в буквенных домах на берегу Семантика большого языкового океана. \n\nМаленький ручеек Даль журчит по всей стране и обеспечивает ее всеми необходимыми правилами. Эта парадигматическая страна, в которой жаренные члены предложения залетают прямо в рот.'
        }
        image={{
          imageAlt: 'how_it_works',
          imageSrc: '/assets/img/how_it_works.webp',
          imageSrcSet:
            '/assets/img/how_it_works-320.webp 320w, /assets/img/how_it_works.webp 1920w',
          imageSizes: '(max-width: 560px) 320px, (max-width: 561px) 1920px'
        }}
      />

      <HowItWorksSliderFull
        data={[
          {
            num: '1',
            title:
              'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.',
            descr:
              'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Вдали от всех живут они в буквенных домах на берегу Семантика большого языкового океана. \n\nМаленький ручеек Даль журчит по всей стране и обеспечивает ее всеми необходимыми правилами. Эта парадигматическая страна, в которой жаренные члены предложения залетают прямо в рот.'
          },
          {
            num: '2',
            title:
              'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.',
            descr:
              'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Вдали от всех живут они в буквенных домах на берегу Семантика большого языкового океана. \n\nМаленький ручеек Даль журчит по всей стране и обеспечивает ее всеми необходимыми правилами. Эта парадигматическая страна, в которой жаренные члены предложения залетают прямо в рот.'
          },
          {
            num: '3',
            title:
              'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.',
            descr:
              'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Вдали от всех живут они в буквенных домах на берегу Семантика большого языкового океана. \n\nМаленький ручеек Даль журчит по всей стране и обеспечивает ее всеми необходимыми правилами. Эта парадигматическая страна, в которой жаренные члены предложения залетают прямо в рот.'
          },
          {
            num: '4',
            title:
              'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.',
            descr:
              'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Вдали от всех живут они в буквенных домах на берегу Семантика большого языкового океана. \n\nМаленький ручеек Даль журчит по всей стране и обеспечивает ее всеми необходимыми правилами. Эта парадигматическая страна, в которой жаренные члены предложения залетают прямо в рот.'
          }
        ]}
      />

      <Accordion
        data={[
          {
            title:
              'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.',
            text: 'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Вдали от всех живут они в буквенных домах на берегу Семантика большого языкового океана. \n\nМаленький ручеек Даль журчит по всей стране и обеспечивает ее всеми необходимыми правилами. Эта парадигматическая страна, в которой жаренные члены предложения залетают прямо в рот.'
          },
          {
            title:
              'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.',
            text: 'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Вдали от всех живут они в буквенных домах на берегу Семантика большого языкового океана. \n\nМаленький ручеек Даль журчит по всей стране и обеспечивает ее всеми необходимыми правилами. Эта парадигматическая страна, в которой жаренные члены предложения залетают прямо в рот.'
          },
          {
            title:
              'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.',
            text: 'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Вдали от всех живут они в буквенных домах на берегу Семантика большого языкового океана. \n\nМаленький ручеек Даль журчит по всей стране и обеспечивает ее всеми необходимыми правилами. Эта парадигматическая страна, в которой жаренные члены предложения залетают прямо в рот.'
          },
          {
            title:
              'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.',
            text: 'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Вдали от всех живут они в буквенных домах на берегу Семантика большого языкового океана. \n\nМаленький ручеек Даль журчит по всей стране и обеспечивает ее всеми необходимыми правилами. Эта парадигматическая страна, в которой жаренные члены предложения залетают прямо в рот.'
          },
          {
            title:
              'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.',
            text: 'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Вдали от всех живут они в буквенных домах на берегу Семантика большого языкового океана. \n\nМаленький ручеек Даль журчит по всей стране и обеспечивает ее всеми необходимыми правилами. Эта парадигматическая страна, в которой жаренные члены предложения залетают прямо в рот.',
            isOpenedDefault: true
          }
        ]}
      />

      <BannerBlock
        title="Узнайте больше о самой Гильдии"
        href="https://guild-of-developers.ru/aboutGuild"
        icon={<ArrowIcon />}
        bgImageUrl="/assets/img/banner_about_guild.svg"
      />
    </>
  );
};

export default HowItWorks;
