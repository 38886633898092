import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Section = styled.section`
  background: var(--color-fon);

  @media (min-width: 768px) {
    padding-top: 0px;
    padding-bottom: 128px;
  }
`;

export const BackgroundImg = styled.div`
  background: url(/assets/img/profile-cover.webp);
  border-radius: 20px;
  display: block;
  width: 100%;
  height: 47vw;
  background-size: cover;

  @media (min-width: 392px) {
    max-height: 219px;
    height: 31.8vw;
  }
  @media (min-width: 768px) {
    max-height: 270px;
    height: 28vw;
  }
  @media (min-width: 1024px) {
    max-height: 343px;
    height: 28vw;
  }
`;

export const Info = styled.div`
  padding-top: 128px;
  padding-bottom: 64px;
  margin-top: -25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  position: relative;

  @media (min-width: 768px) {
    margin-top: -25px;
    padding-left: 58px;
    padding-right: 58px;
    flex-direction: row;
    gap: 35px;
    justify-content: space-between;
  }
  @media (min-width: 1024px) {
    padding-left: 80px;
    padding-right: 80px;
    flex-direction: row;
    gap: 60px;
  }
`;

export const Header = styled.header`
  position: relative;
`;

export const InfoText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 35px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
  }
`;

export const InfoChange = styled(Link)`
  padding: 15px 70px;
  width: 100%;
  position: relative;

  background: var(--gradient-1);
  border-radius: 25px;
  border: none;

  font-size: var(--fs-250);
  font-weight: var(--fw-600);
  line-height: var(--line-height-350);
  text-align: center;
  color: var(--color-white);

  transition: all 0.2s ease-in-out;

  &::before {
    position: absolute;
    content: '';
    inset: 0;
    background: var(--gradient-5);
    z-index: 2;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    border-radius: 25px;
  }

  &:hover::before {
    opacity: 1;
  }

  &:active::before {
    opacity: 0;
  }

  &:active {
    background: var(--gradient-2);
  }

  @media (min-width: 768px) {
    width: 281px;
  }
`;
export const InfoChangeText = styled.span`
  position: relative;
  z-index: 3;
`;

export const Avatar = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  flex-shrink: 0;
  border: 3px solid var(--color-blue-1);

  @media (min-width: 1024px) {
    width: 160px;
    height: 160px;
  }
`;

export const Badge = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 44px;
  height: 44px;

  & svg {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1024px) {
    width: 50px;
    height: 50px;
  }
`;

export const Member = styled.div`
  margin-top: 34px;
`;

export const MemberWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: column;
`;

export const MemberName = styled.h1`
  color: var(--color-white);
  font-size: 24px;
  font-weight: var(--fw-700);
  line-height: var(--line-height-550);
  text-align: center;

  @media (min-width: 1200px) {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
  }
`;

export const MemberUserName = styled.p`
  margin-top: 6px;
  color: var(--color-white);
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;

  @media (min-width: 768px) {
    margin-top: 13px;
    text-align: unset;
    line-height: var(--line-height-550);
  }
`;

export const Descr = styled.div`
  margin-top: 88px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;

  @media (min-width: 768px) {
    margin-top: 82px;
    flex-wrap: nowrap;
    gap: 10px;
  }

  @media (min-width: 1024px) {
    gap: 20px;
  }

  @media (min-width: 1200px) {
    flex-wrap: nowrap;
    gap: 24px;
  }
`;

export const DescrText = styled.div`
  background: var(--gradient-2);
  color: var(--color-white);
  padding: 32px;
  min-width: initial;

  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;

  li {
    display: list-item;
    font-size: 1em;
    list-style-position: inside;
    padding-left: 20px;
  }

  li:before {
    content: '•';
    line-height: 20px;
    margin-left: -15px;
    padding-right: 10px;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weith: 700;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    min-width: 397px;
  }

  @media (min-width: 768px) {
    max-width: 462px;
  }

  @media (min-width: 1200px) {
    min-width: 691px;
  }
`;

export const DescrList = styled.ul`
  gap: 26px;
  width: 100%;
  padding: 26px 35px;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  background: var(--gradient-2);

  @media (min-width: 768px) {
    justify-content: center;
    max-width: 281px;
  }
  @media (min-width: 1024px) {
    justify-content: center;
    max-width: 302px;
  }
`;

export const DescrItem = styled.li`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (min-width: 768px) {
  }
`;

export const DescrItemText = styled.p`
  color: var(--color-white);
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700px;
  }
`;

export const DescrItemLink = styled.a`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
  color: var(--color-white);

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
  }
`;

export const Skills = styled.ul`
  margin-top: 88px;
  padding: 32px;
  border-radius: 30px;
  background: var(--gradient-2);

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    padding: 48px 0;
  }
`;

export const SkillsWrapper = styled.div`
  max-width: 232px;

  @media (min-width: 768px) {
    max-width: 584px;
  }
  @media (min-width: 1024px) {
    max-width: 826px;
  }
  @media (min-width: 1200px) {
    max-width: 996px;
  }
`;

export const SkillsItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 20px;

  &:not(:last-child) {
    margin-bottom: 64px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 16px;
  }
  @media (min-width: 1024px) {
    gap: 64px 32px;
  }
`;

export const SkillsDepartment = styled.div`
  width: 100%;
  padding: 35px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(12, 24, 44, 0.8), rgba(12, 24, 44, 0.8)), url(.jpg);
  border-radius: 20px;
  color: var(--color-white);
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;

  @media (min-width: 768px) {
    width: 221px;
    flex: none;
    padding: 55px 24px;
  }
  @media (min-width: 1024px) {
    width: 311px;
  }
`;

export const SkillsStackList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 48px 23px;
  @media (min-width: 1024px) {
    gap: 34px 18px;
  }
  @media (min-width: 1200px) {
    gap: 34px 24px;
    max-width: 665px;
  }
`;

export const SkillsStackItem = styled.li`
  width: 100%;
  padding: 14px;
  max-height: 48px;

  border: 1px solid var(--color-white);
  border-radius: 30px;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  color: var(--color-white);

  @media (min-width: 768px) {
    width: 161px;
    font-size: 14px;
  }
  @media (min-width: 1024px) {
    width: 145px;
    font-size: 16px;
  }
  @media (min-width: 1024px) {
    width: 201px;
  }
`;

export const ProfileTitle = styled.h2`
  margin-top: 88px;
  margin-bottom: 64px;
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  color: var(--color-white);
  text-align: center;
`;

export const ProfileWrapper = styled.div`
  background: var(--gradient-2);
  padding: 30px 32px;
  width: 100%;
  border-radius: 20px;
`;

export const ProfileText = styled.p`
  color: var(--color-white);
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
`;

export const Projects = styled.div`
  margin-top: 88px;
  margin-bottom: 88px;
`;

export const Portfolio = styled.div`
  margin-top: 88px;
`;

export const OtherMembers = styled.div`
  display: block;
`;

export const Divider = styled.hr`
  margin: 88px 0;
  height: 4px;
  border: none;
  background: var(--color-grey-3);
`;

export const Grid = styled.ul`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(1, 296px);
  gap: 24px;
  margin-bottom: 64px;
  @media (min-width: 768px) {
    gap: 16px 10px;
    grid-template-columns: repeat(2, 339px);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, min(462px, 100%));
    gap: 24px 20px;
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(2, min(588px, 100%));
    gap: 32px 24px;
  }
`;
