import { SVGProps } from 'react';
const TrashBinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="none">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.336 5.333C.336 4.597.852 4 1.489 4h19.027c.637 0 1.153.597 1.153 1.333 0 .737-.516 1.334-1.153 1.334H1.49C.852 6.667.336 6.07.336 5.333ZM8.333 9.333c.737 0 1.334.523 1.334 1.167v7c0 .644-.597 1.167-1.334 1.167C7.597 18.667 7 18.144 7 17.5v-7c0-.644.597-1.167 1.333-1.167ZM13.67 9.333c.736 0 1.333.523 1.333 1.167v7c0 .644-.597 1.167-1.334 1.167-.736 0-1.333-.523-1.333-1.167v-7c0-.644.597-1.167 1.333-1.167Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.876 4c.665 0 1.204.503 1.204 1.123v16.631h13.85V5.123c0-.62.54-1.123 1.204-1.123.665 0 1.204.503 1.204 1.123v16.912a1.9 1.9 0 0 1-.617 1.39 2.188 2.188 0 0 1-1.49.575H3.779a2.188 2.188 0 0 1-1.49-.576 1.9 1.9 0 0 1-.617-1.389V5.123c0-.62.54-1.123 1.204-1.123Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.494.849A2.763 2.763 0 0 1 8.479 0h5.053c.744 0 1.458.305 1.984.849.527.544.822 1.28.822 2.05v2.608c0 .64-.502 1.16-1.122 1.16-.62 0-1.123-.52-1.123-1.16V2.9a.59.59 0 0 0-.165-.41.553.553 0 0 0-.397-.17H8.48a.553.553 0 0 0-.397.17.59.59 0 0 0-.165.41v2.608c0 .64-.502 1.16-1.122 1.16-.62 0-1.123-.52-1.123-1.16V2.9c0-.77.296-1.506.822-2.05Z"
      clipRule="evenodd"
    />
  </svg>
);
export default TrashBinIcon;
