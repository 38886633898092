import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  justify-content: center;
  gap: 22px;
  @media (max-width: 1280px) {
    flex-direction: column;
    max-width: 388px;
    margin: 0 auto;
  }
  @media (max-width: 780px) {
    max-width: 288px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--gradient-2);
  border-radius: 20px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  background: var(--gradient-3);
  border-radius: 15px;
  padding: 60px 40px;

  width: 388px;

  @media (max-width: 780px) {
    max-width: 288px;
  }
`;

export const ImageBox = styled.div`
  width: 265px;
  height: 199px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-grey-3);
  border-radius: 10px;
`;

export const CardImage = styled.img`
  border-radius: 10px;
  width: 265px;
  height: 199px;

  @media (max-width: 780px) {
    width: 242px;
  }
`;

export const DetailsWrapper = styled.div`
  padding: 17px 25px 29px;
  @media (max-width: 780px) {
    padding: 17px 16px 29px;
  }
`;

export const SubTitle = styled.h2`
  font-size: 24px;
  color: var(--color-white);
  font-weight: var(--fw-700);
  @media (max-width: 780px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: var(--color-white);
  margin: 35px 0 44px;
  line-height: 17px;
  font-feature-settings: 'pnum' on, 'lnum' on;
`;

export const Date = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-style: normal;
  margin: 0 0 18px;

  color: var(--color-white);
  font-weight: 400;
  font-feature-settings: 'pnum' on, 'lnum' on;
`;

export const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;

  border-radius: 25px;
  width: 183px;
  height: 40px;

  transition: opacity 0.3s;

  position: relative;
  background: var(--gradient-1);
  z-index: 1;
  &::before {
    border-radius: 25px;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--gradient-4);
    z-index: -1;
    transition: opacity 0.5s;
    opacity: 0;
  }
  &:hover::before {
    opacity: 1;
  }
  &:active {
    border: 2px solid #26c0f1;
    border-radius: 25px;
  }
  @media (max-width: 780px) {
    width: 256px;
    font-weight: 600;
    font-size: 16px;
  }
`;
