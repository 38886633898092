import { FC } from 'react';
import { StyledLoginButton } from './style';

interface ButtonProps {
  children: string;
}

const LoginButton: FC<ButtonProps> = ({ children }) => {
  return <StyledLoginButton>{children}</StyledLoginButton>;
};

export default LoginButton;
