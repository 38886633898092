import { BlankImage } from '../../../UI/Icons/BlankImage';
import { IProject2 } from '../../ProjectsEdit/Project';
import {
  Button,
  Card,
  CardImage,
  Container,
  Date,
  Description,
  DetailsWrapper,
  ImageBox,
  ImageWrapper,
  SubTitle
} from './style';

export const ProjectSmallCard = () => {
  const data: IProject2[] = [
    {
      id: 1,
      imageUrl: '/assets/img/project-screenshot.webp',
      name: '«Инкубатор Фондов»',
      description:
        'Проект, основной целью которого является создание платформы, которая будет оказывать помощь в развитии новых фондов и заниматься продвижением существующих Если ты давно хотел(а) принять участие в разработке сложного и большого веб-приложения, то это твой шанс.',
      role: 'Роль в команде Роль в команде Роль в команде Роль в команде Роль в команде Роль в команде Роль в команде Роль в команде Роль в команде.',
      department: ['Backend', 'Data Scientist'],
      date: '29.07.22 - 10.11.2022'
    },
    {
      id: 2,
      imageUrl: '/assets/img/project-screenshot.webp',
      name: '«Инкубатор Фондов»',
      description:
        'Проект, основной целью которого является создание платформы, которая будет оказывать помощь в развитии новых фондов и заниматься продвижением существующих Если ты давно хотел(а) принять участие в разработке сложного и большого веб-приложения, то это твой шанс.',
      role: 'Роль в команде Роль в команде Роль в команде Роль в команде Роль в команде Роль в команде Роль в команде Роль в команде Роль в команде.',
      department: ['Backend', 'Data Scientist'],
      date: '29.07.22 - 10.11.2022'
    },
    {
      id: 3,
      imageUrl: '/assets/img/project-screenshot.webp',
      name: '«Инкубатор Фондов»',
      description:
        'Проект, основной целью которого является создание платформы, которая будет оказывать помощь в развитии новых фондов и заниматься продвижением существующих Если ты давно хотел(а) принять участие в разработке сложного и большого веб-приложения, то это твой шанс.',
      role: 'Роль в команде Роль в команде Роль в команде Роль в команде Роль в команде Роль в команде Роль в команде Роль в команде Роль в команде.',
      department: ['Backend', 'Data Scientist'],
      date: '29.07.22 - 10.11.2022'
    }
  ];

  return (
    <>
      <Container>
        {data.map((card) => (
          <Card key={card.id}>
            <ImageWrapper>
              <ImageBox>
                {card.imageUrl ? <CardImage src={card.imageUrl} alt="обложка" /> : <BlankImage />}
              </ImageBox>
            </ImageWrapper>
            <DetailsWrapper>
              <Date>29.07.2022 - 10.11.2022</Date>
              <SubTitle>«Инкубатор Фондов»</SubTitle>
              <Description>
                Проект, основной целью которого является создание платформы, которая будет оказывать
                помощь в развитии новых фондов и заниматься продвижением существующих...
              </Description>
              <Button to="#">Подробнее</Button>
            </DetailsWrapper>
          </Card>
        ))}
      </Container>
    </>
  );
};
