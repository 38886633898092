import React, { FC, useEffect, useState } from 'react';

import ProjectClass from '../../../api/types/class/Project';
import Project from '../Project';

import { Section, Grid } from './style';
import { useMediaQuery } from '../../../services/useMediaQuery';
import ReactPaginate from 'react-paginate';
import { ArrowPagination } from './icon';
import axios from 'axios';
import { getProjectsNotDoneII } from '../../../api/Projects';

interface ItemsProps {
  items: ProjectClass[];
}

interface PaginatedProps {
  itemsPerPage: number;
  data: ProjectClass[];
}

const Items: FC<ItemsProps> = ({ items }) => {
  return (
    <Grid>
      {items?.map((project: ProjectClass) => (
        <Project key={project.id} project={project} />
      ))}
    </Grid>
  );
};

const PaginatedItems: FC<PaginatedProps> = ({ itemsPerPage, data }) => {
  const isMobile = useMediaQuery('(max-width: 650px)');

  const [currentItems, setCurrentItems] = React.useState<ProjectClass[]>([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [itemOffset, setItemOffset] = React.useState(0);

  React.useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items items={currentItems} />
      <ReactPaginate
        breakLabel="..."
        nextLabel={<ArrowPagination />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={isMobile ? 2 : 4}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        previousLabel={<ArrowPagination />}
        containerClassName={data.length < itemsPerPage ? 'pagination hidden' : 'pagination'}
        pageLinkClassName="page-num"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        activeLinkClassName="active"
      />
    </>
  );
};

const CurrentProjects: FC = () => {
  const [data, setData] = useState<ItemsProps>();
  // const { REACT_APP_BACKEND_API_HOST = '', REACT_APP_BACKEND_API_TOKEN } = process.env;

  useEffect(() => {
    // const apiUrl = `${REACT_APP_BACKEND_API_HOST}projects/not_done`;
    // const headers = {
    //   'X-ACCESS-TOKEN': REACT_APP_BACKEND_API_TOKEN,
    //   'Content-Type': 'application/json'
    // };
    // const params = {
    //   limit: 100
    // };

    // axios.get(apiUrl, { headers, params }).then((resp) => {
    //   const projects = resp.data;
    //   setData(projects);
    // });
    getProjectsNotDoneII()
      .then((res) => setData(res))
      .catch((err) => console.log(err));
  }, [setData]);

  return (
    <Section>
      <PaginatedItems itemsPerPage={6} data={data ? data?.items : []} />
    </Section>
  );
};

export default CurrentProjects;
