import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 30px;
  z-index: 1000;
`;

export const Container = styled.div`
  width: 1200px;
  background: linear-gradient(101.08deg, #26c0f1 9.94%, #6f35ce 86.57%);
  border-radius: 10px;
  padding: 13px 78px;
  display: flex;
  gap: 21px;
  align-items: center;

  @media screen and (max-width: 1200px) {
    width: 80%;
  }
  @media screen and (max-width: 780px) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 13px 28px;
    gap: 13px;
  }
`;

export const CookieImage = styled.img`
  display: block;
  width: 37px;
  height: 37px;
  @media screen and (max-width: 780px) {
    display: none;
  }
`;

export const TextBlockWrapper = styled.div`
  width: auto;
`;

export const TextBlock = styled.div`
  color: var(--color-white);
  font-size: 14px;
  font-weight: 600px;
  line-height: 20px;
  @media (max-width: 1400px) {
    width: 100%;
  }
  @media (max-width: 560px) {
    padding-top: 0;
    padding-right: 0;
    padding-left: 2px;
  }
`;

export const NextButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: var(--color-white);

  border-radius: 25px;
  min-width: 200px;
  height: 40px;

  position: relative;
  background: var(--color-blue-1);
  z-index: 1;
  &:hover::before {
    opacity: 1;
  }
  @media (max-width: 780px) {
    padding: 20px 30px;
    height: 34px;
    font-weight: 600;
    font-size: 14px;
  }
`;

export const StyledLink = styled(Link)`
  color: var(--color-violet-1);
`;
