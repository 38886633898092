export const BlankImage = () => {
  return (
    <svg width="54" height="44" viewBox="0 0 54 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5.73913V38.2609H48V5.73913H6ZM0 4.78261C0 2.14125 2.23858 0 5 0H49C51.7614 0 54 2.14125 54 4.78261V39.2174C54 41.8588 51.7614 44 49 44H5C2.23858 44 0 41.8588 0 39.2174V4.78261Z"
        fill="#535353"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 22.2321L5.12132 33.5943C3.94975 34.7149 2.05025 34.7149 0.87868 33.5943C-0.292893 32.4737 -0.292893 30.6568 0.87868 29.5361L13.4474 17.5138C13.9106 17.0665 14.4617 16.711 15.0689 16.4678C15.6805 16.2228 16.337 16.0967 17 16.0967C17.663 16.0967 18.3195 16.2228 18.9311 16.4678C19.5384 16.711 20.0894 17.0665 20.5527 17.5139L31 27.507L35.4472 23.2532C35.9104 22.8058 36.4615 22.4502 37.0689 22.2069C37.6805 21.962 38.3369 21.8358 39 21.8358C39.6631 21.8358 40.3195 21.962 40.9311 22.2069C41.5385 22.4502 42.0896 22.8058 42.5528 23.2532L53.1213 33.3622C54.2929 34.4828 54.2929 36.2998 53.1213 37.4204C51.9497 38.541 50.0503 38.541 48.8787 37.4204L39 27.9712L34.5526 32.2252C34.0894 32.6726 33.5384 33.0281 32.9311 33.2714C32.3195 33.5163 31.6631 33.6425 31 33.6425C30.3369 33.6425 29.6805 33.5163 29.0689 33.2714C28.4616 33.0281 27.9106 32.6726 27.4473 32.2252L17 22.2321Z"
        fill="#535353"
      />
      <path
        d="M34 19.1304C36.2091 19.1304 38 17.4174 38 15.3043C38 13.1913 36.2091 11.4783 34 11.4783C31.7909 11.4783 30 13.1913 30 15.3043C30 17.4174 31.7909 19.1304 34 19.1304Z"
        fill="#535353"
      />
    </svg>
  );
};
