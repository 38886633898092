export const LogoIcon = () => {
  return (
    <svg width="57" height="36" viewBox="0 0 57 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5331 26.8648C10.782 26.8648 9.1434 26.5041 7.61741 25.7827C6.09143 25.0613 4.75307 24.0663 3.60232 22.7977C2.4766 21.5291 1.58852 20.0864 0.938105 18.4695C0.312702 16.8527 0 15.1363 0 13.3204C0 11.5046 0.312702 9.80066 0.938105 8.20867C1.58852 6.61669 2.4766 5.19883 3.60232 3.95509C4.75307 2.71135 6.09143 1.74123 7.61741 1.04474C9.16842 0.348247 10.857 0 12.6832 0C15.2098 0 17.3362 0.534807 19.0623 1.60442C20.8134 2.64916 22.1268 4.04215 23.0023 5.78339L19.7753 8.09674C19.0748 6.654 18.0742 5.57195 16.7733 4.85058C15.4975 4.12921 14.0966 3.76853 12.5706 3.76853C11.3198 3.76853 10.1816 4.02971 9.15591 4.55208C8.13025 5.07445 7.24217 5.79582 6.49169 6.71619C5.76622 7.61168 5.21586 8.64398 4.84062 9.8131C4.46538 10.9573 4.27776 12.1638 4.27776 13.4324C4.27776 14.7756 4.4904 16.0318 4.91567 17.2009C5.34095 18.37 5.94133 19.4023 6.71683 20.2978C7.49233 21.1684 8.40542 21.8525 9.4561 22.35C10.5068 22.8475 11.645 23.0962 12.8708 23.0962C14.3217 23.0962 15.6976 22.7355 16.9985 22.0142C18.3243 21.2679 19.5626 20.161 20.7134 18.6934V22.947C18.3869 25.5588 15.6601 26.8648 12.5331 26.8648ZM20.3006 16.38H12.5706V13.2831H20.7134L20.4601 15.951L20.791 19.6814L22.6014 24.4703L23.8279 26.6782L21.4918 25.3463L20.3006 16.38Z"
        fill="white"
      />
      <path
        d="M34.8073 26.6312L35.625 23.2439L37.4354 16.4109L34.8073 11.5052L34.8073 0.233643H44.1594C47.0046 0.233643 49.3798 0.828516 51.2848 2.01826C53.1899 3.18323 54.6125 4.76956 55.5526 6.77726C56.5175 8.76017 57 10.9662 57 13.3952C57 16.0722 56.468 18.4021 55.4042 20.385C54.365 22.3679 52.8806 23.9047 50.9508 24.9953C49.021 26.0859 46.7572 26.6312 44.1594 26.6312H34.8073ZM52.7693 13.3952C52.7693 11.5363 52.4229 9.90035 51.7301 8.48752C51.0621 7.04991 50.0849 5.93452 48.7983 5.14135C47.5365 4.3234 45.9902 3.91443 44.1594 3.91443H38.9638V22.9504H44.1594C46.015 22.9504 47.5737 22.529 48.8354 21.6863C50.122 20.8436 51.0992 19.7034 51.7672 18.2658C52.4353 16.8282 52.7693 15.2046 52.7693 13.3952Z"
        fill="white"
      />
      <g filter="url(#filter0_d_1597_4729)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.4416 28.9672C34.0861 28.9672 38.6619 24.3914 38.6619 18.7469C38.6619 13.1024 34.0861 8.52661 28.4416 8.52661C22.7971 8.52661 18.2213 13.1024 18.2213 18.7469C18.2213 24.3914 22.7971 28.9672 28.4416 28.9672ZM28.4694 25.5645C32.7819 25.5645 36.2778 22.0686 36.2778 17.7561C36.2778 13.4437 32.7819 9.94779 28.4694 9.94779C24.157 9.94779 20.6611 13.4437 20.6611 17.7561C20.6611 22.0686 24.157 25.5645 28.4694 25.5645Z"
          fill="url(#paint0_linear_1597_4729)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1597_4729"
          x="12.2213"
          y="3.52661"
          width="32.4406"
          height="32.4407"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1597_4729" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1597_4729"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1597_4729"
          x1="28.5"
          y1="6.07374"
          x2="28.5"
          y2="31.2448"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#26C0F1" />
          <stop offset="1" stopColor="#6F35CE" />
        </linearGradient>
      </defs>
      <path d="M19 35H38" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export const LogoIconMobile = () => {
  return (
    <svg width="96" height="48" viewBox="0 0 96 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.1084 44.5161C18.1591 44.5161 15.3994 43.9185 12.8293 42.7231C10.2592 41.5278 8.00516 39.879 6.06707 37.7769C4.17111 35.6747 2.67541 33.284 1.57997 30.6048C0.526655 27.9256 0 25.0815 0 22.0726C0 19.0636 0.526655 16.2401 1.57997 13.6021C2.67541 10.9642 4.17111 8.6147 6.06707 6.55376C8.00516 4.49283 10.2592 2.8853 12.8293 1.73118C15.4415 0.577061 18.2855 0 21.3611 0C25.6165 0 29.1978 0.8862 32.1049 2.6586C35.0542 4.38978 37.2661 6.69803 38.7408 9.58333L33.3057 13.4167C32.126 11.026 30.4407 9.23297 28.2498 8.03763C26.101 6.84229 23.7416 6.24462 21.1716 6.24462C19.0649 6.24462 17.1479 6.67742 15.4205 7.54301C13.693 8.4086 12.1973 9.60394 10.9334 11.129C9.71153 12.6129 8.78461 14.3235 8.15263 16.2607C7.52064 18.1568 7.20465 20.1559 7.20465 22.2581C7.20465 24.4839 7.56277 26.5654 8.27903 28.5027C8.99528 30.44 10.0065 32.1505 11.3126 33.6344C12.6187 35.0771 14.1565 36.2106 15.9261 37.0349C17.6956 37.8593 19.6126 38.2715 21.6771 38.2715C24.1208 38.2715 26.4381 37.6738 28.629 36.4785C30.862 35.2419 32.9476 33.4077 34.8857 30.9758V38.0242C30.9673 42.3521 26.3749 44.5161 21.1084 44.5161ZM34.1905 27.1425H21.1716V22.0107H34.8857L34.4591 26.4315L35.0164 32.6129L38.0656 40.5484L40.1311 44.207L36.1967 42L34.1905 27.1425Z"
        fill="white"
      />
      <path
        d="M58.623 44.1289L60.0001 38.516L63.0493 27.1934L58.623 19.0644L58.623 0.386963H74.3739C79.1658 0.386963 83.1661 1.3727 86.3746 3.34416C89.5831 5.27456 91.979 7.90319 93.5625 11.23C95.1875 14.5158 96.0001 18.1712 96.0001 22.1963C96.0001 26.6321 95.1042 30.4929 93.3124 33.7787C91.5624 37.0645 89.0622 39.611 85.812 41.4181C82.5619 43.2253 78.7491 44.1289 74.3739 44.1289H58.623ZM88.8747 22.1963C88.8747 19.1159 88.2913 16.4051 87.1246 14.064C85.9995 11.6818 84.3536 9.83358 82.1868 8.51927C80.0617 7.16388 77.4574 6.48619 74.3739 6.48619H65.6234V38.0297H74.3739C77.4991 38.0297 80.1242 37.3314 82.2493 35.935C84.4161 34.5385 86.062 32.6492 87.1871 30.267C88.3122 27.8848 88.8747 25.1946 88.8747 22.1963Z"
        fill="white"
      />
      <g filter="url(#filter0_d_1597_1834)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.9016 47.9999C57.4081 47.9999 65.1147 40.4176 65.1147 31.0644C65.1147 21.7112 57.4081 14.1289 47.9016 14.1289C38.3951 14.1289 30.6885 21.7112 30.6885 31.0644C30.6885 40.4176 38.3951 47.9999 47.9016 47.9999ZM47.9483 42.3614C55.2113 42.3614 61.0991 36.5686 61.0991 29.4227C61.0991 22.2768 55.2113 16.4839 47.9483 16.4839C40.6852 16.4839 34.7974 22.2768 34.7974 29.4227C34.7974 36.5686 40.6852 42.3614 47.9483 42.3614Z"
          fill="url(#paint0_linear_1597_1834)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1597_1834"
          x="24.6885"
          y="9.12891"
          width="46.4263"
          height="45.8711"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1597_1834" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1597_1834"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1597_1834"
          x1="47.9999"
          y1="10.0644"
          x2="47.9999"
          y2="51.7741"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#26C0F1" />
          <stop offset="1" stopColor="#6F35CE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
