import styled from 'styled-components';

export const Section = styled.section`
  padding: 25px 0;
  @media (max-width: 560px) {
    padding: 25px 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 560px) {
    flex-wrap: wrap;
    padding: 0;
  }
`;

export const PictureWrapper = styled.div`
  width: 44.011%;
  overflow: hidden;
  border-radius: 0px 30px 30px 0px;
  @media (max-width: 1000px) {
    width: 100%;
    border-radius: none;
  }
`;
export const TextBlockWrapper = styled.div`
  display: flex;
  width: 55.988%;
  justify-content: flex-start;
  @media (max-width: 1000px) {
    width: 100%;
    padding-top: 0;
  }
`;

export const TextBlock = styled.div`
  width: 67%;
  padding-top: 96px;
  padding-right: 20px;
  padding-left: 25px;
  @media (max-width: 1400px) {
    width: 100%;
    padding-left: 20px;
    padding-top: 30px;
  }
  @media (max-width: 560px) {
    padding-top: 0;
    padding-right: 0;
    padding-left: 2px;
  }
`;

export const Title = styled.h1`
  margin: 0 0 45px;
  font-size: var(--fs-800);
  font-weight: var(--fw-700);
  line-height: var(--line-height-600);
  color: var(--color-white);
  @media (max-width: 1000px) {
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 0 0 33px;
    font-size: var(--fs-600);
  }
`;

export const Descr = styled.p`
  font-size: var(--fs-900);
  font-weight: var(--fw-400);
  line-height: var(--line-height-800);
  color: var(--color-white);
  @media (max-width: 1000px) {
    padding-bottom: 30px;
  }
  @media (max-width: 560px) {
    line-height: var(--line-height-400);
    font-size: var(--fs-350);
  }
`;

export const DescrP1 = styled.p`
  color: var(--white, #fff);
  font-variant-numeric: lining-nums proportional-nums;
  text-align: justify;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
  @media (max-width: 560px) {
    line-height: var(--line-height-400);
    font-size: var(--fs-350);
  }
`;
export const DescrP2 = styled.p`
  color: var(--white, #fff);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
  margin-top: 20px;
  text-align: justify;
  @media (max-width: 560px) {
    line-height: var(--line-height-400);
    font-size: var(--fs-350);
  }
  &:last-of-type {
    margin-bottom: 64px;
  }
`;

export const Img = styled.img`
  display: block;
`;
