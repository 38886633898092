import styled from 'styled-components';

export const ProfileTitle = styled.h2`
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  color: var(--color-white);
  text-align: center;
`;

export const Grid = styled.ul`
  display: grid;
  justify-content: center;
  grid-template-columns: min(339px, 100%);
  gap: 24px;
  margin-bottom: 64px;
  @media (min-width: 768px) {
    gap: 16px 10px;
    grid-template-columns: min(339px, 100%) min(339px, 100%);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, min(301px, 100%));
    gap: 24px 20px;
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, min(386px, 100%));
    gap: 32px 22px;
  }
`;

export const Portfolios = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

export const Section = styled.section`
  background-color: var(--color-fon);
  position: relative;
  color: var(--color-white);

  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    align-items: center;
    gap: 15px;
    margin-top: 64px;
    margin-bottom: 128px;
    user-select: none;

    @media (max-width: 650px) {
      gap: 4px;
    }

    .page-num {
      display: block;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--fs-500);
      font-weight: var(--fw-600);
      font-feature-settings: 'pnum' on, 'lnum' on;

      line-height: var(--line-height-550);
      cursor: pointer;
    }

    .break {
      cursor: pointer;
      font-size: var(--fs-500);
      font-weight: var(--fw-600);
    }

    .selected {
      border-radius: 50%;
      background: var(--gradient-1);
    }

    .previous {
      cursor: pointer;
      margin-right: 12px;

      @media (max-width: 650px) {
        margin-right: 1px;

        svg {
          width: 14px;
          height: 12px;
        }
      }
    }

    .next {
      cursor: pointer;
      svg {
        transform: rotate(180deg);
      }

      @media (max-width: 650px) {
        margin-left: 1px;

        svg {
          width: 14px;
          height: 12px;
        }
      }
    }
  }
`;

export const BlackBtn = styled.button`
  min-width: 179px;
  padding: 16px 50px;
  max-width: 384px;
  margin: 32px auto 51px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  background: var(--gradient-1);
  border-radius: 25px;
  border: none;

  font-size: var(--fs-250);
  font-weight: var(--fw-600);
  line-height: var(--line-height-350);
  color: var(--color-white);

  transition: all 0.2s ease-in-out;

  &::before {
    position: absolute;
    content: '';
    inset: 0;
    background: var(--gradient-5);
    z-index: 2;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    border-radius: 25px;
  }

  &:hover::before {
    opacity: 1;
  }

  &:active::before {
    opacity: 0;
  }

  &:active {
    background: var(--gradient-2);
  }

  svg {
    position: relative;
    z-index: 3;
  }
`;

export const BlackBtnText = styled.span`
  position: relative;
  z-index: 3;
`;
