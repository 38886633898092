import styled from 'styled-components';
import { CardStates } from '../../../zustand-store';

export const MainContent = styled.ul`
  padding: 16px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, min(377px, 100%));
  gap: 50px;
  justify-content: center;
  color: #ffffff;
  border-radius: 20px;
  padding: 32px;
  text-align: start;
  @media (max-width: 980px) {
    grid-template-columns: repeat(1, min(572px, 100%));
  }
  @media (max-width: 680px) {
    grid-template-columns: repeat(1, min(100%, 100%));
  }
  @media (max-width: 414px) {
    grid-template-columns: repeat(1, min(296px, 100%));
  }
`;
export const Title = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: white;
  margin-bottom: 30px;
`;
export const Card = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 800px;
  margin-left: 400px;
  margin-right: 100px;
  @media (max-width: 1350px) {
    margin-left: 20%;
  }
  @media (max-width: 750px) {
    margin-left: 30%;
  }
  @media (max-width: 500px) {
    margin-left: 40%;
  }
  @media (max-width: 414px) {
    margin: 0px;
  }
`;
export const Tools = styled.div<{ $collapsed?: boolean }>`
  display: flex;
  z-index: 999;
  flex-wrap: wrap;
  justify-content: start;
  width: 306px;
  text-align: center;
  height: 480px;
  color: #ffffff;
  background: linear-gradient(129.36deg, #205768 -9.17%, #33185f 105.44%);
  border-radius: 20px;
  padding: 32px 32px 0 32px;
  position: fixed;
  left: 50%;
  transform: translateX(-210%);
  text-align: start;
  ${(props) =>
    props.$collapsed
      ? '@media (max-width: 1350px){width: 60px;padding: 10px;left: 60px;transform: none;p{display:none;}}'
      : '@media (max-width: 1350px){left: 60px;transform: none}'};
  ${(props) =>
    props.$collapsed
      ? '@media (max-width: 1100px){width: 60px;padding: 10px;left: 40px;transform: none;p{display:none;}}'
      : '@media (max-width: 1100px){left: 40px;transform: none'};
  ${(props) =>
    props.$collapsed
      ? '@media (max-width: 755px){width: 60px;padding: 10px;left: 20px;transform: none;p{display:none;}}'
      : '@media (max-width: 755px){left: 20px;transform: none'};

  @media (max-width: 414px) {
    display: ${(props) => (props.$collapsed ? 'none' : 'flex')};
    left: 50%;
    transform: translateX(-50%);
  }
`;
export const Circle = styled.div<{ $name: CardStates; $currentState: CardStates }>`
  border: 2px solid ${(props) => (props.$name === props.$currentState ? 'white' : '#d0d0d0')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21.5px;
  height: 21.5px;
  border-radius: 50%;
`;
export const ToolsHeader = styled.h1<{
  $name?: CardStates;
  $currentState?: CardStates;
  $collapsed?: boolean;
}>`
  background: ${(props) =>
    props.$name === props.$currentState &&
    'linear-gradient(101.08deg, #26c0f1 9.94%, #6f35ce 86.57%)'};
  display: flex;
  color: ${(props) => (props.$name === props.$currentState ? 'white' : '#d0d0d0')};
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  padding: 8px;
  padding-left: ${(props) => (props.$name === props.$currentState ? '12px' : '0px')};
  gap: 15px;
  border-radius: 10px;
  width: 249px;
  height: 40px;
  cursor: pointer;
  ${(props) =>
    props.$collapsed &&
    '@media (max-width: 1350px) {width:40px;justify-content: center;padding: 0px;}'}
`;
export const ToolsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 48px;
  justify-content: center;
  align-items: space-between;
  @media (max-width: 1350px) {
    margin-bottom: 0px;
  }
`;
export const DepContainer = styled.div`
  margin-bottom: 20px;
`;
export const MainBlock = styled.li``;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding-block: 55px;
  @media (min-width: 768px) {
    padding-block: 105px;
  }
  @media (max-width: 414px) {
    transform: translateX(0px);
    display: block;
  }
`;

export const Department = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;
export const Technology = styled.li`
  padding: 6px 13px 6px 13px;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border: 1.2px solid var(--color-white);
  border-radius: 20px;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    font-weight: var(--fw-700);
    color: var(--color-white);
    background-color: #0c182d;
  }
`;
export const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`;
export const Text = styled.p`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

export const PlusIconContainer = styled.div<{ $collapsed: boolean }>`
  display: none;
  padding: 2px;
  svg {
    transform: ${(props) => !props.$collapsed && 'rotate(45deg)'};
  }
  @media (max-width: 1350px) {
    display: block;
    margin-top: 5px;
    margin-bottom: 26px;
  }
  @media (max-width: 414px) {
    visibility: hidden;
  }
`;

export const ArrowIconContainer = styled.div<{ $collapsed?: boolean }>`
  display: none;
  padding: 2px;
  @media (max-width: 1350px) {
    display: ${(props) => props.$collapsed && 'block'};
    margin-top: 48px;
    margin-bottom: 26px;
  }
`;
export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 20% 0 20%;
  @media (max-width: 414px) {
    margin: 0px;
  }
`;
