import { createGlobalStyle } from 'styled-components';
import normalize from './normalize';

const Global = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800&display=swap');
  ${normalize}

  :root {
    // font
    --ff-primary: 'Raleway', sans-serif;

    // colors
    --color-black: #000000;

    --color-white: #ffffff;
    --color-white-2: #efefef;

    --color-grey: #2d2d2d;
    --color-grey-2: #848e9e;
    --color-grey-3: #d0d0d0;
    --color-grey-4: #a7a7a7;
    --color-grey-5: #797979;
    --color-blue-1: #26C0F1;
    --color-violet-1: #6F35CE;
    --color-violet-2: #9C68F1;
    --color-violet-3: #451299;

    --swiper-pagination-color: var(--color-blue-1);
    --swiper-pagination-bullet-inactive-color: var(--color-grey-2);
   //--swiper-pagination-bullet-inactive-opacity: 1;

    //цвета макета в цвете
    --color-fon: #0C182C;
    --color-grey1: #d0d0d0;

    // gradient
    --gradient-1: linear-gradient(101.08deg, #26c0f1 9.94%, #6f35ce 86.57%);
    --gradient-2: linear-gradient(129.36deg, #205768 -9.17%, #33185F 105.44%);
    --gradient-3: linear-gradient(180deg, #ffffff 0%, #c3f0ff 100%), #d9d9d9;
    --gradient-4: linear-gradient(101.08deg, #6f35ce 9.94%, #6f35ce 86.57%);
    --gradient-5: linear-gradient(101.08deg, #6DD8FA 9.94%, #AE82F5 86.57%);

    // font-weight
    --fw-300: 300;
    --fw-400: 400;
    --fw-500: 500;
    --fw-600: 600;
    --fw-700: 700;
    --fw-800: 800;

    // font-size (MOBILE)
    --fs-250: 14px; // напрвления в карточках
    --fs-300: 12px; // дата в карточках + footer ссылки
    --fs-350: 14px; // header кнопки + ссылки
    --fs-400: 14px; // текст в hero
    --fs-450: 14px; // label в RegistartionForm + label в addProject
    --fs-500: 17px; // заголовок + кнопка в карточке
    --fs-550: 20px; // кнопка далее в RegistrationForm
    --fs-570: 24px;
    --fs-600: 26px; // заголовки секций + в hero цифр в овале
    --fs-700: 30px; // логотип
    --fs-800: 40px;
    --fs-850: 56px; // цифра в слайдере
    --fs-900: 20px;

    // line-height (MOBILE)
    --line-height-250: 19px; // напрвления в карточках
    --line-height-300: 14px; // дата в карточках + footer ссылки
    --line-height-350: 16px; // header кнопки + ссылки
    --line-height-370: 18px;
    --line-height-400: 20px; // мелкий текст в hero
    --line-height-450: 20px; // кнопка в фильтрах
    --line-height-500: 20px; // заголовок + кнопка в карточке
    --line-height-550: 26px; // кнопка далее в RegistrationForm
    --line-height-600: 32px; // заголовки секций + в hero цифр в овале
    --line-height-700: 35px; // логотип
    --line-height-750: 22px;
    --line-height-800: 17px;


    @media (min-width: 768px) {
      // font-size (DESKTOP)
      --fs-250: 16px; // напрвления в карточках
      --fs-300: 12px; // дата в карточках + footer ссылки
      --fs-350: 14px; // header кнопки + ссылки
      --fs-400: 18px; // текст в hero
      --fs-450: 14px;
      --fs-500: 20px; // заголовок + кнопка в карточке
      --fs-600: 30px; // заголовки секций + в hero цифр в овале
      --fs-700: 35px; // логотип
      --fs-800: 46px; // заголовок в hero
      --fs-850: 50px; //заголовок на главной
      --fs-900: 24px; // описание в hero
      --fs-1000: 17px; // описание в разделе God в цифрах
      --fs-1100: 140px; //заголовок в слайдере

      // line-height (DESKTOP)
      --line-height-200: 18px; // дата в проекте
      --line-height-250: 19px; // напрвления в карточках
      --line-height-300: 14px; // дата в карточках + footer ссылки
      --line-height-350: 16px; // header кнопки + ссылки
      --line-height-400: 21px; // текст в hero
      --line-height-450: 22px; // кнопка в фильтрах
      --line-height-500: 23px; // заголовок + кнопка в карточке
      --line-height-550: 26px; // заголовок проекта + кнопка подробнее
      --line-height-600: 35px; // заголовки секций + в hero цифр в овале
      --line-height-650: 17px; // описание проекта
      --line-height-700: 41px; // логотип
      --line-height-800: 28px; // текст в hero-about
      --line-height-900: 54px; // заголовок в разделе GoD в цифрах
      --line-height-950: 164px; //заголовок в слайдере
    }

  }

  body {
    font-family: var(--ff-primary);
    font-weight: var(--fw-400);
    background-color: var(--color-fon);
    color: var(--color-black);
    min-width: 320px;
  }
`;

export default Global;
