import styled from 'styled-components';

export const Input = styled.input`
  z-index: -1;
  opacity: 0;
`;

export const Span = styled.span<{ $open?: boolean; isChangeDepBlock?: boolean }>`
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-weight: var(--fw-600);
  font-size: var(--fs-300);
  line-height: var(--line-height-300);
  color: ${(props) =>
    props.isChangeDepBlock ? (props.$open ? 'white' : 'grey') : props.$open ? 'black' : 'grey'};
`;
