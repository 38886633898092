import { FC } from 'react';
import { Label } from '../style';
import { useProjectStore } from '../../../zustand-store';
import IDepartment from '../../../api/types/interface/IDepartment';

interface Props {
  type: 'checkbox' | 'radio';
  name: 'department' | 'stack' | 'date';
  departmentName?: string;
}

const ProjectDropdownList: FC<Props> = ({ type, name, departmentName }) => {
  const { departments, changeDepartments, changeStack } = useProjectStore();
  const list: IDepartment[] = [];

  switch (name) {
    case 'department':
      list.push(...departments);
      break;
    case 'stack':
      const [value] = departments
        .filter((item) => item.name === departmentName)
        .map((item) => item.stacks || []);
      list.push(...value);
      break;
  }

  const handler = (item: string) => {
    if (name === 'department') {
      changeDepartments(item);
    }
    if (name === 'stack') {
      changeStack(departmentName || '', item);
    }
  };

  return (
    <>
      {list.map((i, index) => (
        <Label key={index}>
          <input type={type} value={i.name} checked={i.isCheck} onChange={() => handler(i.name)} />
          <span>{i.name}</span>
        </Label>
      ))}
    </>
  );
};

export default ProjectDropdownList;
