import { FC } from 'react';
import {
  Card,
  CardImg,
  CardWrapper,
  CardDate,
  CardTitle,
  CardDescription,
  CardRole,
  CardTitleSmall,
  CardRoleItem,
  CardStack,
  SubTitleWrapper,
  SubTitle,
  Footer,
  SubTitleText,
  Text,
  NextLink
} from './style';
import IPortfolioProject from '../../../api/types/interface/IPortfolioProject';
import { ArrowRight } from './icon';

interface ProjectPortfolioProps {
  card?: IPortfolioProject | undefined;
}

const ProjectPortfolio: FC<ProjectPortfolioProps> = ({ card }) => {
  if (card) {
    const stacks =
      card.departments &&
      card.departments
        .map((d) => d.stacks?.map((s) => s.name))
        .flat()
        .join(', ');

    const departments =
      card.departments &&
      card.departments
        .map((d) => d.name)
        .flat()
        .join(', ');

    return (
      <Card>
        <CardImg src={card.img?.url} />
        <CardWrapper>
          <CardDate>
            {card.startDate} - {card.finishedDate}
          </CardDate>
          <CardTitle>{card.title}</CardTitle>
          <CardDescription>{card.description}</CardDescription>

          <CardRole>
            <CardTitleSmall>Роль в команде</CardTitleSmall>
            <CardRoleItem>{card.roleInTeam}</CardRoleItem>
          </CardRole>

          <CardStack>
            <SubTitleWrapper>
              <SubTitle>
                <SubTitleText>Направление</SubTitleText>
                <Text>{departments}</Text>
              </SubTitle>
            </SubTitleWrapper>

            <SubTitleWrapper>
              <SubTitle>
                <SubTitleText>Стек</SubTitleText>
                <Text>
                  <>{stacks}</>
                </Text>
              </SubTitle>
            </SubTitleWrapper>
          </CardStack>
          <Footer>
            <NextLink to="">
              Подробнее
              <ArrowRight />
            </NextLink>
          </Footer>
        </CardWrapper>
      </Card>
    );
  } else return <></>;
};

export default ProjectPortfolio;
