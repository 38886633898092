import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { configureStore } from '@reduxjs/toolkit';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools/build/lib/devtools';
import Global from './lib/styled/global';
import App from './App';

import rootReducer from './services/reducers/RootReducer';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';

const store = configureStore({ reducer: rootReducer, middleware: [thunkMiddleware] });
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Global />
        <App />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </Provider>
  </BrowserRouter>
);
