import styled, { css } from 'styled-components';
import { Menu } from '@headlessui/react';
import TrashBinIcon from '../../UI/Icons/TrashBin';
import { NextButton } from '../../UI/Buttons/NextButton.styled';

export const Wrapper = styled.div``;

export const TopFilter = styled.div`
  display: none;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  padding-bottom: 40px;
  @media (max-width: 1023px) {
    display: flex;
    gap: 10px;
  }
  @media (max-width: 768px) {
    gap: 20px;
  }
`;

export const BottomFilter = styled.div`
  background: var(--gradient-2);
  border-radius: 30px;
  width: 100%;

  display: grid;
  align-items: center;
  padding: 24px;

  gap: 24px;
  grid-template-columns: repeat(4, 192px) 182px 1fr;

  @media (min-width: 1024px) and (max-width: 1200px) {
    grid-template-columns: repeat(4, minmax(163px, 192px)) minmax(150px, 182px) 1fr;
    gap: calc(8px + (16 * ((100vw - 1024px) / 176)));
  }
  @media (max-width: 1023px) {
    display: none;
    /* Кнопка поиск и фильтры */
  }
`;

export const BottomFilterInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  color: var(--color-white);
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  background: var(--color-fon);
  border-radius: 30px;
`;

export const BottomFilterIconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const BottomFilterIcon = styled(TrashBinIcon)`
  color: var(--color-blue-1);
`;

export const BottomFilterButton = styled.button`
  flex-shrink: 0;
  width: 179px;
  padding: 12px 16px;
  background-color: var(--color-white-2);
  border-radius: 15px;
  text-align: center;
  font-size: var(--fs-250);
  font-weight: var(--fw-600);
  line-height: var(--line-height-250);
  line-break: 19px;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

  ${({ active }: { active?: boolean }) => {
    return (
      active &&
      css`
        color: var(--color-white);
        background-color: var(--color-grey);
      `
    );
  }}
`;

export const FilterButton = styled(NextButton)`
  max-width: 165px;
  gap: 16px;
  @media (max-width: 767px) {
    max-width: 138px;
  }
`;

export const FilterButtonText = styled.span``;

export const SearchButtonFilter = styled.button`
  position: relative;

  padding: 5px 22px;
  border-radius: 15px;
  font-weight: var(--fw-400);
  font-size: var(--fs-500);
  line-height: var(--line-height-500);
  font-weight: var(--fw-700);
  color: var(--color-white);
  background-color: var(--color-grey);
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    font-weight 0.3s ease-in-out;

  // TODO: временно - только для еще не готовых ссылок
  ${({ wip }: { wip?: boolean }) => {
    return (
      wip &&
      css`
        &:hover,
        &:focus-visible {
          &::after {
            content: 'Скоро';

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            /* min-width: 130px; */
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 15px;
            background-color: var(--color-grey-2);
            color: var(--color-black);
            font-size: var(--fs-350);
            font-weight: var(--fw-700);
            line-height: var(--line-height-350);
            text-align: center;
          }
        }
      `
    );
  }}
`;

export const MenuButton = styled(Menu.Button)`
  padding: 5px 22px;
  border-radius: 15px;
`;

export const MenuItems = styled(Menu.Items)`
  position: absolute;
  left: 0;
  bottom: -140px;
  width: 100%;
  padding: 30px 25px;
  border-radius: 15px;
  border-top-right-radius: 0;
  background-color: var(--color-white-2);
  z-index: 100;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -25px;
    right: 0;
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 25px solid var(--color-white-2);
  }
  background: var(--gradient-2);
  border-radius: 30px;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(4, 192px) 182px 76px;
  align-items: center;
  gap: 24px;

  padding: 24px;
`;
