import React, { FC } from 'react';
import { Section } from './style';

interface UWrapperProps {
  children: JSX.Element | JSX.Element[];
}

const UWrapper: FC<UWrapperProps> = ({ children }) => {
  return <Section>{children}</Section>;
};

export default UWrapper;
