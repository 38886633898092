import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Project from './Project';
import PortfolioProject from '../../../api/types/class/PortfolioProject';
import { ArrowPagination, IconPlus } from './icon';
import { Grid, Section, BlackBtn, BlackBtnText } from './style';
import { useMediaQuery } from '../../../services/useMediaQuery';
import { useQuery } from '@tanstack/react-query';
import { getPortfolioProject } from '../../../api/PortfolioProjects';
import Preloader from '../../../layout/Main/Preloader';
import { Title } from '../style';

interface PaginatedProps {
  itemsPerPage: number;
  data: PortfolioProject[];
}

interface ItemsProps {
  items: PortfolioProject[];
}

const PaginatedItems: FC<PaginatedProps> = ({ itemsPerPage, data }) => {
  const isMobile = useMediaQuery('(max-width: 650px)');
  const Items: FC<ItemsProps> = ({ items }) => {
    return (
      <Grid>
        {items?.map((card: PortfolioProject) => (
          <Project key={card.id} card={card} />
        ))}
      </Grid>
    );
  };

  const [currentItems, setCurrentItems] = React.useState<PortfolioProject[]>([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [itemOffset, setItemOffset] = React.useState(0);

  React.useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items items={currentItems} />
      <ReactPaginate
        breakLabel="..."
        nextLabel={<ArrowPagination />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={isMobile ? 2 : 4}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        previousLabel={<ArrowPagination />}
        containerClassName={data.length < itemsPerPage ? 'pagination hidden' : 'pagination'}
        pageLinkClassName="page-num"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        activeLinkClassName="active"
      />
    </>
  );
};

const PortfolioEdit: FC = () => {
  const { isLoading, isError, data } = useQuery(['getPortfolioProject'], getPortfolioProject);
  if (isLoading) {
    return (
      <Section>
        <Preloader />
      </Section>
    );
  }

  if (isError) {
    return (
      <Section>
        <Title>Ошибка</Title>;
      </Section>
    );
  }
  return (
    <Section>
      <Link to="/profile/projectAdd">
        <BlackBtn>
          <BlackBtnText>Добавить</BlackBtnText>
          <IconPlus />
        </BlackBtn>
      </Link>
      <PaginatedItems itemsPerPage={6} data={data} />
    </Section>
  );
};

export default PortfolioEdit;
