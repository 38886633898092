import { FC } from 'react';
import { Label } from '../style';
import { useFullScreenProjectStore } from '../../../zustand-store';
import IDepartment from '../../../api/types/interface/IDepartment';
import { Input, Span } from './style';

interface Props {
  isChangeDepBlock?: boolean;
}

const ApplyDropdownList: FC<Props> = ({ isChangeDepBlock }) => {
  const { departments, selectDepartment, getProjStat } = useFullScreenProjectStore();
  const list: IDepartment[] = [];
  const stat = getProjStat();

  list.push(...departments);

  return (
    <>
      {list.map((i, index) => {
        if (
          stat?.find((dep) => {
            if (dep.hired < dep.total) {
              return dep.department.id === i.id;
            }
          })
        ) {
          return (
            <Label key={index} isChangeDepBlock={isChangeDepBlock} $open={true}>
              <Input
                type="radio"
                value={i.name}
                checked={i.isCheck ? i.isCheck : false}
                onChange={() => selectDepartment(i.id)}
              />
              <Span $open={true} isChangeDepBlock={isChangeDepBlock}>
                {i.name}
              </Span>
            </Label>
          );
        } else {
          return (
            <Label key={index} isChangeDepBlock={isChangeDepBlock}>
              <Input
                type="radio"
                value={i.name}
                checked={i.isCheck ? i.isCheck : false}
                onChange={() => selectDepartment(i.id)}
                disabled
              />
              <Span isChangeDepBlock={isChangeDepBlock}>{i.name}</Span>
            </Label>
          );
        }
      })}
    </>
  );
};

export default ApplyDropdownList;
