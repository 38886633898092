import styled from 'styled-components';

export const Container = styled.div<{ primary?: boolean }>`
  display: flex;
  justify-content: flex-end;

  background: ${(props) => (props.primary ? '#0C182C' : 'white')};
  color: ${(props) => (props.primary ? 'white' : 'red')};
  background-repeat: no-repeat;

  background-position: center;

  @media (max-width: 768px) {
    background: url(/assets/img/forget-background.webp);
    width: 100%;
    background-position: center;
    position: relative;
    &:after {
      z-index: 1;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(0deg, rgba(12, 24, 44, 0.6), rgba(12, 24, 44, 0.6));
    }
  }
`;
