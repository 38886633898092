import React, { FC } from 'react';
import { ServerDown500 } from './pages/serverDown500';
import Main from './layout/Main';
import { useSelector } from 'react-redux';
import { getUserById } from './api/UserApi';

const App: FC = () => {
  const checkServerOnline = () => {
    return true;
  };

  const LoginStatus = useSelector((store: any) => store.usersReduser);
  console.log(LoginStatus);
  const isServerOnline = checkServerOnline();

  return isServerOnline ? <Main /> : <ServerDown500 />;
};

export default App;
