export const ArrowRightCircled = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.0013 40.3332C32.1265 40.3332 40.3346 32.1251 40.3346 21.9998C40.3346 11.8746 32.1265 3.6665 22.0013 3.6665C11.8761 3.6665 3.66797 11.8746 3.66797 21.9998C3.66797 32.1251 11.8761 40.3332 22.0013 40.3332Z"
        fill="white"
        stroke="#26C0F1"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6654 21.9998H29.332M29.332 21.9998L22.9154 15.5832M29.332 21.9998L22.9154 28.4165"
        stroke="#26C0F1"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
