import React from 'react';
import {
  Rating,
  RatingCaption,
  RatingInfo,
  RatingInfoButton,
  RatingInfoText,
  RatingName,
  RatingPoints,
  RatingPointsValue,
  RatingStar
} from './style';
import { RatingProgress } from '../RatingProgress';
import { IMemberRating } from '../../../api/types/interface/IMemberRating';

interface RatingProps {
  userRating: IMemberRating;
  currentRatingNum: number;
}

export const MemberRating: React.FC<RatingProps> = ({ userRating, currentRatingNum }) => {
  return (
    <Rating>
      <RatingInfo>
        <RatingStar>
          <RatingProgress />
        </RatingStar>
        <RatingPoints>
          <RatingName>{userRating?.name}</RatingName>
          <RatingPointsValue>
            {currentRatingNum}/{userRating?.points}
            <RatingCaption>балла</RatingCaption>
          </RatingPointsValue>
        </RatingPoints>
      </RatingInfo>

      <RatingInfoButton>
        <RatingInfoText>Как повысить рейтинг?</RatingInfoText>
      </RatingInfoButton>
    </Rating>
  );
};
