import { instance } from '.';
import IDashboardRecord from './types/interface/IDashboardRecord';

/**  */
interface IHomepageDashboardApiResponse {
  guild_members: number;
  town: number;
  projects_in_development: number;
}

/** Получение дашбррда со статистикой для главной страницы */
export const getHomepageDashboard = () =>
  instance
    .get('/dashboard/')
    .then((res) => res.data)
    .then((data: IHomepageDashboardApiResponse): IDashboardRecord[] => {
      return [
        {
          amount: data.guild_members.toString(),
          name: 'участников Гильдии',
          imageSrc: '/assets/img/icon-members.svg'
        },
        {
          amount: data.town.toString(),
          name: 'человек из городов со всей России',
          imageSrc: '/assets/img/icon-town.svg'
        },
        {
          amount: data.projects_in_development.toString(),
          name: 'проектов в разработке',
          imageSrc: '/assets/img/icon-project.svg'
        }
      ];
    });
