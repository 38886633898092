/* eslint-disable @typescript-eslint/no-shadow */
import { Menu } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion';

import React, { FC, useState, useCallback } from 'react';
import { Container } from '../../../lib/styled/container';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import getCroppedImg from '../../../services/canvasUtils';
import {
  Fieldset,
  InputText,
  CalendarIconWrapper,
  Label,
  LabelText,
  LabelCoverText,
  LabelRequired,
  MenuButton,
  MenuItems,
  Section,
  Textarea,
  Title,
  TitleText,
  ArrowLink,
  Wrapper,
  WrapperDiv,
  BlackBtn,
  BlackBtnText,
  ButtonsBox,
  WhiteBtn,
  CropDiv,
  CropControls,
  FileInput,
  CoverDiv,
  CoverBox,
  CoverImg,
  InputWrapper,
  InputDate,
  CalendarWrapper,
  CloseIcon,
  LabelDate
} from './style';
import { Link } from 'react-router-dom';
import { ArrowBack, CalendarIcon } from './icon';
import DropdownList from '../../DropdownList';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import IDepartment from '../../../api/types/interface/IDepartment';

interface IProjectForm {
  name?: string;
  date?: string;
  projectId?: string;
  description?: string;
  role?: string;
  projectLink?: string;
  checkedDepartments: () => IDepartment[];
  changeInput: (name: string, value: string) => void;
}

const ProjectForm: FC<IProjectForm> = ({
  date,
  changeInput,
  projectLink,
  checkedDepartments,
  name,
  role,
  description,
  projectId
}) => {
  const [counter, setCounter] = useState(0);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [minDate, setMinDate] = useState(new Date(0));

  const hiddenCoverInput = React.useRef<HTMLInputElement>(null);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [cover, setCover] = useState('');
  const [croppedCover, setCroppedCover] = useState('');
  const [coverCrop, setCoverCrop] = useState(false);

  const [croppedArea, setCroppedArea] = useState<Area>();

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const saveCroppedCover = useCallback(
    async (event: React.FormEvent<HTMLButtonElement>) => {
      event.preventDefault();
      try {
        const croppedCover = await getCroppedImg(cover, croppedArea, 0);
        setCroppedCover(croppedCover);
        setCoverCrop(false);
      } catch (error) {
        console.error(error);
      }
    },
    [croppedArea, cover]
  );

  const handleCoverUpload = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (hiddenCoverInput.current) hiddenCoverInput.current.click();
  };

  const handleChangeCover = (event: React.FormEvent<HTMLInputElement>) => {
    const uploadedCovers = (event.target as HTMLInputElement).files;
    if (uploadedCovers) {
      setCroppedCover('');
      setCover(URL.createObjectURL(uploadedCovers[0]));
    }
  };

  const handleCoverDelete = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (hiddenCoverInput.current?.value) {
      hiddenCoverInput.current.value = '';
      setCover('');
      setCroppedCover('');
    }
  };

  const handleCoverMin = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (cover) {
      setCoverCrop(true);
    }
  };

  return (
    <Section>
      <Container>
        {projectId ? (
          <Title>
            <ArrowLink to="/profile">
              <ArrowBack />
            </ArrowLink>
            <TitleText>Редактировать проект в портфолио</TitleText>
          </Title>
        ) : (
          <Title>
            <ArrowLink to="/profile">
              <ArrowBack />
            </ArrowLink>
            <TitleText>Добавить проект в портфолио</TitleText>
          </Title>
        )}
        <Wrapper>
          <WrapperDiv>
            <Fieldset>
              <Label wfill>
                <LabelText>
                  Название проекта<LabelRequired>*</LabelRequired>
                </LabelText>
                <InputText
                  value={name}
                  placeholder="Впиши"
                  onChange={(e) => changeInput('name', e.target.value)}
                />
              </Label>
              <LabelDate>
                <Label wfill>
                  <LabelText>
                    Сроки выполнения<LabelRequired>*</LabelRequired>
                  </LabelText>
                  <InputWrapper>
                    <InputDate
                      onClick={() => {
                        setCalendarOpen(true);
                      }}
                    >
                      {date == '' ? 'Выбери' : date}
                    </InputDate>
                    <CalendarIconWrapper
                      onClick={() => {
                        setCalendarOpen(true);
                      }}
                    >
                      <CalendarIcon />
                    </CalendarIconWrapper>
                  </InputWrapper>
                </Label>
                <AnimatePresence>
                  {calendarOpen && (
                    <motion.div
                      initial={{
                        opacity: 0,
                        scale: 0.75
                      }}
                      animate={{
                        opacity: 1,
                        scale: 1
                      }}
                      exit={{
                        opacity: 0
                      }}
                      transition={{ type: 'Inertia', duration: 0.2 }}
                      style={{
                        position: 'relative',
                        zIndex: '150',
                        transformOrigin: 'top left'
                      }}
                    >
                      <CalendarWrapper>
                        <CloseIcon onClick={() => setCalendarOpen(false)} />
                        <Calendar
                          onClickDay={(value) => {
                            setCounter((prev) => prev + 1);
                            const day =
                              value.getDate() < 10 ? '0' + value.getDate() : value.getDate();
                            const month =
                              value.getMonth() + 1 < 10
                                ? '0' + (value.getMonth() + 1)
                                : value.getMonth() + 1;
                            const year = value.getFullYear();
                            const stringDate = `${day}.${month}.${year}`;
                            if (counter === 1) {
                              changeInput('date', `${date} - ${stringDate}`);
                              setCounter(0);
                              setCalendarOpen(false);
                              setMinDate(new Date(0));
                            } else {
                              changeInput('date', stringDate);
                              setMinDate(new Date(Date.parse(`${month}.${day}.${year}`)));
                            }
                          }}
                          maxDate={new Date()}
                          minDate={minDate}
                        />
                      </CalendarWrapper>
                    </motion.div>
                  )}
                </AnimatePresence>
              </LabelDate>
              <Label wfill>
                <LabelText>
                  Короткое описание<LabelRequired>*</LabelRequired>
                </LabelText>
                <Textarea
                  value={description}
                  placeholder="Начни вводить...."
                  onChange={(e) => changeInput('description', e.target.value)}
                />
              </Label>
              <Label wfill>
                <LabelText>Роль в команде</LabelText>
                <InputText
                  value={role}
                  placeholder="Впиши"
                  onChange={(e) => changeInput('role', e.target.value)}
                />
              </Label>
              <Label wfill>
                <LabelText>Направление (одно или несколько)</LabelText>
                <Menu>
                  <MenuButton>
                    {checkedDepartments()
                      .map((item) => item.name)
                      .join(', ') || 'Выбери'}
                  </MenuButton>
                  <MenuItems>
                    <DropdownList page="addProject" type="checkbox" name="department" />
                  </MenuItems>
                </Menu>
              </Label>
              {checkedDepartments().map((item, j) => (
                <Label key={j} wfill>
                  <LabelText>{item.name} Стек (один или несколько)</LabelText>
                  <Menu>
                    <MenuButton>
                      {(item.stacks || [])
                        .filter((i) => i.isCheck)
                        .map((i) => i.name)
                        .join(', ') || 'Выбери'}
                    </MenuButton>
                    <MenuItems>
                      <DropdownList
                        page="addProject"
                        type="checkbox"
                        name="stack"
                        departmentName={item.name}
                      />
                    </MenuItems>
                  </Menu>
                </Label>
              ))}
              <Label wfill>
                <LabelText>Ссылка на проект (если есть на другом источнике)</LabelText>
                <InputText
                  value={projectLink}
                  placeholder="Введи"
                  onChange={(e) => changeInput('projectLink', e.target.value)}
                />
              </Label>
            </Fieldset>
            <Fieldset>
              <LabelCoverText>Фото проeкта (для обложки)</LabelCoverText>
              <CoverDiv>
                {cover && (
                  <CoverBox>
                    {croppedCover ? (
                      <CoverImg src={croppedCover} alt="Обложка" />
                    ) : cover ? (
                      <CoverImg src={cover} alt="Обложка" />
                    ) : null}
                  </CoverBox>
                )}
                <ButtonsBox deskGap="26px">
                  <FileInput
                    type="file"
                    accept="image/*"
                    ref={hiddenCoverInput}
                    onChange={handleChangeCover}
                  />
                  <BlackBtn onClick={handleCoverUpload}>
                    <BlackBtnText>Загрузить</BlackBtnText>
                  </BlackBtn>
                  {cover && (
                    <>
                      <WhiteBtn onClick={handleCoverMin}>Изменить</WhiteBtn>
                      <WhiteBtn onClick={handleCoverDelete}>Удалить</WhiteBtn>
                    </>
                  )}
                </ButtonsBox>
                {coverCrop && (
                  <>
                    <CropDiv>
                      <Cropper
                        image={cover}
                        crop={crop}
                        zoom={zoom}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                        aspect={6 / 3}
                      />
                    </CropDiv>
                    <CropControls>
                      <WhiteBtn onClick={saveCroppedCover}>Сохранить</WhiteBtn>
                      <WhiteBtn onClick={() => setCoverCrop(false)}>Отмена</WhiteBtn>
                    </CropControls>
                  </>
                )}
              </CoverDiv>
            </Fieldset>
          </WrapperDiv>
          <ButtonsBox deskGap="25px" marginBottom="128px">
            {projectId ? (
              <>
                <WhiteBtn
                  maxWidth="384px"
                  height="48px"
                  display="block"
                  type="submit"
                  onClick={() => {}}
                >
                  <Link to="/profile">Удалить проект</Link>
                </WhiteBtn>
                <BlackBtn
                  maxWidth="384px"
                  width="100%"
                  display="block"
                  type="submit"
                  onClick={() => {}}
                >
                  <Link to="/profile">
                    <BlackBtnText>Сохранить</BlackBtnText>
                  </Link>
                </BlackBtn>
              </>
            ) : (
              <BlackBtn
                maxWidth="384px"
                width="100%"
                margin="0 auto"
                display="block"
                type="submit"
                onClick={() => {}}
              >
                <Link to="/profile">
                  <BlackBtnText>Добавить</BlackBtnText>
                </Link>
              </BlackBtn>
            )}
          </ButtonsBox>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default ProjectForm;
