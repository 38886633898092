import React from 'react';

export const ArrowUp = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="m7 14 5-5 5 5Z" fill="white" />
    </svg>
  );
};

export const ArrowDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="m12 15-5-5h10Z" fill="white" />
    </svg>
  );
};

export const ArrorRight = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5991 18.706C12.5096 18.6131 12.4386 18.5027 12.3902 18.3812C12.3417 18.2597 12.3168 18.1294 12.3168 17.9978C12.3168 17.8663 12.3417 17.736 12.3902 17.6145C12.4386 17.493 12.5096 17.3826 12.5991 17.2897L16.7175 13.0002L4.95981 13.0002C4.70525 13.0002 4.46112 12.8948 4.28112 12.7074C4.10112 12.5199 4 12.2656 4 12.0005C4 11.7353 4.10112 11.4811 4.28112 11.2936C4.46112 11.1061 4.70525 11.0008 4.95981 11.0008L16.7175 11.0008L12.5991 6.70956C12.4188 6.52175 12.3175 6.26703 12.3175 6.00143C12.3175 5.73584 12.4188 5.48112 12.5991 5.29331C12.7794 5.10551 13.024 5 13.279 5C13.534 5 13.7785 5.10551 13.9588 5.29331L19.7177 11.2915C19.8072 11.3844 19.8782 11.4948 19.9266 11.6163C19.9751 11.7378 20 11.8681 20 11.9996C20 12.1312 19.9751 12.2615 19.9266 12.383C19.8782 12.5045 19.8072 12.6149 19.7177 12.7078L13.9588 18.706C13.8697 18.7992 13.7637 18.8731 13.647 18.9236C13.5304 18.974 13.4053 19 13.279 19C13.1526 19 13.0276 18.974 12.9109 18.9236C12.7942 18.8731 12.6883 18.7992 12.5991 18.706Z"
        fill="white"
      />
    </svg>
  );
};
