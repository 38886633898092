import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { NavProjects, NavProject } from './style';

import ProjectsInDev from '../../Projects/ProjectsInDev';
import ProjectsDone from '../../Projects/ProjectsDone';
import PortfolioEdit from '../../Projects/ProjectsEdit';

const ProfileProjects = () => {
  const [selectedProjects, setSelectedProjects] = React.useState('dev');
  const projects = [
    {
      id: 0,
      dataName: 'dev',
      text: 'Проекты в разработке'
    },
    {
      id: 1,
      dataName: 'done',
      text: 'Выполненные проекты'
    },
    {
      id: 2,
      dataName: 'portfolio',
      text: 'Портфолио'
    }
  ];

  const navProjectsOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLParagraphElement;
    if (!target.dataset.name) return;
    setSelectedProjects(target.dataset.name);
  };

  return (
    <>
      <NavProjects onClick={navProjectsOnClick}>
        {projects.map((item) => (
          <NavProject key={item.id} onClick={() => setSelectedProjects(item.dataName)}>
            {item.text}
            {item.dataName === selectedProjects ? (
              <motion.div className="active" layoutId="active" />
            ) : null}
          </NavProject>
        ))}
      </NavProjects>
      {selectedProjects === 'dev' && (
        <AnimatePresence>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <ProjectsInDev />
          </motion.div>
        </AnimatePresence>
      )}
      {selectedProjects === 'done' && (
        <AnimatePresence>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <ProjectsDone />
          </motion.div>
        </AnimatePresence>
      )}
      {selectedProjects === 'portfolio' && (
        <AnimatePresence>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <PortfolioEdit />
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
};

export default ProfileProjects;
