import styled from 'styled-components';

export const Section = styled.div`
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  align-items: center;
  height: 100vh;

  @media (max-width: 768px) {
    padding-block: 10%;
  }
`;

export const Card = styled.div`
  width: 792px;
  height: 461px;

  background: linear-gradient(129.36deg, #205768 -9.17%, #33185f 105.44%);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: 500px;
    width: 90%;
  }
`;

export const CardImage = styled.img`
  width: 180px;
  height: 117px;
  @media (max-width: 768px) {
    width: 180px;
    height: auto;
  }
`;

export const CardDescription = styled.p`
  margin-top: 63px;
  max-width: 567px;
  line-height: 54px;
  font-size: 46px;

  font-style: normal;
  font-weight: var(--fw-700);
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--color-white);

  @media (max-width: 768px) {
    max-width: 75%;
    line-height: 3rem;
    font-size: 2rem;
  }
`;

export const CardLinks = styled.div`
  margin-top: 34px;
  gap: 20px;

  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
