import styled from 'styled-components';

export const Section = styled.section`
  background-image: url('/assets/img/Hero.webp');
  background-size: auto;
  background-repeat: no-repeat;
  background-position: initial;
  max-width: 1920px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    background-size: cover;
    background-position: center;
  }
`;

export const Wrapper = styled.div`
  padding: 185px 105px 40px;

  @media (max-width: 768px) {
    padding: 56px 0 40px;
  }
`;

export const Greetings = styled.div`
  margin: 0 0 145px auto;
  max-width: 483px;

  @media (max-width: 768px) {
    margin: 0 auto 70px;
    text-align: center;
    padding: 0 15px;
  }
`;

export const Title = styled.h1`
  margin: 0 0 37px;
  font-size: var(--fs-850);
  font-weight: var(--fw-700);
  line-height: var(--line-height-600);
  color: var(--color-white);

  @media (max-width: 768px) {
    font-size: var(--fs-600);
    margin: 0px 0 13px;
  }
`;

export const TitleSpan = styled.span`
  display: block;
  padding-top: 20px;
  font-size: var(--fs-850);
  font-weight: var(--fw-700);
  line-height: var(--line-height-600);
  color: var(--color-white);
  @media (max-width: 768px) {
    display: inline;
    font-size: var(--fs-600);
  }
`;

export const Descr = styled.p`
  font-size: var(--fs-500);
  font-weight: var(--fw-400);
  line-height: var(--line-height-550);
  color: var(--color-white);
  padding-right: 50px;

  @media (max-width: 768px) {
    font-size: var(--fs-350);
    line-height: var(--line-height-400);
    padding-right: 0;
  }
`;

export const DescrSpan = styled.span`
  display: block;
  font-weight: var(--fw-700);
`;

export const Members = styled.ul`
  max-width: 1920px;
  margin: 0 auto;
  padding: 35px 70px;
  background: var(--gradient-2);
  border-radius: 70px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    border-radius: 125px;
    max-width: 224px;
    flex-direction: column;
    align-items: center;
    padding: 45px 35px;
    gap: 45px;
  }
`;

export const MembersItem = styled.li`
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
`;

export const MembersIcon = styled.img`
  display: block;
  width: 69px;
  height: 69px;
  background-color: var(--color-white);
  border-radius: 50%;
  padding: 10px;
`;

export const MembersDescr = styled.div`
  max-width: 100px;
  text-align: center;

  @media (min-width: 1017px) {
    text-align: left;
  }
  @media (max-width: 768px) {
    max-width: auto;
  }
`;

export const MembersAmount = styled.p`
  font-size: var(--fs-600);
  font-weight: var(--fw-700);
  line-height: var(--line-height-600);
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
  color: var(--color-blue-1);
`;

export const MembersName = styled.p`
  font-size: var(--fs-250);
  font-weight: var(--fw-400);
  font-feature-settings: 'pnum' on, 'lnum' on;
  line-height: var(--line-height-350);
  color: var(--color-white);

  @media (max-width: 768px) {
    font-size: var(--fs-350);
    line-height: var(--line-height-400);
  }
`;
