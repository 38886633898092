import React from 'react';

export const GoDWhiteIcon = () => {
  return (
    <svg
      width="180"
      height="19"
      viewBox="0 0 180 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.79065 14.7191C5.82251 14.7191 4.92254 14.5295 4.09075 14.1502C3.25897 13.7582 2.53627 13.2335 1.92265 12.5761C1.30904 11.9186 0.831786 11.1853 0.49089 10.3761C0.16363 9.56692 0 8.73245 0 7.8727C0 6.96237 0.16363 6.10262 0.49089 5.29344C0.831786 4.47162 1.30222 3.74462 1.9022 3.11245C2.50218 2.48028 3.21124 1.98086 4.02939 1.6142C4.84754 1.24755 5.74069 1.06422 6.70883 1.06422C8.1406 1.06422 9.30646 1.33605 10.2064 1.87972C11.1064 2.42338 11.7882 3.13142 12.2518 4.00381L11.1473 4.68656C10.6837 3.85209 10.0633 3.23888 9.28601 2.84694C8.50877 2.45499 7.64289 2.25902 6.68838 2.25902C5.88387 2.25902 5.15435 2.41706 4.49983 2.73315C3.85894 3.04923 3.30669 3.47279 2.84307 4.00381C2.37945 4.53484 2.02492 5.1354 1.77948 5.8055C1.54767 6.4756 1.43176 7.17099 1.43176 7.89166C1.43176 8.65027 1.56812 9.37094 1.84084 10.0537C2.11356 10.7238 2.50218 11.3244 3.0067 11.8554C3.51123 12.3738 4.09075 12.7847 4.74527 13.0881C5.41343 13.3789 6.12931 13.5243 6.89292 13.5243C7.77925 13.5243 8.61785 13.322 9.40873 12.9174C10.1996 12.5002 10.9564 11.8617 11.6791 11.0019V12.4054C10.3019 13.9479 8.6724 14.7191 6.79065 14.7191ZM11.4541 9.02957H8.22241V8.00546H12.6609V14.6243H11.4541V9.02957Z"
        fill="white"
      />
      <path
        d="M15.2139 10.5468V4.74345H16.6048V10.3192C16.6048 11.4445 16.8093 12.2853 17.2184 12.8416C17.6411 13.3979 18.2615 13.676 19.0797 13.676C19.6251 13.676 20.1501 13.5686 20.6546 13.3536C21.1728 13.126 21.6296 12.81 22.025 12.4054C22.4205 11.9881 22.7205 11.5077 22.925 10.964V4.74345H24.3159V13.0691C24.3159 13.2462 24.3568 13.3789 24.4386 13.4674C24.5204 13.5433 24.6431 13.5875 24.8067 13.6002V14.6243C24.6431 14.6369 24.5136 14.6433 24.4181 14.6433C24.3227 14.6559 24.234 14.6622 24.1522 14.6622C23.8795 14.6369 23.6409 14.5358 23.4363 14.3588C23.2454 14.1818 23.1432 13.9668 23.1295 13.714L23.0886 12.3864C22.625 13.145 21.9909 13.7392 21.1864 14.1691C20.3955 14.599 19.5365 14.8139 18.6093 14.8139C17.4911 14.8139 16.6457 14.4536 16.073 13.7329C15.5003 13.0122 15.2139 11.9502 15.2139 10.5468Z"
        fill="white"
      />
      <path
        d="M27.4606 14.6243V4.74345H28.8515V14.6243H27.4606ZM27.4606 2.67625V0.779739H28.8515V2.67625H27.4606Z"
        fill="white"
      />
      <path
        d="M31.9763 0.779739H33.3671V12.3864C33.3671 12.8795 33.4626 13.2019 33.6535 13.3536C33.8444 13.5053 34.0762 13.5812 34.3489 13.5812C34.5943 13.5812 34.8398 13.5559 35.0852 13.5053C35.3307 13.4548 35.542 13.4042 35.7193 13.3536L35.9647 14.3967C35.7057 14.4979 35.3852 14.58 35.0034 14.6433C34.6353 14.7065 34.3148 14.7381 34.0421 14.7381C33.4148 14.7381 32.9103 14.5674 32.5285 14.226C32.1604 13.8846 31.9763 13.4168 31.9763 12.8226V0.779739Z"
        fill="white"
      />
      <path
        d="M36.7719 9.71232C36.7719 8.7767 36.9764 7.92327 37.3855 7.15202C37.8082 6.36813 38.3877 5.74228 39.1241 5.27448C39.8604 4.80667 40.699 4.57277 41.6399 4.57277C42.5262 4.57277 43.3171 4.79402 44.0125 5.23654C44.7216 5.66642 45.2738 6.19112 45.6693 6.81065V0.779739H47.0601V13.0691C47.0601 13.2462 47.101 13.3789 47.1828 13.4674C47.2647 13.5433 47.3874 13.5875 47.551 13.6002V14.6243C47.2783 14.6622 47.0669 14.6749 46.9169 14.6622C46.6306 14.6496 46.3783 14.5421 46.1602 14.3398C45.942 14.1375 45.8329 13.9163 45.8329 13.676V12.7088C45.4102 13.3536 44.8375 13.8657 44.1148 14.245C43.3921 14.6243 42.6421 14.8139 41.8649 14.8139C41.1285 14.8139 40.4467 14.6749 39.8195 14.3967C39.2059 14.1185 38.6673 13.7392 38.2036 13.2588C37.7537 12.7783 37.3991 12.2347 37.14 11.6278C36.8946 11.0083 36.7719 10.3698 36.7719 9.71232ZM45.6693 11.2295V8.17614C45.492 7.72098 45.2056 7.31007 44.8102 6.94341C44.4148 6.5641 43.9648 6.26698 43.4603 6.05205C42.9694 5.82446 42.4785 5.71067 41.9876 5.71067C41.4149 5.71067 40.8967 5.82446 40.4331 6.05205C39.9695 6.26698 39.5672 6.5641 39.2263 6.94341C38.8991 7.32271 38.6468 7.75259 38.4695 8.23304C38.2923 8.71349 38.2036 9.20658 38.2036 9.71232C38.2036 10.2433 38.2991 10.7491 38.49 11.2295C38.6945 11.71 38.9809 12.1399 39.349 12.5192C39.7172 12.8858 40.1399 13.1703 40.6172 13.3726C41.0944 13.5749 41.6126 13.676 42.1717 13.676C42.5262 13.676 42.8944 13.6128 43.2762 13.4864C43.658 13.3599 44.0193 13.1893 44.3602 12.9743C44.7148 12.7467 45.0079 12.4812 45.2397 12.1778C45.4715 11.8743 45.6147 11.5583 45.6693 11.2295Z"
        fill="white"
      />
      <path
        d="M59.206 14.8139C58.4424 14.8139 57.7333 14.6812 57.0788 14.4157C56.4379 14.1375 55.8788 13.7645 55.4016 13.2967C54.938 12.8163 54.5766 12.2726 54.3175 11.6657C54.0585 11.0462 53.9289 10.3951 53.9289 9.71232C53.9289 9.00428 54.0585 8.34683 54.3175 7.73994C54.5766 7.12042 54.9448 6.57675 55.422 6.10894C55.8993 5.62849 56.4584 5.25551 57.0993 4.99C57.7538 4.71184 58.4628 4.57277 59.2264 4.57277C59.9901 4.57277 60.6923 4.71184 61.3332 4.99C61.9741 5.25551 62.5331 5.62849 63.0104 6.10894C63.4876 6.57675 63.8558 7.12042 64.1149 7.73994C64.374 8.34683 64.5035 9.00428 64.5035 9.71232C64.5035 10.3951 64.374 11.0462 64.1149 11.6657C63.8558 12.2726 63.4876 12.8163 63.0104 13.2967C62.5468 13.7645 61.9877 14.1375 61.3332 14.4157C60.6923 14.6812 59.9832 14.8139 59.206 14.8139ZM55.3402 9.73128C55.3402 10.4646 55.5107 11.1347 55.8516 11.7416C56.2061 12.3358 56.6765 12.81 57.2629 13.164C57.8492 13.5053 58.4969 13.676 59.206 13.676C59.9151 13.676 60.5628 13.499 61.1491 13.145C61.7354 12.791 62.2059 12.3105 62.5604 11.7037C62.9149 11.0841 63.0922 10.414 63.0922 9.69335C63.0922 8.96003 62.9149 8.28993 62.5604 7.68305C62.2059 7.07616 61.7354 6.59571 61.1491 6.2417C60.5628 5.88768 59.9151 5.71067 59.206 5.71067C58.4969 5.71067 57.8492 5.894 57.2629 6.26066C56.6902 6.62732 56.2266 7.11409 55.872 7.72098C55.5175 8.31522 55.3402 8.98532 55.3402 9.73128Z"
        fill="white"
      />
      <path
        d="M67.1612 14.6243V5.76757H65.6886V4.74345H67.1612V4.47794C67.1612 3.66876 67.2839 2.97337 67.5294 2.39177C67.7885 1.81018 68.1498 1.36766 68.6134 1.06422C69.0907 0.748131 69.6498 0.590088 70.2907 0.590088C70.7134 0.590088 71.1224 0.646983 71.5179 0.760774C71.9133 0.874564 72.2542 1.02629 72.5406 1.21594L72.111 2.14523C71.9338 2.00615 71.702 1.89868 71.4156 1.82282C71.1293 1.74696 70.8429 1.70903 70.5566 1.70903C69.9157 1.70903 69.418 1.94293 69.0634 2.41074C68.7225 2.87855 68.5521 3.55497 68.5521 4.44001V4.74345H71.4974V5.76757H68.5521V14.6243H67.1612Z"
        fill="white"
      />
      <path
        d="M78.4415 14.6243V1.15904H83.3708C84.9117 1.15904 86.1935 1.46248 87.2161 2.06937C88.2525 2.66361 89.0297 3.47279 89.5479 4.4969C90.066 5.50838 90.3251 6.63364 90.3251 7.8727C90.3251 9.23819 90.0388 10.4267 89.4661 11.4381C88.907 12.4496 88.1025 13.2335 87.0525 13.7898C86.0162 14.3461 84.789 14.6243 83.3708 14.6243H78.4415ZM88.8934 7.8727C88.8934 6.79801 88.6752 5.84343 88.2388 5.00896C87.8161 4.1745 87.1957 3.52336 86.3775 3.05556C85.5594 2.57511 84.5572 2.33488 83.3708 2.33488H79.8732V13.4484H83.3708C84.5708 13.4484 85.5798 13.2019 86.398 12.7088C87.2161 12.2157 87.8366 11.5519 88.2593 10.7175C88.682 9.87036 88.8934 8.9221 88.8934 7.8727Z"
        fill="white"
      />
      <path
        d="M97.3785 14.8139C96.6149 14.8139 95.9058 14.6812 95.2513 14.4157C94.5968 14.1375 94.0309 13.7645 93.5536 13.2967C93.0764 12.8163 92.7014 12.2663 92.4287 11.6468C92.1696 11.0272 92.04 10.3635 92.04 9.65542C92.04 8.73245 92.265 7.88534 92.715 7.11409C93.1786 6.34284 93.8127 5.72964 94.6172 5.27448C95.4217 4.80667 96.3353 4.57277 97.358 4.57277C98.408 4.57277 99.3216 4.80667 100.099 5.27448C100.89 5.74228 101.51 6.36181 101.96 7.13306C102.41 7.89166 102.635 8.72613 102.635 9.63646C102.635 9.7376 102.635 9.83875 102.635 9.9399C102.635 10.0284 102.628 10.0979 102.615 10.1485H93.4923C93.5604 10.8565 93.7718 11.4887 94.1263 12.045C94.4945 12.5887 94.9649 13.0249 95.5376 13.3536C96.124 13.6697 96.758 13.8277 97.4398 13.8277C98.1353 13.8277 98.7898 13.6634 99.4034 13.3347C100.031 13.0059 100.467 12.5761 100.712 12.045L101.919 12.3485C101.701 12.8163 101.367 13.2398 100.917 13.6191C100.467 13.9984 99.9352 14.2956 99.3216 14.5105C98.7216 14.7128 98.0739 14.8139 97.3785 14.8139ZM93.4514 9.21922H101.326C101.272 8.49855 101.06 7.86638 100.692 7.32271C100.337 6.77904 99.867 6.35549 99.2807 6.05205C98.708 5.73596 98.0739 5.57792 97.3785 5.57792C96.683 5.57792 96.049 5.73596 95.4763 6.05205C94.9036 6.35549 94.4331 6.78536 94.065 7.34168C93.7104 7.88534 93.5059 8.51119 93.4514 9.21922Z"
        fill="white"
      />
      <path
        d="M106.912 14.6243L102.596 4.74345H104.049L107.689 13.3347L111.351 4.74345H112.721L108.405 14.6243H106.912Z"
        fill="white"
      />
      <path
        d="M118.131 14.8139C117.367 14.8139 116.658 14.6812 116.004 14.4157C115.349 14.1375 114.783 13.7645 114.306 13.2967C113.829 12.8163 113.454 12.2663 113.181 11.6468C112.922 11.0272 112.792 10.3635 112.792 9.65542C112.792 8.73245 113.017 7.88534 113.467 7.11409C113.931 6.34284 114.565 5.72964 115.37 5.27448C116.174 4.80667 117.088 4.57277 118.11 4.57277C119.16 4.57277 120.074 4.80667 120.851 5.27448C121.642 5.74228 122.263 6.36181 122.713 7.13306C123.163 7.89166 123.388 8.72613 123.388 9.63646C123.388 9.7376 123.388 9.83875 123.388 9.9399C123.388 10.0284 123.381 10.0979 123.367 10.1485H114.245C114.313 10.8565 114.524 11.4887 114.879 12.045C115.247 12.5887 115.717 13.0249 116.29 13.3536C116.876 13.6697 117.51 13.8277 118.192 13.8277C118.888 13.8277 119.542 13.6634 120.156 13.3347C120.783 13.0059 121.219 12.5761 121.465 12.045L122.672 12.3485C122.453 12.8163 122.119 13.2398 121.669 13.6191C121.219 13.9984 120.688 14.2956 120.074 14.5105C119.474 14.7128 118.826 14.8139 118.131 14.8139ZM114.204 9.21922H122.078C122.024 8.49855 121.813 7.86638 121.444 7.32271C121.09 6.77904 120.619 6.35549 120.033 6.05205C119.46 5.73596 118.826 5.57792 118.131 5.57792C117.435 5.57792 116.801 5.73596 116.229 6.05205C115.656 6.35549 115.186 6.78536 114.817 7.34168C114.463 7.88534 114.258 8.51119 114.204 9.21922Z"
        fill="white"
      />
      <path
        d="M125.215 0.779739H126.606V12.3864C126.606 12.8795 126.701 13.2019 126.892 13.3536C127.083 13.5053 127.315 13.5812 127.588 13.5812C127.833 13.5812 128.079 13.5559 128.324 13.5053C128.569 13.4548 128.781 13.4042 128.958 13.3536L129.204 14.3967C128.944 14.4979 128.624 14.58 128.242 14.6433C127.874 14.7065 127.554 14.7381 127.281 14.7381C126.654 14.7381 126.149 14.5674 125.767 14.226C125.399 13.8846 125.215 13.4168 125.215 12.8226V0.779739Z"
        fill="white"
      />
      <path
        d="M134.888 14.8139C134.124 14.8139 133.415 14.6812 132.76 14.4157C132.119 14.1375 131.56 13.7645 131.083 13.2967C130.619 12.8163 130.258 12.2726 129.999 11.6657C129.74 11.0462 129.61 10.3951 129.61 9.71232C129.61 9.00428 129.74 8.34683 129.999 7.73994C130.258 7.12042 130.626 6.57675 131.104 6.10894C131.581 5.62849 132.14 5.25551 132.781 4.99C133.435 4.71184 134.144 4.57277 134.908 4.57277C135.672 4.57277 136.374 4.71184 137.015 4.99C137.656 5.25551 138.215 5.62849 138.692 6.10894C139.169 6.57675 139.537 7.12042 139.796 7.73994C140.056 8.34683 140.185 9.00428 140.185 9.71232C140.185 10.3951 140.056 11.0462 139.796 11.6657C139.537 12.2726 139.169 12.8163 138.692 13.2967C138.228 13.7645 137.669 14.1375 137.015 14.4157C136.374 14.6812 135.665 14.8139 134.888 14.8139ZM131.022 9.73128C131.022 10.4646 131.192 11.1347 131.533 11.7416C131.888 12.3358 132.358 12.81 132.944 13.164C133.531 13.5053 134.178 13.676 134.888 13.676C135.597 13.676 136.244 13.499 136.831 13.145C137.417 12.791 137.887 12.3105 138.242 11.7037C138.596 11.0841 138.774 10.414 138.774 9.69335C138.774 8.96003 138.596 8.28993 138.242 7.68305C137.887 7.07616 137.417 6.59571 136.831 6.2417C136.244 5.88768 135.597 5.71067 134.888 5.71067C134.178 5.71067 133.531 5.894 132.944 6.26066C132.372 6.62732 131.908 7.11409 131.554 7.72098C131.199 8.31522 131.022 8.98532 131.022 9.73128Z"
        fill="white"
      />
      <path
        d="M147.192 14.8139C146.306 14.8139 145.508 14.599 144.799 14.1691C144.104 13.7392 143.552 13.2082 143.143 12.5761V18.6639H141.752V4.74345H142.999V6.65893C143.422 6.0394 143.988 5.53999 144.697 5.16068C145.406 4.76874 146.163 4.57277 146.967 4.57277C147.704 4.57277 148.379 4.71816 148.992 5.00896C149.606 5.28712 150.138 5.66642 150.588 6.14687C151.038 6.62732 151.385 7.17731 151.631 7.79684C151.89 8.40372 152.019 9.03589 152.019 9.69335C152.019 10.6163 151.815 11.4698 151.406 12.2536C151.01 13.0249 150.451 13.6444 149.729 14.1122C149.006 14.58 148.161 14.8139 147.192 14.8139ZM146.804 13.676C147.39 13.676 147.915 13.5686 148.379 13.3536C148.842 13.126 149.238 12.8226 149.565 12.4433C149.906 12.064 150.165 11.6404 150.342 11.1726C150.52 10.6922 150.608 10.1991 150.608 9.69335C150.608 9.16233 150.506 8.65659 150.301 8.17614C150.11 7.69569 149.831 7.27214 149.463 6.90548C149.095 6.53882 148.672 6.24802 148.195 6.03308C147.717 5.81814 147.199 5.71067 146.64 5.71067C146.286 5.71067 145.917 5.77389 145.536 5.90032C145.154 6.02676 144.786 6.21009 144.431 6.45031C144.09 6.6779 143.804 6.93709 143.572 7.22788C143.34 7.51868 143.197 7.82845 143.143 8.15718V11.1916C143.361 11.6594 143.654 12.083 144.022 12.4623C144.39 12.8289 144.813 13.126 145.29 13.3536C145.781 13.5686 146.286 13.676 146.804 13.676Z"
        fill="white"
      />
      <path
        d="M158.978 14.8139C158.214 14.8139 157.505 14.6812 156.85 14.4157C156.196 14.1375 155.63 13.7645 155.153 13.2967C154.675 12.8163 154.3 12.2663 154.028 11.6468C153.769 11.0272 153.639 10.3635 153.639 9.65542C153.639 8.73245 153.864 7.88534 154.314 7.11409C154.778 6.34284 155.412 5.72964 156.216 5.27448C157.021 4.80667 157.934 4.57277 158.957 4.57277C160.007 4.57277 160.921 4.80667 161.698 5.27448C162.489 5.74228 163.109 6.36181 163.559 7.13306C164.009 7.89166 164.234 8.72613 164.234 9.63646C164.234 9.7376 164.234 9.83875 164.234 9.9399C164.234 10.0284 164.227 10.0979 164.214 10.1485H155.091C155.16 10.8565 155.371 11.4887 155.725 12.045C156.094 12.5887 156.564 13.0249 157.137 13.3536C157.723 13.6697 158.357 13.8277 159.039 13.8277C159.734 13.8277 160.389 13.6634 161.003 13.3347C161.63 13.0059 162.066 12.5761 162.312 12.045L163.518 12.3485C163.3 12.8163 162.966 13.2398 162.516 13.6191C162.066 13.9984 161.534 14.2956 160.921 14.5105C160.321 14.7128 159.673 14.8139 158.978 14.8139ZM155.05 9.21922H162.925C162.871 8.49855 162.659 7.86638 162.291 7.32271C161.937 6.77904 161.466 6.35549 160.88 6.05205C160.307 5.73596 159.673 5.57792 158.978 5.57792C158.282 5.57792 157.648 5.73596 157.075 6.05205C156.503 6.35549 156.032 6.78536 155.664 7.34168C155.31 7.88534 155.105 8.51119 155.05 9.21922Z"
        fill="white"
      />
      <path
        d="M171.318 5.88136C170.391 5.90665 169.573 6.14687 168.864 6.60203C168.168 7.0572 167.678 7.68305 167.391 8.47958V14.6243H166V4.74345H167.309V7.11409C167.678 6.4187 168.162 5.85607 168.762 5.4262C169.375 4.99632 170.023 4.75609 170.705 4.70552C170.841 4.69288 170.957 4.68656 171.052 4.68656C171.162 4.68656 171.25 4.69288 171.318 4.70552V5.88136Z"
        fill="white"
      />
      <path
        d="M175.909 14.8139C175.037 14.8139 174.225 14.6812 173.475 14.4157C172.725 14.1502 172.078 13.7456 171.532 13.2019L172.105 12.2916C172.705 12.81 173.305 13.1893 173.905 13.4295C174.518 13.6571 175.166 13.7709 175.848 13.7709C176.68 13.7709 177.355 13.6191 177.873 13.3157C178.391 12.9996 178.65 12.5508 178.65 11.9692C178.65 11.5772 178.521 11.2801 178.261 11.0778C178.016 10.8629 177.655 10.6922 177.177 10.5657C176.714 10.4267 176.155 10.2813 175.5 10.1295C174.764 9.9399 174.143 9.74392 173.639 9.54163C173.148 9.32669 172.773 9.06118 172.514 8.7451C172.268 8.41637 172.146 7.99281 172.146 7.47443C172.146 6.82962 172.316 6.29859 172.657 5.88136C173.012 5.45148 173.489 5.12908 174.089 4.91414C174.702 4.68656 175.384 4.57277 176.134 4.57277C176.952 4.57277 177.675 4.69288 178.302 4.9331C178.93 5.17333 179.441 5.50838 179.836 5.93825L179.161 6.81065C178.78 6.40606 178.323 6.10894 177.791 5.91929C177.273 5.717 176.693 5.61585 176.052 5.61585C175.616 5.61585 175.2 5.67274 174.805 5.78653C174.409 5.88768 174.082 6.06469 173.823 6.31756C173.578 6.55778 173.455 6.89283 173.455 7.32271C173.455 7.67672 173.55 7.95488 173.741 8.15718C173.932 8.34683 174.218 8.51119 174.6 8.65027C174.982 8.7767 175.452 8.91578 176.012 9.0675C176.816 9.2698 177.518 9.47841 178.118 9.69335C178.718 9.89565 179.182 10.1612 179.509 10.4899C179.836 10.8186 180 11.2801 180 11.8743C180 12.7973 179.625 13.518 178.875 14.0364C178.125 14.5547 177.136 14.8139 175.909 14.8139Z"
        fill="white"
      />
    </svg>
  );
};
