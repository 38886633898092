import styled from 'styled-components';

const size = 122;
const strokeWidth = 12;
const center = size / 2;
const radius = center - strokeWidth;
const progress = (20 / 120) * 100;
const arcLength = 2 * 3.14 * radius;
const arcOffset = arcLength * ((100 - progress) / 100);

export const IndicatorWrapper = styled.div`
  position: relative;
`;

export const IconRatingWrapper = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

export const Indicator = styled.svg`
  width: ${size}px;
  height: ${size}px;
`;
export const IndicatorTrack = styled.circle`
  cx: ${center}px;
  cy: ${center}px;
  r: ${radius}px;
  fill: transparent;
  stroke-width: ${strokeWidth};
  stroke: #0c182c;
`;

export const IndicatorIndication = styled.circle`
  cx: ${center}px;
  cy: ${center}px;
  r: ${radius}px;
  fill: transparent;
  stroke: #26c0f1;
  stroke-width: ${strokeWidth};
  stroke-dasharray: ${arcLength};
  stroke-dashoffset: ${arcOffset};
`;
