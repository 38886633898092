import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Card = styled.div`
  background: var(--gradient-2);
  border-radius: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardImage = styled.img`
  width: 100%;
  height: 278px;
  border-radius: 20px;
  object-fit: cover;
`;

export const CardEdit = styled(Link)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-fon);
  border-radius: 50%;
`;

export const ImageBox = styled.div`
  position: relative;
  width: 100%;
  height: 278px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-grey-3);
  border-radius: 20px;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 19px 0px;

  @media (min-width: 768px) {
    padding: 16px 24px 0;
  }
`;

export const Date = styled.span`
  font-size: var(--fs-300);
  line-height: var(--line-height-300);
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    margin-bottom: 18px;
  }
`;

export const Title = styled.h2`
  font-size: var(--fs-550);
  font-weight: var(--fw-600);
  line-height: var(--line-height-550);
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-overflow: ellipsis;
  overflow: hidden;

  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-bottom: 25px;
  }
`;

export const Text = styled.p`
  font-size: var(--fs-350);
  line-height: var(--line-height-400);
  font-weight: var(--fw-400);
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SubTitleWrapper = styled.div`
  display: flex;
  gap: 32px;
`;

export const SubTitleText = styled.span`
  margin-bottom: 6px;
`;

export const SubTitle = styled.h3`
  font-size: var(--fs-250);
  font-weight: var(--fw-600);
  line-height: var(--line-height-500);
  margin-top: 18px;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    margin-bottom: 6px;
  }
`;

export const Footer = styled.div``;

export const BlackBtn = styled.button`
  min-width: 179px;
  padding: 16px 50px;
  max-width: 281px;
  margin: 40px auto 24px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  background: var(--gradient-1);
  border-radius: 25px;
  border: none;

  font-size: var(--fs-250);
  font-weight: var(--fw-600);
  line-height: var(--line-height-350);
  color: var(--color-white);

  transition: all 0.2s ease-in-out;

  &::before {
    position: absolute;
    content: '';
    inset: 0;
    background: var(--gradient-5);
    z-index: 2;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    border-radius: 25px;
  }

  &:hover::before {
    opacity: 1;
  }

  &:active::before {
    opacity: 0;
  }

  &:active {
    background: var(--gradient-2);
  }

  svg {
    position: relative;
    z-index: 3;
    transform: rotate(180deg);
  }
`;

export const BlackBtnText = styled.span`
  position: relative;
  z-index: 3;
`;
