import React, { FC } from 'react';

import { Section, Wrapper, IconWrapper, Title } from './style';

interface BannerBlockProps {
  title: string;
  icon: React.ReactNode;
  bgImageUrl: string;
  href?: string;
  action?: () => void;
}

const BannerBlock: FC<BannerBlockProps> = (data) => {
  return (
    <Section>
      <Wrapper
        href={data.href}
        style={{ backgroundImage: `url("${data.bgImageUrl}")` }}
        onClick={data.action}
      >
        <Title>{data.title}</Title>
        <IconWrapper>{data.icon}</IconWrapper>
      </Wrapper>
    </Section>
  );
};

export default BannerBlock;
