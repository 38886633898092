export const IconClose = ({ projectPage }: { projectPage: boolean | undefined }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={projectPage ? '21' : '64'}
      height={projectPage ? '21' : '64'}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M31.9999 58.6668C17.279 58.6508 5.34941 46.7212 5.33325 32.0002V31.4668C5.6264 16.8123 17.6922 5.14141 32.3485 5.33589C47.0047 5.53035 58.7565 17.5172 58.6607 32.1746C58.565 46.8316 46.6575 58.6639 31.9999 58.6668ZM18.6666 29.3335V34.6668H29.3333V45.3335H34.6666V34.6668H45.3332V29.3335H34.6666V18.6669H29.3333V29.3335H18.6666Z"
        fill={projectPage ? '#FFFFFF' : '#26C0F1'}
      />
    </svg>
  );
};
