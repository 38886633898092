import React, { FC } from 'react';
import { Container } from '../../lib/styled/container';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Section, Wrapper } from './style';
import 'swiper/css';
import 'swiper/css/pagination';
import { HowItWorksSliderSlide, HowItWorksSliderSlideProps } from '../HowItWorksSliderSlide';

interface HowItWorksSliderFullProps {
  data: Array<HowItWorksSliderSlideProps>;
}

const HowItWorksSliderFull: FC<HowItWorksSliderFullProps> = (props) => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Swiper
            modules={[Pagination]}
            pagination={{ clickable: true }}
            breakpoints={{
              768: {
                spaceBetween: 30,
                slidesPerView: 1
              }
            }}
          >
            {props.data.map((item: HowItWorksSliderSlideProps, index) => (
              <SwiperSlide key={index}>
                <HowItWorksSliderSlide num={item.num} title={item.title} descr={item.descr} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default HowItWorksSliderFull;
