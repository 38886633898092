import IDepartment from './IDepartment';

export enum TestStatus {
  Unknown,
  SentToLead,
  Success,
  Failed
}

/** интерфейс тестового задания */
export default interface ITestTask {
  department: IDepartment;
  testTask: string;
  testTaskStatus?: TestStatus;
}
