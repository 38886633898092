import styled, { css } from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const ContUsForm = styled.form`
  padding: 24px 25px;
  background: var(--gradient-2);
  border-radius: 20px;
  text-align: center;
  max-width: 500px;
  height: fit-content;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 768px) {
    min-width: 384px;
    margin-inline: 125px;
  }
`;

export const Label = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  gap: 10px;
  position: relative;
  flex-grow: 1;

  @media (min-width: 768px) {
    width: initial;

    ${({ wfill }: { wfill?: boolean }) => {
      return (
        wfill &&
        css`
          width: 100%;
        `
      );
    }}
  }
`;

export const LabelText = styled.span`
  font-size: var(--fs-450);
  font-weight: var(--fw-600);
  line-height: var(--line-height-500);
  color: var(--color-white);
`;

export const InputName = styled.input.attrs({
  type: 'text'
})`
  width: 100%;
  padding: 15px 24px;
  border: 2px solid var(--color-grey);
  border-radius: 15px;
  font-size: var(--fs-400);
  font-weight: 600;
  background: var(--color-white);

  &::placeholder {
    font-size: var(--fs-400);
    font-weight: var(--fw-400);
    line-height: var(--line-height-500);
  }

  &:focus {
    outline: none;
  }
`;

export const InputText = styled.textarea.attrs({
  type: 'text'
})`
  width: 100%;
  max-width: 100%;
  padding: 15px 24px;
  border: 2px solid var(--color-grey);
  border-radius: 15px;
  font-size: var(--fs-400);
  font-weight: 600;
  background: var(--color-white);

  &::placeholder {
    font-size: var(--fs-400);
    font-weight: var(--fw-400);
    line-height: var(--line-height-500);
  }

  &:focus {
    outline: none;
  }
`;

export const SentButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: var(--color-white);

  border-radius: 25px;
  width: 179px;
  height: 40px;

  transition: opacity 0.25s;

  position: relative;
  background: var(--gradient-1);
  z-index: 1;
  &::before {
    border-radius: 25px;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--gradient-4);
    z-index: -1;
    transition: opacity 0.5s;
    opacity: 0;
  }
  &:hover::before {
    opacity: 1;
  }
  &:active {
    border: 2px solid #26c0f1;
    border-radius: 25px;
  }
  @media (max-width: 768px) {
    width: 256px;
    font-weight: 600;
    font-size: 16px;
  }
`;
