import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  border-radius: 20px;
  padding: 20px 16px;
  gap: 24px;

  background: var(--gradient-2);
  color: var(--color-white);

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
  }
`;

export const BadgeWrapper = styled.div`
  width: 112px;
  position: relative;
`;

export const Badge = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 32px;

  & svg {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 768px) {
    right: 0;
    top: 76px;
    width: 32px;
    height: 32px;
  }
`;

export const CardMeadia = styled.img`
  width: 110px;
  border: 2px solid var(--color-blue-1);
  border-radius: 50%;
  @media (min-width: 768px) {
  }
`;

export const CardTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  text-align: center;

  margin-top: 16px;
  @media (min-width: 1024px) {
    text-align: left;
  }
`;

export const CardSubTitle = styled.h3`
  font-weight: 700;
  font-size: 14px;
`;

export const CardDepartment = styled.div``;

export const CardEmail = styled.p`
  fontweight: 400;
  font-size: 14px;
  text-align: center;

  margin-top: 5px;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

export const Divider = styled.hr``;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0 8px;
  color: var(--color-grey-3);
  @media (min-width: 768px) {
    gap: 40px;
  }
`;

export const CardBody = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const CardFooterSubTitle = styled.h3`
  font-weight: 400;
  font-size: 14px;
`;

export const CardFooterText = styled.p`
margin-top: 10px;

font-size: 16px
font-weight: 600;
line-height: 22px;

color: var(--color-white);
`;

export const CardFooterSection = styled.div``;
