import { FC } from 'react';
import { Container } from '../../lib/styled/container';
import { Telegram, Youtube, LogoIcon } from './Icon';
import {
  FooterMail,
  Wrapper,
  Social,
  FooterLink,
  FooterText,
  Logo,
  StyledFooter,
  IconDiv,
  SocialLink,
  SocialText
} from './style';

const Footer: FC = () => {
  return (
    <StyledFooter>
      <Container>
        <Wrapper>
          <Logo>
            <LogoIcon />
          </Logo>
          <SocialText>
            <Social>
              <FooterLink href="/assets/img/privacy.pdf" download="privacy">
                Политика конфиденциальности
              </FooterLink>
              <FooterText>@2023, Guild of Developers</FooterText>
            </Social>
            <Social>
              <FooterMail href="mailto:guild.of.developers@mail.ru">
                guild.of.developers@mail.ru
              </FooterMail>
              <IconDiv>
                <SocialLink href="#">
                  <Telegram />
                </SocialLink>
                <SocialLink href="https://www.youtube.com/@terraitschool" target="_blank">
                  <Youtube />
                </SocialLink>
              </IconDiv>
            </Social>
          </SocialText>
        </Wrapper>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
