import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestLoginByMail } from '../../api/UserApi';

interface User {
  email: string;
  password: string;
}

export const LOGIN = createAsyncThunk('LOGIN', (user: User) => {
  return requestLoginByMail(user.email, user.password);
});
