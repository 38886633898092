import { SVGProps } from 'react';
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#fff"
      d="M15.874.953a1.574 1.574 0 0 0-.547-.694C15.087.09 14.806 0 14.52 0H1.48C1.194 0 .914.09.673.26.433.427.243.67.127.952a1.717 1.717 0 0 0-.108.903c.046.306.173.59.366.82l.009.011 4.94 5.693v6.02c0 .29.072.574.21.822.138.249.335.452.572.589a1.392 1.392 0 0 0 1.521-.08l2.37-1.706c.204-.147.37-.345.485-.577.115-.232.175-.492.175-.755V8.38l4.938-5.693.01-.011c.192-.23.32-.514.366-.82.046-.306.009-.62-.107-.903ZM9.29 7.159a1.666 1.666 0 0 0-.401 1.094v4.268l-1.778 1.28V8.253c0-.406-.143-.798-.4-1.094L2.164 1.92h11.67L9.29 7.16Z"
    />
  </svg>
);
export default Icon;
