import styled, { css } from 'styled-components';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  position: relative;
`;

export const LeftFilter = styled.div`
  display: flex;
  gap: 25px;
  width: 100%;

  @media (max-width: 460px) {
    justify-content: center;
  }
`;

export const FilterButtons = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-blue-1);
  width: 179px;
  height: 40px;
  border: 2px solid var(--color-blue-1);
  border-radius: 25px;
  font-weight: var(--fw-600);
  font-size: var(--fs-250);
  line-height: var(--line-height-450);

  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    font-weight 0.3s ease-in-out;

  ${({ active }: { active?: boolean }) => {
    return (
      active &&
      css`
        color: var(--color-white);
        background-color: var(--color-violet-1);
        border: none;
      `
    );
  }}

  @media (max-width: 768px) {
    width: 136px;
    height: 30px;
    font-size: var(--fs-350);
  }
`;

export const MenuButton = styled(Menu.Button)`
  padding: 5px 22px;
  border-radius: 15px;

  @media (max-width: 460px) {
    position: absolute;
    right: 0;
    top: -78px;
  }
`;

export const MenuItems = styled(Menu.Items)`
  position: absolute;
  left: 0;
  bottom: -140px;
  width: 100%;
  padding: 30px 25px;
  border-radius: 15px;
  border-top-right-radius: 0;
  background-color: var(--color-white-2);
  z-index: 100;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -25px;
    right: 0;
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 25px solid var(--color-white-2);
  }
`;
