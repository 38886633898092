import React, { FC } from 'react';
import {
  StyledLink,
  EmptySection,
  InputEmail,
  Label,
  LabelText,
  NextButton,
  RegForm,
  Section,
  Title,
  Typography,
  Wrapper,
  Divider,
  HalfContainer
} from './style';

export const ForgetPasswordForm: FC = () => {
  const [email, setEmail] = React.useState('');
  return (
    <>
      <EmptySection />
      <Section primary>
        <HalfContainer>
          <Wrapper>
            <Title>Восстановить пароль</Title>
            <RegForm>
              <Label>
                <LabelText>Email*</LabelText>
                <InputEmail
                  value={email}
                  placeholder="Введите"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Label>
              <Typography size="14px" weight="400">
                Нажимая на кнопку, вы даете согласие на <Divider /> обработку{' '}
                <StyledLink to="/assets/img/privacy.pdf" target="_blank">
                  персональных данных
                </StyledLink>
              </Typography>
            </RegForm>
            <NextButton>Отправить</NextButton>
          </Wrapper>
        </HalfContainer>
      </Section>
    </>
  );
};
