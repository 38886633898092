import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CardLink = styled(Link)`
  display: block;
`;

export const Card = styled.div`
  font-feature-settings: 'pnum' on, 'lnum' on;
  background: var(--gradient-2);
  border-radius: 20px;
  height: 100%;
`;

export const ImgBlock = styled.div`
  background: linear-gradient(180deg, #ffffff 0%, #c3f0ff 100%);
  border-radius: 10px;

  @media (max-width: 430px) {
    padding: 40px 23px;
  }
`;

export const Img = styled.img`
  width: 100%;
  border-radius: 10px;
`;

export const WrapperInfo = styled.div`
  padding: 24px 25px;
`;

export const Date = styled.span`
  color: var(--color-white);
  font-weight: var(--fw-300);
  font-size: var(--fs-300);
  line-height: var(--line-height-200);
`;

export const Title = styled.h2`
  color: var(--color-white);
  font-weight: var(--fw-700);
  font-size: var(--fs-900);
  line-height: var(--line-height-550);
  margin-top: 8px;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: 1024px) {
    font-size: var(--fs-900);
  }
`;

export const Description = styled.p`
  color: var(--color-white);
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: var(--fw-400);
  font-size: var(--fs-350);
  line-height: var(--line-height-650);
  margin-top: 24px;
`;
