export const IconFilter = ({ isMobile }: { isMobile: boolean }) => {
  return isMobile ? (
    <svg width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.211604 1.61C2.2316 4.2 5.9616 9 5.9616 9V15C5.9616 15.55 6.4116 16 6.9616 16H8.9616C9.5116 16 9.9616 15.55 9.9616 15V9C9.9616 9 13.6816 4.2 15.7016 1.61C15.8162 1.46237 15.8871 1.28553 15.9061 1.09961C15.9251 0.913684 15.8916 0.726154 15.8092 0.558371C15.7269 0.390588 15.5991 0.249293 15.4404 0.150577C15.2817 0.0518598 15.0985 -0.000312035 14.9116 1.40404e-06H1.0016C0.171604 1.40404e-06 -0.298396 0.950002 0.211604 1.61Z"
        fill="#26C0F1"
      />
    </svg>
  ) : (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.211604 1.61C2.2316 4.2 5.9616 9 5.9616 9V15C5.9616 15.55 6.4116 16 6.9616 16H8.9616C9.5116 16 9.9616 15.55 9.9616 15V9C9.9616 9 13.6816 4.2 15.7016 1.61C15.8162 1.46237 15.8871 1.28553 15.9061 1.09961C15.9251 0.913684 15.8916 0.726154 15.8092 0.558371C15.7269 0.390588 15.5991 0.249293 15.4404 0.150577C15.2817 0.0518598 15.0985 -0.000312035 14.9116 1.40404e-06H1.0016C0.171604 1.40404e-06 -0.298396 0.950002 0.211604 1.61Z"
        fill="#26C0F1"
      />
    </svg>
  );
};
