export const Telegram = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.943 2.03882L16.9249 16.4831C16.6972 17.5025 16.1034 17.7562 15.2596 17.2759L10.6609 13.8371L8.44199 16.0028C8.19643 16.252 7.99105 16.4604 7.5178 16.4604L7.84819 11.7076L16.3713 3.89193C16.7418 3.55665 16.2909 3.37088 15.7953 3.70616L5.25867 10.439L0.72255 8.99815C-0.264145 8.68553 -0.282004 7.99684 0.927925 7.51658L18.6706 0.579901C19.4921 0.267275 20.2109 0.765664 19.943 2.03882Z"
        fill="#26C0F1"
      />
    </svg>
  );
};

export const Youtube = () => {
  return (
    <svg
      height="22px"
      width="22px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 461.001 461.001"
      fill="#26C0F1"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <g>
          {' '}
          <path d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"></path>{' '}
        </g>{' '}
      </g>
    </svg>
  );
};

export const LogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="141"
      height="79"
      viewBox="0 0 141 79"
      fill="none"
    >
      <path
        d="M31.003 66.071C26.6712 66.071 22.6179 65.1839 18.8431 63.4098C15.0683 61.6357 11.7576 59.1886 8.91103 56.0686C6.12633 52.9485 3.92952 49.4003 2.32058 45.4238C0.773527 41.4473 0 37.2261 0 32.7602C0 28.2943 0.773527 24.1037 2.32058 20.1884C3.92952 16.273 6.12633 12.786 8.91103 9.72712C11.7576 6.66828 15.0683 4.28238 18.8431 2.56943C22.6798 0.856476 26.8569 0 31.3742 0C37.6243 0 42.8843 1.3153 47.1542 3.9459C51.4859 6.51533 54.7348 9.94123 56.9006 14.2236L48.9178 19.9131C47.1851 16.3648 44.7099 13.7036 41.492 11.9295C38.336 10.1554 34.8706 9.26829 31.0958 9.26829C28.0017 9.26829 25.186 9.91065 22.6489 11.1954C20.1117 12.4801 17.9149 14.2542 16.0584 16.5177C14.2638 18.7201 12.9024 21.2589 11.9742 24.1343C11.046 26.9484 10.5818 29.9155 10.5818 33.0355C10.5818 36.339 11.1078 39.4285 12.1598 42.3038C13.2118 45.1791 14.697 47.7179 16.6154 49.9203C18.5337 52.0615 20.7924 53.7438 23.3915 54.9674C25.9905 56.1909 28.8061 56.8027 31.8384 56.8027C35.4275 56.8027 38.831 55.9156 42.0489 54.1415C45.3287 52.3062 48.3918 49.5838 51.2384 45.9744V56.4356C45.4834 62.8592 38.7382 66.071 31.003 66.071ZM50.2174 40.2849H31.0958V32.6684H51.2384L50.6119 39.2297L51.4304 48.4042L55.9089 60.182L58.9427 65.6122L53.164 62.3365L50.2174 40.2849Z"
        fill="url(#paint0_linear_4893_13971)"
      />
      <path
        d="M86.1018 65.4957L88.1244 57.165L92.6028 40.36L86.1018 28.2948L86.1018 0.57373H109.236C116.274 0.57373 122.149 2.03676 126.862 4.96282C131.574 7.82792 135.094 11.7293 137.419 16.6671C139.806 21.5438 140.999 26.9692 140.999 32.9433C140.999 39.5269 139.684 45.2571 137.052 50.1338C134.482 55.0106 130.809 58.7901 126.036 61.4723C121.262 64.1545 115.662 65.4957 109.236 65.4957H86.1018ZM130.534 32.9433C130.534 28.3713 129.677 24.348 127.964 20.8733C126.311 17.3376 123.894 14.5944 120.711 12.6437C117.59 10.6321 113.765 9.62622 109.236 9.62622H96.3836V56.4432H109.236C113.826 56.4432 117.682 55.4068 120.803 53.3342C123.985 51.2616 126.403 48.4575 128.055 44.9218C129.708 41.3861 130.534 37.3933 130.534 32.9433Z"
        fill="url(#paint1_linear_4893_13971)"
      />
      <g filter="url(#filter0_d_4893_13971)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.3558 71.2411C84.3186 71.2411 95.6376 59.9875 95.6376 46.1054C95.6376 32.2234 84.3186 20.9697 70.3558 20.9697C56.393 20.9697 45.074 32.2234 45.074 46.1054C45.074 59.9875 56.393 71.2411 70.3558 71.2411ZM70.4248 62.8727C81.0924 62.8727 89.7402 54.2749 89.7402 43.669C89.7402 33.063 81.0924 24.4652 70.4248 24.4652C59.7572 24.4652 51.1094 33.063 51.1094 43.669C51.1094 54.2749 59.7572 62.8727 70.4248 62.8727Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4893_13971"
          x="39.074"
          y="15.9697"
          width="62.5637"
          height="62.2715"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4893_13971" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4893_13971"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4893_13971"
          x1="0.000724301"
          y1="-4.31776"
          x2="68.059"
          y2="-48.4192"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#26C0F1" />
          <stop offset="0.990321" stopColor="#6F35CE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4893_13971"
          x1="-3.7098"
          y1="-10.221"
          x2="66.6214"
          y2="-56.1776"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#26C0F1" />
          <stop offset="0.953478" stopColor="#6F35CE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
