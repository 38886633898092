import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Accordion from '../../../Accordion';
import BannerBlock from '../../../BannerBlock';
import { ArrowRightCircled } from '../../../UI/Icons/ArrowRightCircled';
import { BlankImage } from '../../../UI/Icons/BlankImage';
import { IconCloseViolet1 } from '../../../UI/Icons/IconCloseViolet1';
import {
  Card,
  CardImage,
  Description,
  Paragraph,
  ImageWrapper,
  SubTitle,
  List,
  Date,
  ImageBox
} from '../style';

export const ProjectCard = () => {
  const data = [
    { imageUrl: '/assets/img/project-screenshot.webp', alt: 'Раз' },
    { imageUrl: '/assets/img/project-screenshot.webp', alt: 'Два' },
    { imageUrl: '/assets/img/project-screenshot.webp', alt: 'Три' },
    { imageUrl: '/assets/img/project-screenshot.webp', alt: 'Четыре' }
  ];
  return (
    <Card>
      <Swiper
        scrollbar={{ draggable: true }}
        modules={[Pagination]}
        pagination={{ clickable: true }}
        slidesPerView={1}
      >
        {data.map((card) => (
          <SwiperSlide>
            <ImageWrapper>
              <ImageBox>
                {card.imageUrl ? <CardImage src={card.imageUrl} alt="обложка" /> : <BlankImage />}
              </ImageBox>
            </ImageWrapper>
          </SwiperSlide>
        ))}
      </Swiper>

      <Description>
        Проект, основной целью которого является создание платформы, которая будет оказывать
        помощь в развитии новых фондов и заниматься продвижением существующих.
      </Description>
      <hr />
      <SubTitle>Задача</SubTitle>
      <Paragraph>
        Проект, основной целью которого является создание платформы, которая будет оказывать
        помощь в развитии новых фондов и заниматься продвижением существующих.
      </Paragraph>
      <hr />
      <SubTitle>Что сделали</SubTitle>
      <List>
        <li>Разработка дизайна веб-сайта</li>
        <li>Frontend разработка</li>
        <li>Backend разработка</li>
        <li>Деплой сервисов</li>
      </List>
      <hr />
      <SubTitle>Разработка проекта</SubTitle>
      <SubTitle>6 месяцев</SubTitle>
      <Date>28.08.2022 – 11.11.2022</Date>
      <hr />
      <BannerBlock
        title="Перейти на сайт"
        href="#"
        icon={<ArrowRightCircled />}
        bgImageUrl="/assets/img/rectangle-9.webp"
      />
      <hr />
      <SubTitle>Кто делал проект</SubTitle>
      <Accordion
        styles={{
          background: '#D0D0D0',
          textColor: '#000000',
          borderTop: '#777777',
          iconClose: <IconCloseViolet1 />
        }}
        data={[
          {
            avatar: '/assets/img/avatar.webp',
            title: 'Backend',
            text: 'Иванов Иван'
          },
          {
            avatar: '/assets/img/avatar.webp',
            title: 'Frontend',
            text: 'User2'
          },
          {
            avatar: '/assets/img/avatar.webp',
            title: 'UI/UX',
            text: 'User3'
          },
          {
            avatar: '/assets/img/avatar.webp',
            title: 'DS',
            text: 'User4'
          }
        ]}
      />
    </Card>
  );
};
