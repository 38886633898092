import styled from 'styled-components';

export const Card = styled.div`
  background: var(--gradient-2);
  border-radius: 15px;
  padding: 100px 60px 64px 67px;
  max-width: 792px;
  margin: 0 auto;

  .swiper {
    padding-bottom: 75px;
  }
  .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--gradient-3);
  border-radius: 15px;
  padding: 35px 0;
`;

export const CardImage = styled.img`
  border-radius: 10px;
  width: 400px;
  height: auto;
  min-height: 300px;
`;

export const ImageBox = styled.div`
  width: 400px;
  height: 278px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-grey-3);
  border-radius: 10px;
`;

export const SubTitle = styled.h2`
  font-size: 24px;
  display: flex;
  justify-content: center;
  color: var(--color-white);
  font-weight: var(--fw-700);
  margin-top: 44px;
  margin-bottom: 32px;

  @media (max-width: 780px) {
    font-size: 20px;
    line-height: 26px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`;

export const Description = styled.p`
  font-size: 20px;
  color: var(--color-white);
  margin: 35px 0 44px;
  line-height: 26px;
  font-weight: 700;

  @media (max-width: 780px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const Paragraph = styled.p`
  font-weight: 400;
  font-style: normal;

  margin-top: 24px;
  margin-bottom: 44px;
  font-size: 16px;
  color: var(--color-white);
  line-height: 22px;

  @media (max-width: 780px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const List = styled.ul`
  font-weight: 400;
  font-style: normal;
  list-style: inside;

  margin: 24px 0 44px;
  font-size: 16px;
  color: var(--color-white);
  line-height: 22px;

  @media (max-width: 780px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const Date = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-style: normal;
  margin: 16px 0 44px;

  color: var(--color-white);
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-feature-settings: 'pnum' on, 'lnum' on;
`;
