import { Menu } from '@headlessui/react';

import { FC } from 'react';
import { Container } from '../../../../lib/styled/container';
import {
  Fieldset,
  InputText,
  Label,
  LabelText,
  MenuButton,
  MenuItems,
  Section,
  Textarea,
  Title,
  TitleText,
  ArrowLink,
  Wrapper,
  WrapperDiv,
  BlackBtn,
  BlackBtnText,
  ButtonsBox,
  WhiteBtn
} from './style';
import { ArrowBack } from './icon';
import DropdownList from '../../../DropdownList';
import IDepartment from '../../../../api/types/interface/IDepartment';
import ISocialLink from '../../../../api/types/interface/ISocialLink';

interface IProjectGodForm {
  name?: string;
  workInfo?: string;
  participantBenefit?: string;
  projectId?: string;
  description?: string;
  projectLinks: ISocialLink[];
  checkedDepartments: () => IDepartment[];
  changeInput: (name: string, value: string) => void;
  changeProjectLinks: (id: number, url: string) => void;
  addProjectLink: () => void;
}

const ProjectGodForm: FC<IProjectGodForm> = ({
  changeInput,
  projectLinks,
  changeProjectLinks,
  addProjectLink,
  checkedDepartments,
  name,
  workInfo,
  participantBenefit,
  description
}) => {
  return (
    <Section>
      <Container>
        <Title>
          <ArrowLink to="/projects">
            <ArrowBack />
          </ArrowLink>
          <TitleText>Добавить проект</TitleText>
        </Title>
        <Wrapper>
          <WrapperDiv>
            <Fieldset>
              <Label wfill>
                <LabelText>Название</LabelText>
                <InputText
                  value={name}
                  placeholder="Введи"
                  onChange={(e) => changeInput('name', e.target.value)}
                />
              </Label>
              <Label wfill>
                <LabelText>Описание</LabelText>
                <Textarea
                  value={description}
                  placeholder="Начни вводить...."
                  onChange={(e) => changeInput('description', e.target.value)}
                />
              </Label>
              <Label wfill>
                <LabelText>Чем предстоит заниматься</LabelText>
                <InputText
                  value={workInfo}
                  placeholder="Введи"
                  onChange={(e) => changeInput('workInfo', e.target.value)}
                />
              </Label>
              <Label wfill>
                <LabelText>Что участник получит</LabelText>
                <InputText
                  value={participantBenefit}
                  placeholder="Введи"
                  onChange={(e) => changeInput('participantBenefit', e.target.value)}
                />
              </Label>
              <Label wfill>
                <LabelText>Указать направления</LabelText>
                <Menu>
                  <MenuButton>
                    {checkedDepartments()
                      .map((item) => item.name)
                      .join(', ') || 'Выбери'}
                  </MenuButton>
                  <MenuItems>
                    <DropdownList page="addProject" type="checkbox" name="department" />
                  </MenuItems>
                </Menu>
              </Label>
              {checkedDepartments().map((item, j) => (
                <Label key={j} wfill>
                  <LabelText>{item.name} Стек (один или несколько)</LabelText>
                  <Menu>
                    <MenuButton>
                      {(item.stacks || [])
                        .filter((i) => i.isCheck)
                        .map((i) => i.name)
                        .join(', ') || 'Выбери'}
                    </MenuButton>
                    <MenuItems>
                      <DropdownList
                        page="addProject"
                        type="checkbox"
                        name="stack"
                        departmentName={item.name}
                      />
                    </MenuItems>
                  </Menu>
                </Label>
              ))}
              <Label wfill>
                <LabelText>Добавить ссылки на чаты в Telegram, Trello, GitHub</LabelText>
                {projectLinks.map((item, i) => (
                  <InputText
                    key={item.id}
                    value={item.url}
                    placeholder="Введи"
                    onChange={(e) => changeProjectLinks(i, e.target.value)}
                  />
                ))}
              </Label>
            </Fieldset>
            <BlackBtn
              marginTop="16px"
              onClick={(e) => {
                e.preventDefault();
                addProjectLink();
              }}
            >
              <BlackBtnText>Добавить ещё ссылку</BlackBtnText>
            </BlackBtn>
            <ButtonsBox>
              <WhiteBtn>Предпросмотр</WhiteBtn>
              <BlackBtn maxWidth="277px">
                <BlackBtnText>Добавить проект</BlackBtnText>
              </BlackBtn>
            </ButtonsBox>
          </WrapperDiv>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default ProjectGodForm;
