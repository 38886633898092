import mem from 'mem';
import { instance } from '.';
import AuthTokenPayload from './types/class/AuthTokenPayload';
import IAuthTokens from './types/interface/IAuthTokens';
import ITelegramUserData from './types/interface/ITelegramUserData';

export interface IAuthApiResponse {
  msg?: string;
  data?: never;
  tokens?: IAuthTokens;
}
class AuthError {
  response!: IAuthApiResponse;
}

/** Auth class */
export default class Auth {
  /** Ключ для хранения авторизационных токенов */
  private static readonly STORAGE_TOKENS_KEY: string = 'my_tokens_key';

  /** mam max cache age */
  public static readonly MEM_MAX_AGE: number = 10000;

  /** Вход по email/password
   * AuthByEmailPwd
   */
  public static async AuthByEmailPwd(
    email: string,
    pwd: string,
    handleAuthOk?: (authData: IAuthApiResponse) => void,
    handleAuthError?: (msg: string) => void
  ) {
    if (email === '') throw new RangeError('Bad parameter: email');
    if (pwd === '') throw new RangeError('Bad parameter: pwd');
    try {
      const response = await instance
        .post('/auth/login/', {
          email: email,
          password: pwd
        })
        .then((res): IAuthTokens => res.data);

      console.log(response);
      if (response) Auth.SaveTokens(response);
      else throw new Error('Bad response');

      //TODO: save current member
      if (handleAuthOk && response) handleAuthOk({ tokens: response });
    } catch (error: unknown) {
      if (error instanceof AuthError)
        if (handleAuthError) handleAuthError(error?.response?.msg || '');
    }
  }

  /**
   * Logout
   */
  public static async Logout() {
    //TODO: delete current user from state
    try {
      await instance.post('/auth/logout/');
    } catch (error: unknown) {}
    Auth.RemoveTokens();
  }

  /**
   * Сохранить токены в хранилище
   * @param @interface tokens токены для сохранения
   */
  private static SaveTokens(tokens: IAuthTokens) {
    localStorage.setItem(Auth.STORAGE_TOKENS_KEY, JSON.stringify(tokens));
  }

  /**
   * Получить токены из хранилища
   * @returns @interface IAuthTokens токены
   */
  public static GetTokens(): IAuthTokens | null {
    const jsonStr = localStorage.getItem(Auth.STORAGE_TOKENS_KEY);
    if (jsonStr != null) return JSON.parse(jsonStr);

    return null;
  }

  /**
   * Удаление токенов из хранилища
   */
  public static RemoveTokens() {
    localStorage.removeItem(Auth.STORAGE_TOKENS_KEY);
  }

  public static async GetAccessToken() {
    const tokens = Auth.GetTokens();
    if (tokens == null) return '';

    const payload = AuthTokenPayload.CreateFromB64(tokens.access_token);
    if (payload.CheckAlive()) return tokens.access_token;

    const refreshPayload = AuthTokenPayload.CreateFromB64(tokens.refresh_token);
    // todo: check if nessesary and try to memoize
    if (refreshPayload.CheckAlive()) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const rtokens = await memoizedRefreshTokens();
      return rtokens;
    }

    return '';
  }

  public static async RefreshTokens() {
    try {
      const response = await instance
        .post('/auth/refresh_token/', undefined, {
          headers: {
            Authorization: `Bearer ${Auth.GetTokens()?.refresh_token}`
          }
        })
        .then((res): IAuthApiResponse => res.data);

      console.log(response);
      if (response && response.tokens) Auth.SaveTokens(response?.tokens);
      else throw new Error('Bad response');

      return Auth.GetTokens();
    } catch (error: unknown) {
      Auth.RemoveTokens();
      throw error;
    }
  }

  public static async AuthByTelegram(
    tgData: ITelegramUserData,
    handleAuthOk?: (authData: IAuthApiResponse) => void,
    handleAuthError?: (msg: string) => void
  ) {
    if (!tgData) throw new RangeError('Bad parameter: tgData');
    try {
      const response = await instance
        .post('/auth/login/telegram', tgData)
        .then((res): IAuthApiResponse => res.data);

      console.log(response);
      if (response && response.tokens) Auth.SaveTokens(response?.tokens);
      else throw new Error('Bad response');

      //TODO: save current member
      if (handleAuthOk) handleAuthOk(response);
    } catch (error: unknown) {
      if (error instanceof AuthError)
        if (handleAuthError) handleAuthError(error?.response?.msg || '');
    }
  }
}

export const memoizedRefreshTokens = mem(Auth.RefreshTokens, { maxAge: Auth.MEM_MAX_AGE });
