import styled from 'styled-components';

export const StyledLoginButton = styled.button`
  display: flex;
  width: 183.273px;
  height: 48px;
  padding: 9px 19px;
  justify-content: center;
  align-items: center;
  gap: 25px;
  border-radius: 25px;
  border: 2px solid var(--blue-1, #26c0f1);
  color: var(--blue-1, #26c0f1);

  &:hover,
  &:focus-visible {
    border-radius: 25px;
    border: 2px solid var(--violet-1, #6f35ce);
    color: var(--violet-1, #6f35ce);
  }

  &:active {
    background: var(--blue-1, #26c0f1);
  }
`;
