export const ArrowBack = () => {
  return (
    <svg width="23" height="26" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 1L1 8L8 15M1 8H17"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
