import React, { FC } from 'react';
import NavBarButton from '../NavBarButton';
import { Container } from '../../../lib/styled/container';
import { NavBarLink, StyledHeader, Navbar, LogoDiv, LeftDiv, RightDiv } from './style';
import { LogoIcon } from '../Icon';
import LoginButton from '../LoginButton';
import { useSelector } from 'react-redux';

const DesktopHeader: FC = () => {
  const LoginStatus = useSelector((store: any) => store.usersReduser.isLogin);
  console.log(LoginStatus);
  return (
    <StyledHeader>
      <Container>
        <Navbar>
          <LeftDiv>
            {/* <NavBarLink wip="false" to="/howItWorks">
              Как это устроено?
            </NavBarLink> */}
            <NavBarLink wip="false" to="/aboutGuild">
              О Гильдии
            </NavBarLink>
            <NavBarLink wip="false" to="/projects">
              <NavBarButton>Найти проект</NavBarButton>
            </NavBarLink>
          </LeftDiv>
          <LogoDiv>
            <NavBarLink to="/">
              <LogoIcon />
            </NavBarLink>
          </LogoDiv>
          <RightDiv>
            <NavBarLink wip="false" to="/login">
              <LoginButton>Войти</LoginButton>
            </NavBarLink>
            {/* <NavBarLink wip="false" to="/profile">
              <LoginButton>Профиль</LoginButton>
            </NavBarLink> */}
            <NavBarLink wip="true" to="#">
              Архив проектов
            </NavBarLink>
            <NavBarLink wip="false" to="/guildMembers">
              Гильдийцы
            </NavBarLink>
          </RightDiv>
        </Navbar>
      </Container>
    </StyledHeader>
  );
};

export default DesktopHeader;
