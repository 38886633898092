import styled, { css } from 'styled-components';
// 2 стиля primary secondary

// ${({ bgColor})} => bgColor && css`
// background-color: ${bgColor};`
// background: ${({error, isDirty})} => (error, isDirty) ? 'red' : 'gray';

// attrs - инлайновые стили
// styled.textarea.attrs(({ heightCustom })) => ({ style: { height: heightCustom } })

// const AvatarStyled = styled.img``
// const UserStyled = styled.section`${AvatarStyled}
// { width: 150px }`

// webPageTest
// Chrome Perfomance повторный рендеринг route rendering performance

// вариант outlide = boolean: props

interface PropsButton {
  outlined?: boolean;
  primary?: boolean;
}

export const NextButton = styled.button<PropsButton>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ primary }) =>
    primary &&
    css`
      background: var(--gradient-1);
      border: none;
      color: var(--color-white);
      &::before {
        background: var(--gradient-5);
      }
      &:active {
        background: var(--gradient-2);
      }
    `};

  ${({ outlined }) =>
    outlined &&
    css`
      background: transparent;
      border: 2px solid var(--color-blue-1);
      color: var(--color-blue-1);
    `};

  padding: 0.867em;
  font-size: inherit;

  border-radius: 2em;
  font-weight: 600;

  position: relative;
  z-index: 1;

  &::before {
    border-radius: inherit;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: opacity 1s;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }
  &:active::before {
    opacity: 0;
  }
`;
