import { FC } from 'react';
import { ItemsProps } from '..';
import { CardLink, Card, ImgBlock, Img, WrapperInfo, Date, Title, Description } from './style';

export interface ProjectProps {
  project: ItemsProps;
}

const Project: FC<ProjectProps> = ({ project }) => {
  const image = project.images?.[0];
  return (
    <CardLink to={`/projects/${project.id}`}>
      <Card>
        <ImgBlock>
          <Img src={image === undefined ? '/assets/img/projectDonePlug.png' : image} />
        </ImgBlock>
        <WrapperInfo>
          <Date>
            {project.create_at} - {project.update_at}
          </Date>
          <Title>«{project.title}»</Title>
          <Description>{project.short_description}</Description>
        </WrapperInfo>
      </Card>
    </CardLink>
  );
};

export default Project;
