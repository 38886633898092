import React, { FC } from 'react';
import { ItemList, SvgWrapper, TextBlock, Amount, Name } from './style';
import { TeamFiguresIcon } from './icon';

export interface TeamFiguresOneProps {
  users_count: string;
  department_title: string;
  image_path: string;
}

export const TeamFiguresOne: FC<TeamFiguresOneProps> = (data) => {
  return (
    <ItemList>
      <SvgWrapper>
        <TeamFiguresIcon source={data.image_path} />
      </SvgWrapper>
      <TextBlock>
        <Amount>{data.users_count}</Amount>
        <Name>{data.department_title}</Name>
      </TextBlock>
    </ItemList>
  );
};
