import { createReducer } from '@reduxjs/toolkit';
import { REGISTER } from '../actions/register';

const initialState = {
  id: '',
  errMsg: '',
  status: '',
  isRegistrated: false
};

export const registerReduser = createReducer(initialState, (builder) => {
  builder
    .addCase(REGISTER.pending, (state, action) => {
      state.id = '';
      state.errMsg = '';
      state.status = action.type;
      state.isRegistrated = false;
    })
    .addCase(REGISTER.fulfilled, (state, action) => {
      state.id = action.payload.data.id;
      state.errMsg = '';
      state.status = action.type;
      state.isRegistrated = true;
    })
    .addCase(REGISTER.rejected, (state, action) => {
      state.id = '';
      state.errMsg = action.error.message;
      state.status = action.type;
      state.isRegistrated = false;
    });
});

export default registerReduser;
