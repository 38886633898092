import { FC } from 'react';
import IMember from '../../../api/types/interface/IMember';
import {
  Badge,
  BadgeWrapper,
  Card,
  CardBody,
  CardDepartment,
  CardEmail,
  CardFooter,
  CardFooterSection,
  CardFooterSubTitle,
  CardFooterText,
  CardHeader,
  CardMeadia,
  CardSubTitle,
  CardTitle,
  Divider
} from './style';
import { Flex } from '../../UI/Flex/Flex';
import { Chip } from '../../UI/Chip/Chip';

interface MemberCardProps {
  member: IMember;
}

export const MemberCard: FC<MemberCardProps> = ({ member }) => {
  const departments = member.department?.map((d) => d.name);
  return (
    <Card>
      <CardHeader>
        <BadgeWrapper>
          <Badge src="/assets/img/user-rating.svg" />
          <CardMeadia src={member.profilePhoto?.url} />
        </BadgeWrapper>
      </CardHeader>
      <CardBody>
        <CardTitle>Константинопольский Константин</CardTitle>
        <CardEmail>{member.email}</CardEmail>
        <Divider />
        <CardDepartment>
          <CardSubTitle>Направление</CardSubTitle>
          <Flex gap="8px" mt="8px">
            {departments?.map((d) => (
              <Chip padding="10px 16px" borderRadius="20px" border="1px solid var(--color-white)">
                {d}
              </Chip>
            ))}
          </Flex>
        </CardDepartment>

        <CardDepartment>
          <CardSubTitle>Стек</CardSubTitle>
          <Flex gap="8px" mt="8px">
            {member.department?.map((d) =>
              d.stacks?.map((s) => (
                <Chip padding="10px 16px" borderRadius="20px" border="1px solid var(--color-white)">
                  {s.name}
                </Chip>
              ))
            )}
          </Flex>
        </CardDepartment>
        <Divider />
        <CardFooter>
          <CardFooterSection>
            <CardFooterSubTitle>Участие в проектах</CardFooterSubTitle>
            <CardFooterText>{member.projects?.length}</CardFooterText>
          </CardFooterSection>

          <CardFooterSection>
            <CardFooterSubTitle>Состоит в Гильдии</CardFooterSubTitle>
            <CardFooterText>{member.timeInGuild}</CardFooterText>
          </CardFooterSection>
        </CardFooter>
      </CardBody>
    </Card>
  );
};
