import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestRegistrationByMail } from '../../api/UserApi';

export const REGISTER = createAsyncThunk(
  'REGISTER',
  ({
    telegram_id,
    last_name,
    tg_login,
    town,
    email,
    password,
    nickname,
    create_at,
    about_me,
    stack,
    departments,
    links
  }) => {
    return requestRegistrationByMail(
      telegram_id,
      last_name,
      tg_login,
      town,
      email,
      password,
      nickname,
      create_at,
      about_me,
      stack,
      departments,
      links
    );
  }
);
