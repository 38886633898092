import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Section = styled.section`
  background-color: var(--color-fon);
  margin-bottom: 50px;
  margin-inline: 24px;
  @media (max-width: 414px) {
    margin-inline: 16px;
  }
`;

export const Wrapper = styled.div`
  @media (max-width: 320px) {
    padding-block: 40px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 40px;
  color: var(--color-white);

  text-align: center;
  font-weight: var(--fw-700);
  font-size: var(--fs-600);
  line-height: var(--line-height-600);
`;

export const Grid = styled.ul`
  display: grid;
  margin: 0;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(297px, 1fr));
  column-gap: clamp(10px, 1.5%, 21px);
  row-gap: 24px;

  @media (max-width: 1310px) {
    grid-template-columns: repeat(auto-fill, minmax(302px, 1fr));
  }

  @media (max-width: 768px) {
    row-gap: 10px;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 24px;
  }
`;

export const GoToAllButton = styled(Link)`
  max-width: 100%;
  width: 384px;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 64px auto 0;
  background: var(--gradient-1);
  color: var(--color-white);
  font-size: 16px;
  font-weight: var(--fw-600);
  border-radius: 25px;
  line-height: var(--line-height-450);
  justify-content: center;
  transition: transform 0.3s ease-out;

  &:hover,
  &:focus-visible {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(1.05);
  }
`;

export const IconArrowWrapper = styled.div`
  padding-left: 20px;
  display: inline-block;
`;
