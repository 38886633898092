import { FC } from 'react';
import { ProfileText, ProfileWrapper } from '../style';

export const ProjectsEmpty: FC<{ data: string }> = ({ data }) => {
  return (
    <ProfileWrapper>
      <ProfileText>{data}</ProfileText>
    </ProfileWrapper>
  );
};
