import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchDataProjects = createAsyncThunk<any, any>(
  'fetchDataProjects',
  async ({ limit, page }) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_HOST}projects/done`, {
      params: {
        limit: limit,
        page: page
      },
      headers: {
        'X-ACCESS-TOKEN': `${process.env.REACT_APP_BACKEND_API_TOKEN}`
      }
    });
    return response.data;
  }
);

export interface ProjectsState {
  loaded: boolean;
  limit: number;
  total: number;
  page: number;
  items: [];
}

const InitialState: ProjectsState = {
  loaded: false,
  limit: 100,
  total: 1,
  page: 1,
  items: []
};

const sliceProjectsReducer = createSlice({
  name: 'sliceProjectsReducer',
  initialState: InitialState,
  reducers: {
    changePage: (state, { payload }) => {
      state.page = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDataProjects.fulfilled, (state, { payload }) => {
      state.loaded = true;
      state.items = payload.items;
      state.page = payload.page;
      state.total = payload.total;
    });
  }
});

export default sliceProjectsReducer.reducer;
export const { changePage } = sliceProjectsReducer.actions;
