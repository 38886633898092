import React, { FC, useState } from 'react';
//import { useQuery } from '@tanstack/react-query';
import { Container } from '../../lib/styled/container';
import Filter from '../Filter';
import Project from './Project';
//import Preloader from '../../layout/Main/Preloader';
import { GoToAllButton, Grid, IconArrowWrapper, Section, Title, Wrapper } from './style';
//import { getProjectsNotDone } from '../../api/Projects';
import ProjectClass from '../../api/types/class/Project';
import { ArrorRight } from './Project/Icon';

interface ProjectsProps {
  page: string;
  projects?: 'Текущие' | 'Выполненные';
}

interface CurrentProjectsProps {
  data?: ProjectClass[] | undefined;
  showFilter?: boolean;
  showGoToAllButton?: boolean;
  title?: string;
  bgImageUrl?: string;
}

const CurrentProjects: FC<CurrentProjectsProps> = ({
  data,
  showFilter,
  showGoToAllButton,
  title
}) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {!!showFilter && <Filter page="projects" projects="Текущие" />}
      <Grid>
        {data?.map((project) => (
          <Project key={project.id} project={project} />
        ))}
      </Grid>
      {!!showGoToAllButton && <Filter page="projects" projects="Текущие" />}
    </Wrapper>
  );
};

const Projects: FC<ProjectsProps> = ({ page }) => {
  //const { isLoading, isError, data } = useQuery(['project'], getProjectsNotDone);
  //const { isLoading, isError } = useQuery(['project'], getProjectsNotDone);
  const [data] = useState(ProjectClass.CreateTestDataArray(10));
  /*if (isLoading) {
    return (
      <Section>
        <Container>
          <Wrapper>
            <Preloader />
          </Wrapper>
        </Container>
      </Section>
    );
  }

  if (isError) {
    return (
      <Section>
        <Container>
          <Wrapper>
            <Title>Ошибка</Title>;
          </Wrapper>
        </Container>
      </Section>
    );
  }
*/
  return (
    <Section>
      <Container>{page === 'projects' && <CurrentProjects data={data} />}</Container>
      <Container>{page === 'home' && <CurrentProjects data={data} showFilter={false} />}</Container>
      <Container>{page === 'apply' && <CurrentProjects data={data.slice(0, 3)} />}</Container>
    </Section>
  );
};

export const Projects2: FC<CurrentProjectsProps> = ({ showFilter, showGoToAllButton, title }) => {
  //const { isLoading, isError, data } = useQuery(['project'], getProjectsNotDone);
  //const { isLoading, isError } = useQuery(['project'], getProjectsNotDone);
  const [data] = useState(ProjectClass.CreateTestDataArray(10));
  /*
  if (isLoading) {
    return (
      <Section>
        <Container>
          <Wrapper>
            <Preloader />
          </Wrapper>
        </Container>
      </Section>
    );
  }

  if (isError) {
    return (
      <Section>
        <Container>
          <Wrapper>
            <Title>Ошибка</Title>;
          </Wrapper>
        </Container>
      </Section>
    );
  }
*/
  return (
    <Section>
      <Container>
        <Wrapper>
          <Title>{title}</Title>
          {!!showFilter && <Filter page="projects" projects="Текущие" />}
          <Grid>
            {data?.map((project) => (
              <Project key={project.id} project={project} />
            ))}
          </Grid>
          {!!showGoToAllButton && (
            <GoToAllButton to="/currentProjects">
              Все проекты
              <IconArrowWrapper>
                <ArrorRight />
              </IconArrowWrapper>
            </GoToAllButton>
          )}
        </Wrapper>
      </Container>
    </Section>
  );
};
export default Projects;
