import React, { FC } from 'react';
import { NumberWrapper, TitleWrapper, Title, Descr, NumberBlock, Slide } from './style';

export interface HowItWorksSliderSlideProps {
  num: string;
  title: string;
  descr: string;
}

export const HowItWorksSliderSlide: FC<HowItWorksSliderSlideProps> = (data) => {
  return (
    <Slide>
      <TitleWrapper>
        <NumberWrapper>
          <NumberBlock>{data.num}</NumberBlock>
        </NumberWrapper>
        <Title>{data.title}</Title>
      </TitleWrapper>
      <Descr>{data.descr}</Descr>
    </Slide>
  );
};

export default HowItWorksSliderSlide;
