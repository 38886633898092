import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Card = styled.li`
  min-height: 374px;
  padding: 24px;
  background: linear-gradient(129.36deg, #205768 -9.17%, #33185f 105.44%);
  color: var(--color-white);
  border-radius: 20px;

  display: flex;
  flex-direction: column;

  background-color: var(--color-white-2);

  transition: all 0.3s;
`;

export const CardDate = styled.span`
  margin-block-end: 25px;

  font-weight: var(--fw-400);
  font-size: var(--fs-300);
  line-height: var(--line-height-300);
  font-feature-settings: 'pnum' on, 'lnum' on;
`;

export const CardTitle = styled.h2`
  font-weight: var(--fw-600);
  font-size: var(--fs-500);
  line-height: var(--line-height-500);

  color: var(--color-white);
`;

export const CardDepartments = styled.ul`
  margin-block: 21px 15px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const DepContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CardDepartment = styled.li`
  padding: 6px 13px 6px 20px;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--color-white);
  border-radius: 20px;

  font-weight: var(--fw-600);
  font-size: var(--fs-250);
  line-height: var(--line-height-250);

  cursor: pointer;

  transition: all 0.3s;

  p {
    display: flex;
    align-items: center;
  }

  &:hover,
  &:focus-visible {
    font-weight: var(--fw-700);
    color: var(--color-white);
    background-color: #0c182d;

    p {
      svg {
        path {
          fill: var(--color-white);
        }
      }
    }
  }
`;

export const Stack = styled.ul<{
  $toggle?: string;
  $depName?: string;
}>`
  list-style: disc;
  background-color: #0c182d;
  border-radius: 15px;
  width: ${(props) => props.$toggle === props.$depName && '100%'};
  height: ${(props) => props.$toggle === props.$depName && 'auto'};
  position: absolute;
  bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: ${(props) => props.$toggle === props.$depName && '20px'};
  text-align: center;
  column-gap: 3ch;
  row-gap: 1ch;
`;

// TODO:
// если > чем на 1 строку, то в начале второй строки тоже есть пагинация
// позже подумать над тем как это исправить
export const StackItem = styled.li`
  &:first-of-type {
    list-style: none;
  }
`;

export const CardButton = styled(Link)`
  margin-block-start: auto;
  width: 100%;
  height: 48px;
  color: var(--color-white);
  background: linear-gradient(101.08deg, #26c0f1 9.94%, #6f35ce 86.57%);

  border-radius: 25px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: var(--fw-700);
  font-size: var(--fs-500);
  line-height: var(--line-height-500);
  text-align: center;

  color: var(--color-white);
  background-color: var(--color-grey);

  transition: transform 0.3s ease-out;

  &:hover,
  &:focus-visible {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(1.05);
  }
`;
