import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 40px;

  @media (max-width: 768px) {
    padding: 0 32px;
  }
`;

export const Wrapper = styled.div`
  padding-top: 128px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;

export const Title = styled.h2`
  font-weight: var(--fw-700);
  font-size: var(--fs-600);
  line-height: var(--line-height-600);
  text-align: center;

  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
`;

export const BlackBtn = styled.button`
  min-width: 296px;
  padding: 14px 0;
  max-width: 384px;
  margin: 64px auto;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  background: var(--gradient-1);
  border-radius: 25px;
  border: none;

  font-size: var(--fs-250);
  font-weight: var(--fw-600);
  line-height: var(--line-height-350);
  color: var(--color-white);

  transition: all 0.2s ease-in-out;

  @media (max-width: 768px) {
    max-width: 279px;
  }

  &::before {
    position: absolute;
    content: '';
    inset: 0;
    background: var(--gradient-5);
    z-index: 2;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    border-radius: 25px;
  }

  &:hover::before {
    opacity: 1;
  }

  &:active::before {
    opacity: 0;
  }

  &:active {
    background: var(--gradient-2);
  }

  svg {
    position: relative;
    z-index: 3;
  }
`;

export const BlackBtnText = styled.span`
  position: relative;
  z-index: 3;
`;

export const NavProjects = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-bottom: 2px solid var(--color-grey-2);
  margin-top: 64px;

  .active {
    border-bottom: 4px solid var(--color-blue-1);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  @media (max-width: 768px) {
    gap: 15px;
  }
`;

export const NavProject = styled.p`
  cursor: pointer;
  color: var(--color-white);
  font-size: var(--fs-550);
  font-weight: var(--fw-700);
  line-height: var(--line-height-550);
  text-align: center;
  transition: border 0.1s ease;
  position: relative;
  padding-bottom: 18px;

  @media (max-width: 450px) {
    font-size: var(--fs-500);
  }
`;

export const NavProjectTitle = styled.span``;
