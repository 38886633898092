import { useState, useEffect } from 'react';
import {
  Container,
  CookieImage,
  NextButton,
  StyledLink,
  TextBlock,
  TextBlockWrapper,
  Wrapper
} from './style';

export const CookieConsent = () => {
  const [isCookieConsent, setCookieConsent] = useState(true);
  const getCookie = () => {
    const cookieArr = document.cookie.split('; ');
    return cookieArr.some((el) => el === 'cookieConsent=yes');
  };
  const handleClick = () => {
    document.cookie = 'cookieConsent=yes';
    setCookieConsent(true);
  };

  useEffect(() => {
    setCookieConsent(getCookie());
  }, [isCookieConsent]);

  return (
    <>
      {!isCookieConsent ? (
        <Wrapper>
          <Container>
            <CookieImage src="/assets/img/icon-cookie.png" />
            <TextBlockWrapper>
              <TextBlock>
                Используя данный сайт, вы даете согласие на использование файлов cookie, помогающих
                нам сделать его удобнее для вас.{' '}
                <StyledLink to="/assets/img/privacy.pdf">Подробнее</StyledLink>
              </TextBlock>
            </TextBlockWrapper>
            <NextButton onClick={handleClick}>Принять и закрыть</NextButton>
          </Container>
        </Wrapper>
      ) : null}
    </>
  );
};
