import styled from 'styled-components';

export const ItemList = styled.div`
  display: flex;
  @media (max-width: 560px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const TextBlock = styled.div`
  padding: 0;
  text-align: left;
  padding-left: 35px;
  @media (max-width: 560px) {
    text-align: center;
    padding-left: 0;
  }
`;
export const Amount = styled.h2`
  padding-bottom: 15px;
  padding-top: 4px;
  font-size: var(--fs-800);
  font-weight: var(--fw-800);
  line-height: var(--line-height-900);
  color: var(--color-blue-1);
  @media (max-width: 560px) {
    padding-bottom: 4px;
    padding-top: 4px;
    font-size: var(--fs-600);
    line-height: var(--line-height-600);
  }
`;
export const Name = styled.p`
  font-size: var(--fs-1000);
  font-weight: var(--fw-400);
  line-height: var(--line-height-250);
  color: var(--color-white);
  @media (max-width: 560px) {
    font-size: var(--fs-400);
  }
`;

export const SvgWrapper = styled.div`
  width: 143px;
  height: 141px;
  @media (max-width: 560px) {
    width: 75px;
    height: 75px;
    svg {
      width: 75px;
      height: 75px;
    }
  }
`;
