import React from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import {
  Container,
  Wrapper,
  Title,
  NavProjects,
  NavProject,
  BlackBtn,
  BlackBtnText
} from './style';
import { IconPlus } from './icon';

import CurrentProjects from '../ProjectsCurrent';
import ProjectsDone from '../ProjectsDone';

const GodProjects = () => {
  const [selectedProjects, setSelectedProjects] = React.useState('current');
  const projects = [
    {
      id: 0,
      dataName: 'current',
      text: 'Проекты в разработке'
    },
    {
      id: 1,
      dataName: 'done',
      text: 'Выполненные проекты'
    }
  ];

  const navProjectsOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLParagraphElement;
    if (!target.dataset.name) return;
    setSelectedProjects(target.dataset.name);
  };

  const canView = false; // pm or team leader

  return (
    <Container>
      <Wrapper>
        <Title>Проекты</Title>
        {canView && (
          <Link to="/projects/projectAdd">
            <BlackBtn>
              <BlackBtnText>Добавить проект</BlackBtnText>
              <IconPlus />
            </BlackBtn>
          </Link>
        )}
        <NavProjects onClick={navProjectsOnClick}>
          {projects.map((item) => (
            <NavProject key={item.id} onClick={() => setSelectedProjects(item.dataName)}>
              {item.text}
              {item.dataName === selectedProjects ? (
                <motion.div className="active" layoutId="active" />
              ) : null}
            </NavProject>
          ))}
        </NavProjects>
        {/* Сюда вставить фильтр */}
        {selectedProjects === 'current' && (
          <AnimatePresence>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <CurrentProjects />
            </motion.div>
          </AnimatePresence>
        )}
        {selectedProjects === 'done' && (
          <AnimatePresence>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <ProjectsDone />
            </motion.div>
          </AnimatePresence>
        )}
      </Wrapper>
    </Container>
  );
};

export default GodProjects;
