import React from 'react';
import { Errors } from '../components/Errors';

export const ServerDown500 = () => {
  return (
    <>
      <Errors page="505" />
    </>
  );
};
