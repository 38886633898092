import IDepartment from '../interface/IDepartment';
import { IHireStatistics } from '../interface/IHireStatistics';
import IMember from '../interface/IMember';
import IProject from '../interface/IProject';
import IProjectImage from '../interface/IImage';
import IStack from '../interface/IStack';
import ProjectStatus from '../ProjectStatus';
import Member from './Member';
import ITestTask from '../interface/ITestTask';

/**
 * @class Project проект
 * @implements IProject
 */

export default class Project implements IProject {
  /**
   * @constructor Project
   * @param data Partial of Project
   */
  public constructor(data: Partial<Project>) {
    Object.assign(this, data);
  }

  /**
   * Генератор тестовых данных
   * @param n количество объектов
   * @returns Массив тестовых объектов
   * @example  const memberArray = Project.CreateTestDataArray(10);
   */
  public static CreateTestDataArray(n: number, withMembers = true): Project[] {
    const arr: Project[] = [];
    for (let i = 0; i < n; i++) arr.push(Project.CreateTestData(withMembers));
    return arr;
  }

  /**
   * Генератор тестовых данных
   * @returns Тестовый объект
   * @example  const member = Member.CreateTestData();
   */
  public static CreateTestData(withMembers = true): Project {
    return new Project({
      id: Math.floor(Math.random() * 1000),
      title: `Test project № ${Math.floor(Math.random() * 1000)}`,
      description:
        'Cras sollicitudin sapien lorem, vulputate sollicitudin felis tristique vel. Quisque condimentum posuere mattis. Nullam sodales erat eget feugiat mollis. Integer eget euismod ex. Interdum et malesuada fames ac ante ipsum primis in faucibus. Curabitur tincidunt turpis quis quam dapibus rhoncus. In auctor odio quis urna tincidunt, a dapibus tellus consectetur. Ut nibh lorem, interdum a libero non, viverra congue massa. Pellentesque elementum tempor arcu, sed malesuada justo viverra ut.',
      todo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis viverra sem. Donec id dictum nunc, sed pharetra felis. Cras vel turpis suscipit, molestie massa nec, ornare nisl. Nulla leo nisl, congue id nisi vel, sodales porttitor sapien. In et pellentesque nibh, a blandit ex. Donec in leo leo. Etiam metus erat, interdum ornare quam ut, gravida pretium purus.',
      departments: [
        {
          id: 1,
          name: 'Front',
          stacks: [
            {
              id: Math.floor(Math.random() * 100),
              name: 'JavaScript'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'HTML'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'Sass'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'React'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'Redux'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'TS'
            }
          ]
        },
        {
          id: 2,
          name: 'Back',
          stacks: [
            {
              id: Math.floor(Math.random() * 100),
              name: 'Python'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'SQL'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'Nginx'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'Alogorithms'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'FastApi'
            }
          ]
        },
        {
          id: 3,
          name: 'Quality Assurance',
          stacks: [
            {
              id: Math.floor(Math.random() * 100),
              name: 'Zephyr'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'Selenium'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'Jira'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'Jenkins'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: ' Gatling'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'Docker'
            },
            {
              id: Math.floor(Math.random() * 100),
              name: 'ESLint'
            }
          ]
        }
      ],
      benefit:
        'Praesent efficitur pulvinar ornare. Aenean gravida at neque et aliquet. Cras sodales quis mauris ut hendrerit. Curabitur a quam et nulla finibus ullamcorper non ut magna. Pellentesque lacinia molestie risus, in molestie nisi placerat consectetur. Curabitur in erat malesuada, aliquam odio at, dapibus ex. Sed tristique ut tellus eu pharetra. Quisque vitae mattis mauris.',
      task: 'Пойти туда, не знаю куда',
      whatDone: 'Что-то принести',
      startDate: new Date()
        .toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .split('/')
        .join('.'),
      finishedDate: new Date()
        .toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .split('/')
        .join('.'),
      timeSpent: 'Некоторое время',
      testTask: [
        {
          department: {
            id: 1,
            name: 'Front'
          },
          testTask: 'https://google.com'
        },
        {
          department: {
            id: 2,
            name: 'Back'
          },
          testTask: 'https://yandex.ru'
        },
        {
          department: {
            id: 3,
            name: 'Quality Assurance'
          },
          testTask: 'https://bing.com'
        }
      ],
      members: withMembers ? Member.CreateTestDataArray(Math.floor(Math.random() * 3)) : [],
      projectExternalUrl: 'https://google.com',
      projectImages: [
        {
          id: Math.floor(Math.random() * 1000),
          url: '/assets/img/project-screenshot.webp'
        },
        {
          id: Math.floor(Math.random() * 1000),
          url: '/assets/img/project-screenshot.webp'
        },
        {
          id: Math.floor(Math.random() * 1000),
          url: '/assets/img/project-screenshot.webp'
        }
      ],
      status: ProjectStatus.InWork,
      statistics: [
        {
          department: {
            id: 1,
            name: 'Frontend'
          },
          total: Math.floor(Math.random() * 10),
          hired: Math.floor(Math.random() * 10)
        },
        {
          department: {
            id: 2,
            name: 'Backend'
          },
          total: Math.floor(Math.random() * 10),
          hired: Math.floor(Math.random() * 10)
        },
        {
          department: {
            id: 3,
            name: 'Quality Assurance'
          },
          total: Math.floor(Math.random() * 10),
          hired: Math.floor(Math.random() * 10)
        }
      ]
    });
  }

  id?: number = -1;

  title?: string = '';

  description?: string = '';

  todo?: string = '';

  benefit?: string = '';

  task?: string = '';

  whatDone?: string = '';

  timeSpent?: string = '';

  startDate?: string = '';

  finishedDate?: string = '';

  members?: IMember[] = [];

  projectExternalUrl?: string = '';

  projectImages?: IProjectImage[] = [];

  status?: ProjectStatus = ProjectStatus.Draft;

  statistics?: IHireStatistics[] = [];

  departments?: IDepartment[] = [];

  stacks?: IStack[] = [];

  testTask?: ITestTask[] = [];
}
