import React, { FC } from 'react';
import { Menu } from '@headlessui/react';
import {
  Wrapper,
  TopFilter,
  BottomFilter,
  MenuItems,
  BottomFilterInput,
  BottomFilterIconWrapper,
  BottomFilterIcon,
  FilterButton,
  FilterButtonText
} from './style';
import FilterOpen from '../FilterOpen';
import { NextButton } from '../../UI/Buttons/NextButton.styled';
import IconFilter from './Icon';
import IconSearch from '../../UI/Icons/IconSearch';

const FilterMembers: FC = () => {
  const filters = ['Направление', 'Стек', 'Рейтинг', 'Состоит в гильдии'];
  // меню 1200 пкс
  // фильтр и заголовок
  // 4. S - Для стилей, C - Для компонентов
  // комит
  // поиск верстка

  return (
    <Wrapper>
      <TopFilter>
        <FilterButton outlined>
          Поиск
          <IconSearch />
        </FilterButton>

        <Menu>
          <FilterButton primary>
            Фильтры
            <IconFilter />
          </FilterButton>

          <MenuItems>
            <FilterOpen />
          </MenuItems>
        </Menu>
      </TopFilter>

      <BottomFilter>
        {filters.map((f) => (
          <BottomFilterInput key={f}>{f}</BottomFilterInput>
        ))}

        <NextButton primary>Показать</NextButton>
        <BottomFilterIconWrapper>
          <BottomFilterIcon />
        </BottomFilterIconWrapper>
      </BottomFilter>
    </Wrapper>
  );
};

export default FilterMembers;
