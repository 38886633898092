export const ArrowBack = () => {
  return (
    <svg width="23" height="26" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 1L1 8L8 15M1 8H17"
        stroke="#26C0F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const CalendarIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2641 1.45712H9.1974V1.25958C9.1974 1.10242 9.12997 0.951691 9.00994 0.840559C8.88992 0.729426 8.72713 0.666992 8.5574 0.666992C8.38766 0.666992 8.22487 0.729426 8.10485 0.840559C7.98482 0.951691 7.9174 1.10242 7.9174 1.25958V1.45712H4.0774V1.25958C4.0774 1.10242 4.00997 0.951691 3.88994 0.840559C3.76992 0.729426 3.60713 0.666992 3.4374 0.666992C3.26766 0.666992 3.10487 0.729426 2.98485 0.840559C2.86482 0.951691 2.7974 1.10242 2.7974 1.25958V1.45712H1.73073C1.44783 1.45712 1.17652 1.56117 0.976482 1.74639C0.776443 1.93161 0.664063 2.18283 0.664062 2.44477V10.346C0.664063 10.6079 0.776443 10.8592 0.976482 11.0444C1.17652 11.2296 1.44783 11.3337 1.73073 11.3337H10.2641C10.547 11.3337 10.8183 11.2296 11.0183 11.0444C11.2183 10.8592 11.3307 10.6079 11.3307 10.346V2.44477C11.3307 2.18283 11.2183 1.93161 11.0183 1.74639C10.8183 1.56117 10.547 1.45712 10.2641 1.45712ZM2.7974 2.6423C2.7974 2.79947 2.86482 2.95019 2.98485 3.06133C3.10487 3.17246 3.26766 3.23489 3.4374 3.23489C3.60713 3.23489 3.76992 3.17246 3.88994 3.06133C4.00997 2.95019 4.0774 2.79947 4.0774 2.6423H7.9174C7.9174 2.79947 7.98482 2.95019 8.10485 3.06133C8.22487 3.17246 8.38766 3.23489 8.5574 3.23489C8.72713 3.23489 8.88992 3.17246 9.00994 3.06133C9.12997 2.95019 9.1974 2.79947 9.1974 2.6423H10.0507V3.82749H1.94406V2.6423H2.7974ZM1.94406 10.1485V5.01267H10.0507V10.1485H1.94406Z"
        fill="black"
      />
    </svg>
  );
};
