import React, { FC } from 'react';
import { Errors } from '../components/Errors';

const NotFound404: FC = () => {
  return (
    <>
      <Errors page="404" />
    </>
  );
};

export default NotFound404;
