import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Menu } from '@headlessui/react';
import { TestStatus } from '../../../api/types/interface/ITestTask';

export const Section = styled.section`
  background: #0c182d, #215168);
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  transform: translateX(-22px);
  justify-content: center;
  padding-block: 55px;
  @media (min-width: 768px) {
    padding-block: 105px;
  }
  @media (max-width: 414px) {
    display: block;
    transform: translateX(0px);
  }
`;

export const Title = styled.h2`
  margin-bottom: 40px;
  text-align: center;
  font-weight: var(--fw-700);
  font-size: var(--fs-600);
  line-height: var(--line-height-600);
  color: var(--color-white);
`;

export const GoBack = styled(Link)`
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 10px;
  transform: translateY(-10px);
  color: #26c0f1;
  gap: 25px;

  font-size: var(--fs-350);
  font-weight: var(--fw-400);
  cursor: pointer;

  transition: transform 0.3s ease-out;

  &:hover,
  &:focus-visible {
    font-weight: var(--fw-500);
    transform: scale(1.02);
  }

  &:active {
    transform: scale(1.05);
  }
  @media (max-width: 885px) {
    margin-left: 10%;
  }
  @media (max-width: 414px) {
    position: absolute;
    left: -5%;
    top: 15px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: stretch;
  }
`;

export const MainBlock = styled.li``;

export const MainContent = styled.ul`
  padding: 16px;
  width: 486px;
  color: #ffffff;
  background: linear-gradient(129.36deg, #205768 -9.17%, #33185f 105.44%);
  border-radius: 20px;
  padding: 32px;
  width: 434px;
  text-align: start;
  @media (max-width: 1024px) {
    width: 462px;
  }
  @media (max-width: 885px) {
    width: 572px;
    margin-right: 10%;
  }
  @media (max-width: 720px) {
    width: 95%;
  }
  @media (max-width: 414px) {
    width: 296px;
    margin-right: 0px;
  }

  & ${MainBlock}:nth-child(-n + 5) {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      margin: 25px 0;
      background-color: var(--color-grey-2);
    }
  }
`;

export const SubTitle = styled.h3`
  margin-bottom: 20px;
  color: white;
  font-size: 24px;
  font-weight: var(--fw-600);
  line-height: 26px;
`;

export const SomeInfo = styled.h4`
  font-size: var(--fs-390);
  font-weight: var(--fw-500);
  line-height: var(--line-height-500);
  color: white;
  margin-bottom: 32px;
  padding: 3px;
  @media (max-width: 768px) {
    font-size: var(--fs-500);
  }
`;

export const Description = styled.p`
  font-size: var(--fs-350);
  font-weight: var(--fs-400);
  line-height: var(--line-height-400);
`;

export const List = styled.ul`
  list-style-type: disc;
  margin-inline-start: 25px;
`;

export const ListItem = styled.li`
  font-size: var(--fs-350);
  font-weight: var(--fs-400);
  line-height: var(--line-height-400);
`;

export const AsideContent = styled.aside`
  width: 383px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    width: 302px;
  }
  @media (max-width: 885px) {
    width: 572px;
    margin-right: 10%;
  }
  @media (max-width: 720px) {
    width: 95%;
  }
  @media (max-width: 414px) {
    width: 296px;
    margin-right: 0px;
  }
`;

export const AsideBlock = styled.div`
  background: linear-gradient(315deg, #331d60, #215168);
  border-radius: 15px;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 32px 30px 0px 30px;
  text-align: center;
`;

export const Pagination = styled.nav`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const PaginationControl = styled(Link)`
  display: flex;
  align-items: center;
  gap: 13px;
  margin-top: 20px;
  font-size: var(--fs-350);
  font-weight: var(--fs-400);
  line-height: var(--line-height-400);
  transition: transform 0.3s ease-out;

  &:hover,
  &:focus-visible {
    font-weight: var(--fw-500);
    transform: scale(1.02);
  }

  &:active {
    transform: scale(1.05);
  }

  cursor: pointer;
`;

export const PaginationLeft = styled.span`
  text-align: left;
  color: #26c0f1;
`;

export const PaginationRight = styled.span`
  text-align: right;
  color: #26c0f1;
`;

export const Button = styled.button<{
  $projectCardPage?: boolean;
  $collapsed?: boolean;
  $mobile?: boolean;
}>`
  margin-inline: auto;
  margin-left: 32px;
  margin-right: 32px;
  width: ${(props) => (props.$mobile ? '296px' : '100%')};
  height: 48px;
  margin-bottom: 32px;
  border-radius: 25px;
  margin-inline: 0px;

  display: ${(props) => (props.$mobile ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;

  font-weight: var(--fw-600);
  font-size: ${(props) => (props.$projectCardPage ? '16px' : '13px')};
  line-height: var(--line-height-300);
  text-align: center;

  color: var(--color-white);
  background: linear-gradient(101.08deg, #26c0f1 9.94%, #6f35ce 86.57%);
  transition: transform 0.3s ease-out;

  &:hover,
  &:focus-visible {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(1.05);
  }
  @media (max-width: 1350px) {
    display: ${(props) =>
      props.$projectCardPage && props.$collapsed && !props.$mobile
        ? 'none'
        : !props.$mobile && 'flex'};
  }
  @media(max-width: 414px){
    display: ${(props) => props.$mobile && 'flex'};
    visibility: ${(props) => props.$mobile && !props.$collapsed && 'hidden'};
    
  }
  }
`;
export const ApplyFormScreen = styled.div`
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
  text-align: center;
  background-color: #0c182dcc;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ApplyForm = styled.form<{ $succMsg?: boolean; $status?: TestStatus }>`
  padding: 43px 100px 20px 100px;
  background: linear-gradient(315deg, #331d60, #215168);
  border-radius: 30px;
  border: ${(props) =>
    props.$status === TestStatus.Failed
      ? '5px solid #cc3a1a'
      : props.$status === TestStatus.Success
      ? '5px solid #0ac555'
      : ''};
  color: #ffffff;
  width: 588px;
  position: relative;
  transform: translateY(-10%);
  @media (max-width: 768px) {
    width: 73%;
    padding: 50px 50px 10px 50px;
  }
  @media (max-width: 414px) {
    width: 90%;
  }
  @media (max-width: 500px) {
    padding: ${(props) => (props.$succMsg ? '50px 32px 10px 32px' : '80px 32px 10px 32px;')};
  }
`;
export const ApplyTitle = styled.div`
  text-align: center;
  margin-bottom: 40px;
  font-size: var(--fs-600);
  font-weight: var(--fw-600);
  line-height: var(--line-height-700);
  color: white;
  @media (max-width: 414px) {
    font-size: var(--fs-550);
  }
`;
export const ApplySubTitle = styled.div`
  text-align: start;
  margin-bottom: 20px;
  color: white;
  font-size: var(--fs-400);
  font-weight: var(--fw-600);
  line-height: var(--line-height-500);
`;
export const AppplyTestTask = styled.div`
  width: 100%;
  height: 48px;
  background: #6f35ce;
  border-radius: 30px;
  padding: 16px;
  font-size: var(--fs-350);
  text-align: start;
  margin-bottom: 30px;
`;

export const Select = styled.div<{ $second?: boolean }>`
  position: relative;
  width: 100%;
  margin-bottom: ${(props) => (props.$second ? '20px' : '88px')};
`;
export const SelectDepartment = styled(Menu.Button)<{ $second?: boolean; children?: string }>`
  position: relative;
  width: 100%;
  padding: 9px 19px;
  border-radius: 30px;
  background-color: ${(props) => (props.$second ? '#6f35ce' : 'white')};
  height: 48px;
  color: ${(props) => (props.$second ? 'white' : 'black')};
  font-family: Raleway;
  font-size: var(--fs-350);
  font-weight: var(--fw-400);
  line-height: var(--line-height-400);
  text-align: left;
  transition: font-weight 0.3s ease-in-out;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 14px;
    width: 0;
    height: 0;
    border-top: 9px solid ${(props) => (props.$second ? 'white' : 'black')};
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    transition: all 0.3s ease-in-out;
  }

  &[data-headlessui-state='open'] {
    font-weight: var(--fw-600);
  }

  &[data-headlessui-state='open']::before {
    border-top: none;
    border-bottom: 9px solid ${(props) => (props.$second ? 'white' : 'black')};
  }
`;

export const ChangeDepartment = styled.select`
  width: 100%;
  height: 48px;
  background: #6f35ce;
  color: var(--color-white);
  border-radius: 30px;
  padding: 16px;
  font-size: var(--fs-350);
  text-align: start;
  margin-bottom: 30px;
`;

export const MenuItems = styled(Menu.Items)<{
  $first?: boolean | undefined;
  $second?: boolean | undefined;
  children?: JSX.Element;
}>`
  position: absolute;
  padding: 30px 32px;
  width: 100%;
  max-height: 400px;
  left: 0;
  top: 55px;
  display: flex;
  flex-direction: column;
  gap: 17px;
  background-color: ${(props) => (props.$first ? 'white' : props.$second ? '#6f35ce' : '')};
  border-radius: 20px;
  overflow-y: auto;
`;
export const ApplySolutionInput = styled.input`
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 30px;
  background: white;
  font-size: var(--fs-350);
  text-align: start;
  margin-bottom: 90px;
  padding: 16px;
`;
export const Close = styled.div<{ $projectCardPage?: boolean }>`
  position: absolute;
  display: ${(props) => props.$projectCardPage && 'none'};
  width: 30px;
  top: 50px;
  right: 50px;
  cursor: pointer;
  @media (max-width: 500px) {
    top: 7%;
    right: 5%;
  }
  @media (max-width: 600px) {
    top: 35px;
    right: 35px;
  }
  @media (max-width: 414px) {
    display: ${(props) => props.$projectCardPage && 'block'};
  }
`;
export const ConnectInfoContainer = styled.div`
  width: 100%;
  height: auto;
  @media (max-width: 885px) {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 600px) {
    display: block;
  }
`;
export const ConnectInfo = styled.button<{ $statWin?: boolean }>`
  width: 100%;
  height: 48px;
  border: 3px solid;
  color: #26c0f1;
  border-radius: 30px;
  font-size: var(--fs-350);
  font-weight: var(--fw-600);
  text-align: center;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 885px) {
    width: ${(props) => (props.$statWin ? '100%' : '48%')};
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const IconContainer = styled.div`
  margin-left: 20px;
`;
export const StatusImgContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Separator = styled.hr`
  margin-bottom: 32px;
  border: 0.5px solid #848e9e;
`;
export const ConnectButtons = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  @media (max-width: 885px) {
    display: grid;
    grid-template-columns: repeat(2, min(48%, 100%));
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 550px) {
    display: block;
  }
`;
export const Span = styled.span`
  &::before {
    background-color: transparent;
    border: 1px solid red !important;
  }
`;

export const Stat = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

export const StatItem = styled.div`
  display: inline-block;
  text-align: center;
  width: 47%;
  height: 96px;
  background: #6f35ce;
  border-radius: 20px;
  padding: 23px;
  font-size: var(--fs-350);
  margin-top: 16px;
  p {
    height: 20px;
  }
  ${SubTitle} {
    height: 20px;
  }
  @media (max-width: 414px) {
    width: 100%;
    height: 73px;
  }
`;
