import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';

import { Grid, Section } from './style';
import { ArrowPagination } from './icon';

import Project from './Project';
import ProjectClass from '../../../api/types/class/Project';
import { useMediaQuery } from '../../../services/useMediaQuery';
import { useQuery } from '@tanstack/react-query';
import { getProjectsNotDone } from '../../../api/Projects';
import Preloader from '../../../layout/Main/Preloader';
import { Title } from '../style';

interface PaginatedProps {
  itemsPerPage: number;
  data: ProjectClass[];
}

interface ItemsProps {
  items: ProjectClass[];
}

const PaginatedItems: FC<PaginatedProps> = ({ itemsPerPage, data }) => {
  const isMobile = useMediaQuery('(max-width: 650px)');
  const Items: FC<ItemsProps> = ({ items }) => {
    return (
      <Grid>
        {items?.map((project: ProjectClass) => (
          <Project key={project.id} project={project} />
        ))}
      </Grid>
    );
  };

  const [currentItems, setCurrentItems] = React.useState<ProjectClass[]>([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [itemOffset, setItemOffset] = React.useState(0);

  React.useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items items={currentItems} />
      <ReactPaginate
        breakLabel="..."
        nextLabel={<ArrowPagination />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={isMobile ? 2 : 4}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        previousLabel={<ArrowPagination />}
        containerClassName={data.length < itemsPerPage ? 'pagination hidden' : 'pagination'}
        pageLinkClassName="page-num"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        activeLinkClassName="active"
      />
    </>
  );
};

const ProjectsInDev: FC = () => {
  const { isLoading, isError, data } = useQuery(['getProjectsNotDone'], getProjectsNotDone);
  if (isLoading) {
    return (
      <Section>
        <Preloader />
      </Section>
    );
  }

  if (isError) {
    return (
      <Section>
        <Title>Ошибка</Title>;
      </Section>
    );
  }
  return (
    <Section>
      <PaginatedItems itemsPerPage={6} data={data} />
    </Section>
  );
};

export default ProjectsInDev;
