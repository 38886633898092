import styled from 'styled-components';

export const Slide = styled.div`
  width: 91.6%;
  @media (max-width: 560px) {
    width: 95.6%;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    margin-bottom: 32px;
    flex-direction: column;
    align-items: start;
  }
`;

export const NumberWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 282px;
  height: 282px;
  width: 100%;
  margin-right: 25px;
  background-image: url('/assets/img/number_elipse.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 768px) {
    margin-bottom: 24px;
    max-width: 136px;
    height: 136px;
  }
`;

export const NumberBlock = styled.span`
  font-weight: var(--fw-700);
  font-size: var(--fs-1100);
  line-height: var(--line-height-950);
  color: var(--color-white);
  font-feature-settings: 'pnum' on, 'lnum' on;
  @media (max-width: 768px) {
    font-size: var(--fs-850);
    line-height: var(--line-height-700);
  }
`;

export const Title = styled.h1`
  width: 75%;
  font-size: var(--fs-800);
  font-weight: var(--fw-700);
  line-height: var(--line-height-900);
  color: var(--color-white);
  @media (max-width: 768px) {
    width: 100%;
    font-size: var(--fs-570);
    line-height: var(--line-height-550);
  }
`;

export const Descr = styled.p`
  padding-left: 100px;
  margin-bottom: 27px;
  width: 100%;
  font-size: var(--fs-900);
  font-weight: var(--fw-400);
  line-height: var(--line-height-800);
  color: var(--color-white);
  white-space: pre-wrap;
  @media (max-width: 1000px) {
    padding-left: 25px;
  }
  @media (max-width: 768px) {
    padding-left: 0;
    font-size: var(--fs-400);
    line-height: var(--line-height-400);
  }
`;
