import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import SignUpAddPhoto from '../../pages/registrationAddPhoto';
import AboutGuild from '../../pages/aboutGuild';
import AddProject from '../../pages/addProject';
import CurrentProjects from '../../pages/currentProjects';
import DoneProjects from '../../pages/doneProjects';
import GodProjects from '../../components/Projects/ProjectsGoD';
import ProjectGodAdd from '../../components/Projects/ProjectGodAdd';
import GuildMembers from '../../pages/guildMembers';
import Home from '../../pages/Home';
import HowItWorks from '../../pages/howItWorks';
import Login from '../../pages/login';
import NotFound404 from '../../pages/notFound404';
import Registration from '../../pages/registration';
import EditProject from '../../pages/editProject';
import Profile from '../../pages/profile';
import EditProfile from '../../pages/profileEdit';
import EditProfilePhoto from '../../pages/profilePhotoEdit';
import Header from '../Header';
import Footer from '../Footer';
import ProjectDoneItem from '../../components/Projects/ProjectDoneItem';
import ForgetPassword from '../../pages/forgetPassword';
import { CookieConsent } from './CookieConsent';
import { RootWrapper } from './RootWrapper';
import { MainWrapper } from './MainWrapper';
import { ProjectCard } from '../../components/Projects/ProjectCard';
import ProjectPage from '../../pages/projectPage';

const Main: FC = () => {
  return (
    <RootWrapper>
      <Header />
      <MainWrapper>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route path="/registration/addPhoto" element={<SignUpAddPhoto />} />
          <Route path="/currentProjects" element={<CurrentProjects />} />
          <Route path="/doneProjects" element={<DoneProjects />} />
          <Route path="/aboutGuild" element={<AboutGuild />} />
          <Route path="/guildMembers" element={<GuildMembers />} />
          <Route path="/howItWorks" element={<HowItWorks />} />
          <Route path="/projects" element={<GodProjects />} />
          <Route path="/projects/:projectId" element={<ProjectPage />} />
          <Route path="/projects/projectAdd" element={<ProjectGodAdd />} />
          <Route path="/profile/projectAdd" element={<AddProject />} />
          <Route path="/profile/projects/:projectId" element={<EditProject />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/edit" element={<EditProfile />} />
          <Route path="/profile/photo" element={<EditProfilePhoto />} />
          <Route path="/doneProjects/:projectId" element={<ProjectDoneItem />} />
          <Route path="/projectCard" element={<ProjectCard />} />

          <Route path="*" element={<NotFound404 />} />
        </Routes>
        <CookieConsent />
      </MainWrapper>
      <Footer />
    </RootWrapper>
  );
};

export default Main;
