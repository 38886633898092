import React from 'react';
import { GithubIcon, GitlabIcon, TelegramIcon } from '../icon';
import LinkType from '../../../api/types/LinkType';

interface SocialIconsProps {
  type: LinkType;
}

export const SocialIcons: React.FC<SocialIconsProps> = ({ type }) => {
  switch (type) {
    case 1:
      return <TelegramIcon />;
    case 2:
      return <GithubIcon />;
    case 3:
      return <GitlabIcon />;
    default:
      break;
  }
  return <></>;
};
