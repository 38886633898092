import styled, { css } from 'styled-components';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';

export const Section = styled.section`
  background-color: var(--color-fon);
  position: relative;
  color: var(--color-white);
`;

export const Title = styled.h1`
  font-size: var(--fs-600);
  font-weight: var(--fw-700);
  line-height: var(--line-height-600);
  max-width: 792px;
  text-align: center;
  margin: 81px auto 32px;
  position: relative;

  @media (min-width: 768px) {
    margin: 105px auto 39px;
  }
`;

export const TitleText = styled.span``;

export const ArrowLink = styled(Link)`
  position: absolute;
  left: 0;
  top: 5px;

  @media (max-width: 600px) {
    top: -30px;

    svg {
      width: 18px;
      height: 16px;
    }
  }
`;

export const Wrapper = styled.form`
  max-width: 688px;
  margin: 0 auto;
`;

export const WrapperDiv = styled.div`
  position: relative;
  max-width: 792px;
  padding: 48px 32px;
  margin: 0 auto 64px;
  background: var(--gradient-2);
  border-radius: 20px;

  @media (min-width: 768px) {
    padding: 48px;
    margin: 0 auto 64px;
  }
`;

export const Fieldset = styled.fieldset`
  border: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 32px;
`;

export const Label = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  gap: 10px;
  position: relative;
  flex-grow: 1;

  @media (min-width: 768px) {
    width: initial;

    ${({ wfill }: { wfill?: boolean }) => {
      return (
        wfill &&
        css`
          width: 100%;
        `
      );
    }}
  }
`;

export const LabelText = styled.span`
  font-size: var(--fs-450);
  font-weight: var(--fw-600);
  line-height: var(--line-height-500);
  padding-left: 16px;
`;

export const InputText = styled.input.attrs({
  type: 'text'
})`
  min-width: 232px;
  width: 100%;
  padding: 14px 16px;
  border: 2px solid var(--color-grey-3);
  border-radius: 50px;
  font-size: var(--fs-250);
  background: var(--color-white);

  &::placeholder {
    color: var(--color-black);
    font-size: var(--fs-250);
    font-weight: var(--fw-400);
    line-height: var(--line-height-500);
  }

  &:focus {
    outline: none;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 134px;
  padding: 12px 16px;
  border: 2px solid var(--color-grey-3);
  border-radius: 20px;
  font-size: var(--fs-250);
  background: var(--color-white);
  resize: none;
  overflow: hidden;

  &::placeholder {
    color: var(--color-black);
    font-size: var(--fs-250);
    font-weight: var(--fw-400);
    line-height: var(--line-height-500);
  }

  &:focus {
    outline: none;
  }

  @media (min-width: 768px) {
    height: 112px;
  }
`;

export const MenuButton = styled(Menu.Button)`
  position: relative;
  width: 100%;
  padding: 12px 16px;
  border: 2px solid var(--color-grey-3);
  border-radius: 50px;
  background: var(--color-white);

  font-size: var(--fs-250);
  font-weight: var(--fw-400);
  line-height: var(--line-height-400);
  text-align: left;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 14px;
    width: 0;
    height: 0;
    border-top: 9px solid var(--color-black);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    transition: all 0.3s ease-in-out;
  }

  &[data-headlessui-state='open']::before {
    border-top: none;
    border-bottom: 9px solid var(--color-black);
  }
`;

export const MenuItems = styled(Menu.Items)`
  position: absolute;
  padding: 30px 32px;
  width: 100%;
  left: 0;
  top: 110%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 17px;
  background: var(--gradient-2);
  border-radius: 7px;
`;

export const ButtonsBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 88px;
  gap: 88px;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
  }
`;

interface BlackBtnProps {
  marginTop?: string;
  maxWidth?: string;
}

export const BlackBtn = styled.button<BlackBtnProps>`
  min-width: 232px;
  padding: 16px 0;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '281px')};
  width: 100%;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  position: relative;

  background: var(--gradient-1);
  border-radius: 25px;
  border: none;

  font-size: var(--fs-250);
  font-weight: var(--fw-600);
  line-height: var(--line-height-350);
  text-align: center;
  color: var(--color-white);

  transition: all 0.2s ease-in-out;

  &::before {
    position: absolute;
    content: '';
    inset: 0;
    background: var(--gradient-5);
    z-index: 2;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    border-radius: 25px;
  }

  &:hover::before {
    opacity: 1;
  }

  &:active::before {
    opacity: 0;
  }

  &:active {
    background: var(--gradient-2);
  }

  @media (max-width: 768px) {
    max-width: 232px;
  }
`;

export const BlackBtnText = styled.span`
  position: relative;
  z-index: 3;
`;

export const WhiteBtn = styled.button`
  min-width: 232px;
  padding: 14px 0;
  max-width: 277px;
  width: 100%;
  border-radius: 25px;
  border: 2px solid var(--color-blue-1);

  font-size: var(--fs-250);
  font-weight: var(--fw-600);
  line-height: var(--line-height-350);
  text-align: center;

  color: var(--color-blue-1);
  transition: all 0.2s ease-in-out;

  &:hover {
    color: var(--color-violet-1);
    border-color: var(--color-violet-1);
  }

  &:active {
    color: var(--color-white);
    background-color: var(--color-blue-1);
    border-color: var(--color-blue-1);
  }

  @media (max-width: 768px) {
    max-width: 232px;
  }
`;
