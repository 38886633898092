import { FC } from 'react';
import { GoDWhiteIcon } from '../../UI/Icons/GoDWhiteIcon';
import { LogoIcon } from '../../UI/Icons/LogoIcon';
import { Card, CardDescription, Section } from './style';

interface ErrorsProps {
  bgImageUrl: string;
}

export const Error500: FC<ErrorsProps> = (data) => {
  return (
    <Section style={{ backgroundImage: `url("${data.bgImageUrl}")` }}>
      <Card>
        <LogoIcon />
        <GoDWhiteIcon />
        <CardDescription>Сервис не отвечает... Решаем проблему</CardDescription>
      </Card>
    </Section>
  );
};
